/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/07/2022.
 */

export const CTA_OPTIONS = [
  { label: "Link to group page", value: "groupPage" },
  { label: "Link to a form", value: "form" },
  {
    label: "Use first URL from group's description",
    value: "linkFirst",
  },
  {
    label: "Use last URL from group's description",
    value: "linkLast",
  },
  {
    label: "Contact leader",
    value: "contactLeader",
  },
];

export default CTA_OPTIONS;
