/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/06/2021.
 */

import { CT } from "../../../consts";

export const definitions = {
  title: "Full calendar",
  icon: "brush",
  groups: [
    {
      label: "Events",
      options: [
        {
          title: "Event name background",
          templates: [CT.monthly],
          name: "mcEventNameBgColor",
          defaultValue: "#D4F1FF",
          style: {
            selector: [".full-calendar .event-pill span"],
            property: "background-color",
          },
          tooltip: null,
          dependency: [
            {
              field: "mcShowEventsNames",
              value: 1,
              defaultValue: 0,
              message:
                'This option requires "Show Event names in cell" to be turned on',
            },
            {
              field: "generalUseEventAccent",
              value: 0,
              defaultValue: 1,
              message:
                'This option is overwritten by "Use calendar color as accent color" option',
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Event name",
          templates: [CT.monthly],
          name: "mcEventNameTextColor",
          defaultValue: "#4A4A4A",
          style: {
            selector: ".full-calendar .event-pill span",
            property: "color",
          },
          tooltip: null,
          dependency: {
            field: "mcShowEventsNames",
            value: 1,
            defaultValue: 0,
            message:
              'This option requires "Show Event names in cell" to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "More events indication",
          templates: [CT.monthly],
          name: "mcMoreEventsIndicationColor",
          defaultValue: "#A9A6A6",
          style: [
            {
              selector: [
                ".full-calendar .event-pill b",
                ".full-calendar .event-dot b",
              ],
              property: "color",
            },
            {
              selector: [".full-calendar.calendar table td .event-dot i"],
              property: "background-color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Details",
      options: [
        {
          title: "Number of events bg",
          templates: [CT.monthly],
          name: "mcEventsNumberBgColor",
          defaultValue: "#d4d4d4",
          style: [
            {
              selector: ".full-calendar table td .events-counter",
              property: "background-color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "mcShowEventsCounter",
            value: 1,
            defaultValue: 0,
            message:
              'This option requires "Show number of events on mouse hover" behavior to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Number of events text",
          templates: [CT.monthly],
          name: "mcEventsNumberColor",
          defaultValue: "#ffffff",
          style: [
            {
              selector: ".full-calendar table td .events-counter p",
              property: "color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "mcShowEventsCounter",
            value: 1,
            defaultValue: 0,
            message:
              'This option requires "Show number of events on mouse hover" behavior to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Hovered events list accent",
          templates: [CT.monthly],
          name: "mcHoveredEventsListAccentColor",
          defaultValue: "#FBAD61",
          style: [
            {
              selector:
                ".full-calendar table td .detailed-events-counter ul li",
              property: "border-left-color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "mcShowEventsDetailedInfo",
            value: 1,
            defaultValue: 1,
            message:
              'This option requires "Show events detailed on mouse hover" behavior to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Hovered events list bg",
          templates: [CT.monthly],
          name: "mcHoveredEventsListBgColor",
          defaultValue: "#ffffff",
          style: [
            {
              selector: ".full-calendar table td .detailed-events-counter",
              property: "background-color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "mcShowEventsDetailedInfo",
            value: 1,
            defaultValue: 1,
            message:
              'This option requires "Show events detailed on mouse hover" behavior to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Hovered events list text",
          templates: [CT.monthly],
          name: "mcHoveredEventsListColor",
          defaultValue: "#585858",
          style: [
            {
              selector:
                ".full-calendar table td .detailed-events-counter ul li",
              property: "color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "mcShowEventsDetailedInfo",
            value: 1,
            defaultValue: 1,
            message:
              'This option requires "Show events detailed on mouse hover" behavior to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Hovered events list marker",
          templates: [CT.monthly],
          name: "mcHoveredEventsListMarkerColor",
          defaultValue: "#c3c3c3",
          style: [
            {
              selector:
                ".full-calendar table td .detailed-events-counter::before",
              property: "border-top-color",
            },
            {
              selector:
                ".full-calendar table td .detailed-events-counter.lefter::before",
              property: "border-right-color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "mcShowEventsDetailedInfo",
            value: 1,
            defaultValue: 1,
            message:
              'This option requires "Show events detailed on mouse hover" behavior to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
