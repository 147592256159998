/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/02/2019.
 */

const initialState = {
  collection: [],
  total: 0,
  page: 1,
  ipp: 20,

  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  update: false,
  updateSuccess: false,
  updateError: null,

  delete: false,
  deleteSuccess: false,
  deleteError: null,
};

export default function reducer(
  state = { ...initialState, collection: [] },
  action
) {
  switch (action.type) {
    case "COUPONS_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: false,
        page: action.payload.page,
        ipp: action.payload.ipp,
      };

    case "COUPONS_FETCH_FULFILLED":
      return {
        ...state,
        collection: action.payload.coupons,
        total: action.payload.total,
        fetch: false,
        fetchSuccess: true,
      };

    case "COUPONS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.payload,
      };

    case "COUPON_UPDATE":
      return {
        ...state,
        update: true,
        updateSuccess: false,
        updateError: false,
      };

    case "COUPON_UPDATE_FULFILLED":
      const couponIndex = state.collection.findIndex(
        (i) => i.uuid == action.payload.uuid
      );

      if (couponIndex >= 0) {
        state.collection.splice(couponIndex, 1, action.payload);
      } else {
        state.collection.push(action.payload);
      }

      return {
        ...state,
        collection: [...state.collection],
        total: state.total + 1,
        update: false,
        updateSuccess: true,
      };

    case "COUPON_UPDATE_REJECTED":
      return {
        ...state,
        update: false,
        updateError: action.payload,
      };

    case "COUPON_DELETE":
      return {
        ...state,
        delete: true,
        deleteSuccess: false,
        deleteError: null,
      };

    case "COUPON_DELETE_FULFILLED":
      return {
        ...state,
        collection: state.collection.filter(
          (i) => i.uuid != action.payload.uuid
        ),
        delete: false,
        deleteSuccess: true,
      };

    case "COUPON_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteSuccess: false,
        deleteError: action.payload,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        collection: [...state.collection],
      };

    case "USER_LOGOUT":
      return { ...initialState, collection: [] };

    default:
      return state;
  }

  return state;
}
