import { filterAvailableValuesForWidgetType, initBuilder } from "../builder";
import definitions from "../builder/alerts/definitions";

const AlertsBuilderMiddleware = (storeAPI) => (next) => (action) => {
  switch (action.type) {
    case "ALERT_UPDATE_FULFILLED":
    case "ALERT_FETCH_FULFILLED":
    case "ALERT_SET":
      if (
        !action.payload ||
        !action.payload.uuid ||
        !action.payload.integration
      ) {
        break;
      }

      action.payload.builder = {
        design: initBuilder(action.payload, definitions),
        behavior: initBuilder(action.payload, definitions),
      };

      action.payload.widget_settings = {
        design: filterAvailableValuesForWidgetType(
          action.payload.widget_settings.design || {},
          action.payload.builder.design.values
        ),
        behavior: filterAvailableValuesForWidgetType(
          action.payload.widget_settings.behavior || {},
          action.payload.builder.behavior.values
        ),
      };

      break;
  }

  next(action);
};

export default AlertsBuilderMiddleware;
