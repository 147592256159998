export const updateWidgetSettings = (state, action) => {
  // If widget_settings is not defined, create it
  if (!state.model.widget_settings) {
    state.model.widget_settings = {};
  }

  // if path is null, it will apply to root of widget_settings,
  // otherwise it will apply to path
  const widgetSettings = action.path
    ? state.model.widget_settings[action.path] || {}
    : state.model.widget_settings;

  // action.payload is an array of objects. Merge it to widgetSettings object
  action.payload.forEach((item) => {
    widgetSettings[item.field] = item.value;
  });

  // If path is null, it will apply to root of widget_settings,
  // otherwise it will apply to path

  const widget_settings = action.path
    ? {
        ...state.model.widget_settings,
        [action.path]: widgetSettings,
      }
    : widgetSettings;

  // you wonder? if we use spread separator on the model, then it will overwrite the settings in the widget's state,
  // e.g. in the Calendar page we store Setup's tab settings in calendarModel state. This state gets updated on effect
  // when calendar.model gets updated. So if user change something in the setup tab and then change somethin in the design
  // tab, the setup tab settings will be lost. To prevent this, it only updates the widget_settings object.
  // Then on DESIGN_PROP_UPDATE, in the main store, it sets the timestamp of the last update. This timestamp is used to
  // determine if the widget settings have been updated. If the timestamp is different, then the widget settings are updated.
  state.model.widget_settings = {
    ...widget_settings,
  };

  return state;
  // This is how it was done before but it made setup tab settings to be reverted.
  // return {
  //   ...state,
  //   model: {
  //     ...state.model,
  //     widget_settings,
  //   },
  // };
};

export const overwriteWidgetSettings = (state, action) => {
  return {
    ...state,
    model: {
      ...state.model,
      widget_settings: {
        ...state.model.widget_settings,
        [action.path]: Object.assign({}, action.payload),
      },
    },
  };
};

export const overwriteWidgetFilters = (state, action) => {
  return {
    ...state,
    model: {
      ...state.model,
      ...action.payload,
    },
  };
};
