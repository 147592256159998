/**
 * Created by enes.kahrovic@thebeaverhead.com on 23/10/2018.
 */

const initialState = {
  collection: [],

  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  update: false,
  updateSuccess: false,
  updateError: null,

  delete: false,
  deleteSuccess: false,
  deleteError: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "USER_LOGOUT":
      return initialState;

    case "DOMAINS_FETCH":
      return {
        ...state,
        fetch: true,
        fetchError: null,
        fetchSuccess: false,
      };

    case "DOMAINS_FETCH_FULFILLED":
      return {
        ...state,
        collection: action.payload,
        fetch: false,
        fetchSuccess: true,
        fetchError: null,
      };

    case "DOMAINS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.payload,
      };

    case "DOMAIN_UPDATE":
      return {
        ...state,
        update: true,
        updateError: null,
        updateSuccess: false,
      };

    case "DOMAIN_UPDATE_FULFILLED":
      const userIdx = state.collection.findIndex(
        (i) => i.uuid == action.payload.uuid
      );

      if (userIdx >= 0) {
        state.collection.splice(userIdx, 1, action.payload);
      } else {
        state.collection.push(action.payload);
      }

      return {
        ...state,
        collection: state.collection,
        update: false,
        updateSuccess: true,
      };

    case "DOMAIN_UPDATE_REJECTED":
      return {
        ...state,
        update: false,
        updateError: action.payload,
        updateSuccess: false,
      };

    case "DOMAIN_DELETE":
      return {
        ...state,
        delete: true,
        deleteSuccess: false,
        deleteError: null,
      };

    case "DOMAIN_DELETE_FULFILLED":
      return {
        ...state,
        delete: false,
        deleteSuccess: false,
        collection: state.collection.filter(
          (i) => i.uuid != action.payload.uuid
        ),
      };

    case "DOMAIN_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteError: action.payload,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        collection: [...state.collection],
      };

    default:
      return state;
  }

  return state;
}
