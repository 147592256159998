/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/06/2021.
 */

class EventsStrategy {

  processData(data) {

  }
}

export default EventsStrategy
