/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/07/2022.
 */

import { GT, IT } from "../../../consts";

export const definitions = {
  title: "Simple list styles",
  icon: "brush",
  groups: [
    {
      label: "Layout",
      options: [
        {
          title: "Background color",
          templates: [GT.twoColumn],
          name: "gtCBg",
          defaultValue: "#fff",
          style: {
            selector: [".dce--two-columns__row"],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6445116-groups-widget-design-view-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Main information",
      options: [
        {
          title: "Group name color",
          templates: [GT.twoColumn],
          name: "gCGroupName",
          defaultValue: "rgba(0, 0, 0, 0.8)",
          style: {
            selector: [".dce--two-columns__row .col--right h2"],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6445116-groups-widget-design-view-styles",
          },
          show: [
            {
              field: "gcgShowName",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Location color",
          templates: [GT.twoColumn],
          name: "gcTLocationColor",
          defaultValue: "#A09E9E",
          style: {
            selector: [
              ".dce--two-columns__row .col--right h2 .dce--two-columns--address .dce--two-columns--address-lines",
            ],
            property: "color",
          },
          show: [
            {
              field: "gcgShowAddress",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6445116-groups-widget-design-view-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Meeting time color",
          templates: [GT.twoColumn],
          name: "gcTMeetingColor",
          defaultValue: "#A09E9E",
          style: {
            selector: [
              ".dce--two-columns__row .col--right h2 .dce--two-columns--meeting",
            ],
            property: "color",
          },
          show: [
            {
              field: "gcgShowMeetingTime",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6445116-groups-widget-design-view-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },

        {
          title: "Group description color",
          templates: [GT.twoColumn],
          name: "gCGroupDesc",
          defaultValue: "#333",
          style: {
            selector: [".dce--two-columns__row .col--right p"],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6445116-groups-widget-design-view-styles",
            message: "Color of group description text.",
          },
          show: [
            {
              field: "gSDescriptionCard",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Link color",
          templates: [GT.twoColumn],
          name: "gtCLink",
          defaultValue: "#5050af",
          style: {
            selector: [".dce--two-columns__row .col--right p a"],
            property: "color",
          },
          show: [
            {
              field: "gSDescriptionCard",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6445116-groups-widget-design-view-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Link color on hover",
          templates: [GT.twoColumn],
          name: "gtCLinkHover",
          defaultValue: "#6868ad",
          style: {
            selector: [".dce--two-columns__row .col--right p a:hover"],
            property: "color",
          },
          show: [
            {
              field: "gSDescriptionCard",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6445116-groups-widget-design-view-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },

    {
      label: "Images",
      options: [
        {
          title: "Image placeholder background",
          templates: [GT.twoColumn],
          name: "gBCImagePlaceholder",
          defaultValue: "linear-gradient(to right, #6dd5ed, #2193b0)",
          style: {
            selector: [".dce-tc__img-placeholder"],
            property: "background",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6445116-groups-widget-design-view-styles",
            message:
              "Color of group image if pictures are not available or hidden.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Primary call-to-action",
      options: [
        {
          title: "Button color",
          templates: [GT.twoColumn],
          name: "gTCCTA1stBtn",
          defaultValue: "#12212d",
          style: {
            selector: [".col--right_btn.col--right_btn--primary-cta"],
            property: "background-color",
          },
          show: [
            {
              field: "gtCShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6445116-groups-widget-design-view-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Button color on hover",
          templates: [GT.twoColumn],
          name: "gTCCTA1stBtnHover",
          defaultValue: "#12212d",
          style: {
            selector: [
              ".col--right_btn.col--right_btn--primary-cta:hover",
              ".col--right_btn.col--right_btn--primary-cta:active",
              ".col--right_btn.col--right_btn--primary-cta:focus",
            ],
            property: "background-color",
          },
          show: [
            {
              field: "gtCShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6445116-groups-widget-design-view-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color",
          templates: [GT.twoColumn],
          name: "gTCCTA1stBtnLabel",
          defaultValue: "#fff",
          style: {
            selector: [".col--right_btn.col--right_btn--primary-cta"],
            property: "color",
          },
          show: [
            {
              field: "gtCShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6445116-groups-widget-design-view-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color on hover",
          templates: [GT.twoColumn],
          name: "gTCCTA1stBtnLabelHover",
          defaultValue: "#fff",
          style: {
            selector: [
              ".col--right_btn.col--right_btn--primary-cta:hover",
              ".col--right_btn.col--right_btn--primary-cta:active",
              ".col--right_btn.col--right_btn--primary-cta:focus",
            ],
            property: "color",
          },
          show: [
            {
              field: "gtCShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6445116-groups-widget-design-view-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Secondary call-to-action",
      options: [
        {
          title: "Button color",
          templates: [GT.twoColumn],
          name: "gTCCTA2ndBtn",
          defaultValue: "#D2D2D2",
          style: {
            selector: [".col--right_btn.col--right_btn--secondary-cta"],
            property: "background-color",
          },
          show: [
            {
              field: "gtCShowCta2nd",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6445116-groups-widget-design-view-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Button color on hover",
          templates: [GT.twoColumn],
          name: "gTCCTA2ndBtnHover",
          defaultValue: "#eae8e8",
          style: {
            selector: [
              ".col--right_btn.col--right_btn--secondary-cta:hover",
              ".col--right_btn.col--right_btn--secondary-cta:active",
              ".col--right_btn.col--right_btn--secondary-cta:focus",
            ],
            property: "background-color",
          },
          show: [
            {
              field: "gtCShowCta2nd",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6445116-groups-widget-design-view-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color",
          templates: [GT.twoColumn],
          name: "gTCCTA2ndBtnLabel",
          defaultValue: "#535353",
          style: {
            selector: [".col--right_btn.col--right_btn--secondary-cta"],
            property: "color",
          },
          show: [
            {
              field: "gtCShowCta2nd",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6445116-groups-widget-design-view-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color on hover",
          templates: [GT.twoColumn],
          name: "gTCCTA2ndBtnLabelHover",
          defaultValue: "#888888",
          style: {
            selector: [
              ".col--right_btn.col--right_btn--secondary-cta:hover",
              ".col--right_btn.col--right_btn--secondary-cta:active",
              ".col--right_btn.col--right_btn--secondary-cta:focus",
            ],
            property: "color",
          },
          show: [
            {
              field: "gtCShowCta2nd",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6445116-groups-widget-design-view-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
