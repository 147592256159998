/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/10/2018.
 */

const initialState = {
  model: null,
  token: null,

  login: false,
  loginSuccess: false,
  loginError: null,

  logout: false,
};

export default function reducer(
  state = { ...initialState, model: null },
  action
) {
  switch (action.type) {
    case "USER_AUTOLOGIN":
      return {
        ...state,
        logout: false,
        login: true,
        loginSuccess: false,
        loginError: false,
      };

    case "USER_AUTOLOGIN_FULFILLED":
      return {
        ...state,
        model: action.user,
        token: action.token,

        login: false,
        loginSuccess: true,
      };

    case "USER_AUTOLOGIN_REJECTED":
      return {
        ...state,
        login: false,
        loginSuccess: false,
        loginError: action.payload,
      };

    case "USER_AUTOLOGOUT":
      return { ...initialState, model: null, logout: true };

    case "CLEAR_ERRORS":
      return { ...state, logout: false };

    default:
      return state;
  }

  return state;
}
