/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/06/2021.
 */

import { CT, IT } from "../../../consts";

export const definitions = {
  title: "RSVP",
  icon: "rsvp",
  groups: [
    {
      label: "Behavior",
      options: [
        {
          title: "Show RSVP form",
          templates: [
            CT.cardList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.eventList,
            CT.cardSlider,
            CT.detailedList,
          ],
          integrations: [IT.ccb],
          name: "rsvpShowForOpenForAll",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4616280-design-rsvp",
            message: "Allow respondents to Register for an event",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: 'Show RSVP for "Open to All" events',
          templates: [
            CT.cardList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.eventList,
            CT.cardSlider,
            CT.detailedList,
          ],
          integrations: [IT.ccb],
          name: "rsvpShowForOpenToAll",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4616280-design-rsvp",
            message: "Allow respondents to RSVP to an event",
          },
          show: [{ field: "rsvpShowForOpenForAll", value: 1, defaultValue: 1 }],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Settings",
      options: [
        {
          title: "RSVP CTA label",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
          ],
          integrations: [IT.ccb],
          name: "rsvpCTAOpenPrompt",
          defaultValue: "Please let us know if you can make it to the event.",
          tooltip: {
            url: "https://help.display.church/en/articles/4616280-design-rsvp",
            message: "Motivational label for RSVP event",
          },
          show: [{ field: "rsvpShowForOpenForAll", value: 1, defaultValue: 1 }],
          control: {
            kind: "text",
            options: {
              placeholder: "RSVP CTA label",
            },
          },
        },
        {
          title: "Registration CTA label",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
          ],
          integrations: [IT.ccb],
          name: "rsvpCTARegisterPrompt",
          defaultValue: "Please let us know if you can make it to the event.",
          tooltip: {
            url: "https://help.display.church/en/articles/4616280-design-rsvp",
            message: "Motivational label for Registration event",
          },
          show: [{ field: "rsvpShowForOpenForAll", value: 1, defaultValue: 1 }],
          control: {
            kind: "text",
            options: {
              placeholder: "Registration CTA label",
            },
          },
        },
        {
          title: "RSVP login from label",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
          ],
          integrations: [IT.ccb],
          name: "rsvpLoginOpenFormLabel",
          defaultValue: "RSVP to Event with your CCB account",
          tooltip: {
            url: "https://help.display.church/en/articles/4616280-design-rsvp",
            message: "From which ChMS respondents will RSVP",
          },
          show: [{ field: "rsvpShowForOpenForAll", value: 1, defaultValue: 1 }],
          control: {
            kind: "text",
            options: {
              placeholder: "RSVP Login from label",
            },
          },
        },
        {
          title: "Registration login from label",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
          ],
          integrations: [IT.ccb],
          name: "rsvpLoginRegisterFormLabel",
          defaultValue: "Register to Event with your CCB account",
          tooltip: {
            url: "https://help.display.church/en/articles/4616280-design-rsvp",
            message: "From which ChMS  respondents will Register",
          },
          show: [{ field: "rsvpShowForOpenForAll", value: 1, defaultValue: 1 }],
          control: {
            kind: "text",
            options: {
              placeholder: "Registration Login from label",
            },
          },
        },
        {
          title: "RSVP submit button label",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
          ],
          integrations: [IT.ccb],
          name: "rsvpLoginOpenSubmitLabel",
          defaultValue: "RSVP to an event",
          tooltip: {
            url: "https://help.display.church/en/articles/4616280-design-rsvp",
            message: "Name of Submit button for RSVP event",
          },
          show: [{ field: "rsvpShowForOpenForAll", value: 1, defaultValue: 1 }],
          control: {
            kind: "text",
            options: {
              placeholder: "RSVP Submit button label",
            },
          },
        },
        {
          title: "RSVP register submit button label",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
          ],
          integrations: [IT.ccb],
          name: "rsvpLoginRegisterSubmitLabel",
          defaultValue: "Register",
          tooltip: {
            url: "https://help.display.church/en/articles/4616280-design-rsvp",
            message: "Name of Submit button for Registration event",
          },
          show: [{ field: "rsvpShowForOpenForAll", value: 1, defaultValue: 1 }],
          control: {
            kind: "text",
            options: {
              placeholder: "RSVP Register Submit button label",
            },
          },
        },
        {
          title: "User has registered to an event",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
          ],
          integrations: [IT.ccb],
          name: "rsvpUserAttendingLabel",
          defaultValue: "You have RSVP to this event.",
          tooltip: {
            url: "https://help.display.church/en/articles/4616280-design-rsvp",
            message: "Confirmation of RSVP or Registration",
          },
          show: [{ field: "rsvpShowForOpenForAll", value: 1, defaultValue: 1 }],
          control: {
            kind: "text",
            options: {
              placeholder: "User has registered to an event",
            },
          },
        },
        {
          title: "User has updated RSVP information",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
          ],
          integrations: [IT.ccb],
          name: "rsvpUserAttendingCanChangeLabel",
          defaultValue: "You can change your RSVP using form below.",
          tooltip: {
            url: "https://help.display.church/en/articles/4616280-design-rsvp",
            message: "For RSVP events, option to update info",
          },
          show: [{ field: "rsvpShowForOpenForAll", value: 1, defaultValue: 1 }],
          control: {
            kind: "text",
            options: {
              placeholder: "User has updated RSVP information",
            },
          },
        },
      ],
    },
  ],
};
