/**
 * Created by piotr.pozniak@thebeaverhead.com on 25/06/2024.
 */
const WidgetItemsCacheMiddleware = (storeAPI) => (next) => (action) => {
  const widgetItemsCache = storeAPI.getState().widgetItemsCache;
  switch (action.type) {
    case "EVENTS_FETCH":
    case "FEATURED_EVENTS_FETCH":
      if (widgetItemsCache.collection[action.requestUri]) {
        // cache hit

        storeAPI.dispatch({
          ...action,
          type: `${action.type}_FULFILLED_CACHED`,
          payload: {
            ...widgetItemsCache.collection[action.requestUri],
          },
        });

        return;
      }
  }

  return next(action);
};

export default WidgetItemsCacheMiddleware;
