/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/10/2023.
 */
//import CCBGroupsStrategy from "./SignupsStrategy/CCBGroupsStrategy";
import PCOSignupsStrategy from "./SignupsStrategy/PCOSignupsStrategy";
import { IT } from "../consts";
import CCBGroupsStrategy from "./SignupsStrategy/CCBSignupsStrategy";

const selectStrategy = (kind) => {
  switch (kind) {
    case IT.ccb:
      return new CCBGroupsStrategy();
    case IT.pco:
      return new PCOSignupsStrategy();
  }
};

const SignupItemsMiddleware = (storeAPI) => (next) => (action) => {
  const signup = storeAPI.getState().widget;

  if (!signup.model || !signup.model.integration) {
    return next(action);
  }
  const itemsStrategy = selectStrategy(signup.model.integration.type);
  itemsStrategy.setWidgetModel(signup.model);

  switch (action.type) {
    case "WIDGET_ITEMS_FETCH_FULFILLED":
    case "WIDGET_ITEM_FETCH_FULFILLED":
      action.payload.items = itemsStrategy.processData(action.payload.items);

      break;
  }

  next(action);
};

export default SignupItemsMiddleware;
