import React from "react";

const FeaturedFilterLabelCTA = () => {
  return (
    <span>
      You may select featured events on this calendar using one of the available
      filters.{" "}
      <a
        href={
          "https://help.display.church/en/articles/5815427-setup-design-featured-events"
        }
        target={"_blank_ccb_featured_filters"}
      >
        Follow this link to learn more.
      </a>
    </span>
  );
};

export default FeaturedFilterLabelCTA;
