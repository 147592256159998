/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/07/2022.
 */

import { GT, IT } from "../../../consts";
import CTA_OPTIONS from "./cta_options";

const popupShowRequired = {
  field: "gPopupShow",
  value: 1,
  defaultValue: 1,
};

const basicInfoRequired = {
  field: "gPopupPrimitivesShow",
  value: 1,
  defaultValue: 1,
};

export const definitions = {
  title: "Details popup",
  icon: "preview",
  groups: [
    {
      label: "Behaviour",
      options: [
        {
          title: "Show popup on group click",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopupShow",
          defaultValue: 1,
          style: null,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide additional info popup.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Blocks",
      options: [
        {
          title: "Show header",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopupDetailsShow",
          defaultValue: 1,
          style: null,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          show: [popupShowRequired],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show image",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopupImageShow",
          defaultValue: 1,
          style: null,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          show: [popupShowRequired],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show description",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopupDescriptionShow",
          defaultValue: 1,
          style: null,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          show: [popupShowRequired],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show leader",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          integrations: [IT.ccb],
          name: "gPopupLeaderShow",
          defaultValue: 1,
          style: null,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          show: [popupShowRequired],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show contact email",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          integrations: [IT.pco],
          name: "gPopupContactEmailShow",
          defaultValue: 1,
          style: null,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          show: [popupShowRequired],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show basic information",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopupPrimitivesShow",
          defaultValue: 1,
          style: null,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          show: [popupShowRequired],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },

    {
      label: "Primary call-to-action",
      options: [
        {
          title: "Show CTA button",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopupShowCta1st",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide first of two buttons.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA action",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopupCTAAction1st",
          defaultValue: "groupPage",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "What this button will do when clicked.",
          },
          show: [
            {
              field: "gPopupShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_OPTIONS,
            },
          },
        },
        {
          title: "Use default CTA button label",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopup1stDefaultCTALabel",
          defaultValue: 1,
          show: [
            {
              field: "gPopupShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Use (or not) default label: Join group.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA button label",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopup1stCTALabel",
          defaultValue: "Join group",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Instead of the default, what your CTA button will say.",
          },
          show: [
            {
              field: "gPopupShowCta1st",
              value: 1,
              defaultValue: 1,
            },
            {
              field: "gPopup1stDefaultCTALabel",
              value: 0,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "text",
            options: {
              placeholder: "CTA button label",
            },
          },
        },
        {
          title: "Subject line",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopupCTASubject1st",
          defaultValue: "Join Your Small Group",
          show: [
            {
              field: "gPopupCTAAction1st",
              value: "contactLeader",
              defaultValue: "groupPage",
            },
            {
              field: "gPopupShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Message",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopupCTAMessage1st",
          defaultValue:
            "Hello! I am interested in joining your small group. Can I get more information? Thank you!",
          show: [
            {
              field: "gPopupCTAAction1st",
              value: "contactLeader",
              defaultValue: "groupPage",
            },
            {
              field: "gPopupShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "textarea",
            options: {},
          },
        },
      ],
    },
    {
      label: "Secondary call-to-action",
      options: [
        {
          title: "Show CTA button",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopupShowCta2nd",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide second of two buttons.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA action",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopupCTAAction2nd",
          defaultValue: "groupPage",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "What this button will do when clicked.",
          },
          show: [
            {
              field: "gPopupShowCta2nd",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_OPTIONS,
            },
          },
        },
        {
          title: "Use default CTA button label",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopup2ndDefaultCTALabel",
          defaultValue: 1,
          show: [
            {
              field: "gPopupShowCta2nd",
              value: 1,
              defaultValue: 0,
            },
            {
              field: "gPopupShowCta2nd",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Use (or not) default label: Join group.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA button label",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopup2ndCTALabel",
          defaultValue: "Join group",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Instead of the default, what your CTA button will say.",
          },
          show: [
            {
              field: "gPopup2ndDefaultCTALabel",
              value: 0,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "text",
            options: {
              placeholder: "CTA button label",
            },
          },
        },
        {
          title: "Subject line",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopupCTASubject2nd",
          defaultValue: "Join Your Small Group",
          show: [
            {
              field: "gPopupCTAAction2nd",
              value: "contactLeader",
              defaultValue: "groupPage",
            },
            {
              field: "gPopupShowCta2nd",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Message",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopupCTAMessage2nd",
          defaultValue:
            "Hello! I am interested in joining your small group. Can I get more information? Thank you!",
          show: [
            {
              field: "gPopupCTAAction2nd",
              value: "contactLeader",
              defaultValue: "groupPage",
            },
            {
              field: "gPopupShowCta2nd",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "textarea",
            options: {},
          },
        },
      ],
    },
    {
      label: "Basic information",
      options: [
        {
          title: "Show registration status",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          integrations: [IT.ccb],
          name: "gPopupShowRegistrationStatus",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          show: [popupShowRequired, basicInfoRequired],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show interaction type",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          integrations: [IT.ccb],
          name: "gPopupShowInteractionType",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          show: [popupShowRequired, basicInfoRequired],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show childcare information",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          integrations: [IT.ccb],
          name: "gPopupShowChildcare",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          show: [popupShowRequired, basicInfoRequired],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show group type",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopupShowGroupType",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          show: [popupShowRequired, basicInfoRequired],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show membership type",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopupShowMembershipType",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          show: [popupShowRequired, basicInfoRequired],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show leader",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopupShowLeader",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          show: [popupShowRequired, basicInfoRequired],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show department",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          integrations: [IT.ccb],
          name: "gPopupShowDepartment",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          show: [popupShowRequired, basicInfoRequired],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show group capacity",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          integrations: [IT.ccb],
          name: "gPopupShowCapacity",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          show: [popupShowRequired, basicInfoRequired],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show members count",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopupShowMembersCount",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          show: [popupShowRequired, basicInfoRequired],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show enrollment availability",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          integrations: [IT.pco],
          name: "gPopupShowEnrollmentOpen",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          show: [popupShowRequired, basicInfoRequired],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
  ],
};
