/**
 * Created by enes.kahrovic@thebeaverhead.com on 23/10/2018.
 */

const initialState = {
  collection: [],

  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  update: false,
  updateUUID: null,
  updateSuccess: false,
  updateError: null,

  delete: false,
  deleteSuccess: false,
  deleteError: null,

  sync: false,
  syncSuccess: false,
  syncError: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "USER_LOGOUT":
      return initialState;

    case "INTEGRATIONS_FETCH":
      return {
        ...state,
        fetch: true,
        fetchError: null,
        fetchSuccess: false,
      };

    case "INTEGRATIONS_FETCH_FULFILLED":
      return {
        ...state,
        collection: action.payload,
        fetch: false,
        fetchSuccess: true,
        fetchError: null,
      };

    case "INTEGRATIONS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.payload,
      };

    case "INTEGRATION_UPDATE":
      return {
        ...state,
        update: true,
        updateUUID: action.payload.uuid,
        updateSuccess: false,
        updateError: null,
      };

    case "INTEGRATION_UPDATE_FULFILLED":
      const integrationIdx = state.collection.findIndex(
        (i) => i.uuid == action.payload.uuid
      );

      if (integrationIdx >= 0) {
        state.collection.splice(integrationIdx, 1, action.payload);
      } else {
        state.collection.push(action.payload);
      }

      return {
        ...state,
        collection: state.collection,
        update: false,
        updateSuccess: true,
      };

    case "INTEGRATION_UPDATE_REJECTED":
      return {
        ...state,
        update: false,
        updateError: action.payload,
      };

    case "INTEGRATION_DELETE":
      return {
        ...state,
        delete: true,
        deleteSuccess: false,
        deleteError: null,
      };

    case "INTEGRATION_DELETE_FULFILLED":
      return {
        ...state,
        delete: false,
        deleteSuccess: false,
        collection: state.collection.filter(
          (i) => i.uuid != action.payload.uuid
        ),
      };

    case "INTEGRATION_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteError: action.payload,
      };

    case "INTEGRATION_SYNC":
      const integrationSyncIdndex = state.collection.findIndex(
        (i) => i.uuid == action.payload.uuid
      );

      const integrationSyc = state.collection[integrationSyncIdndex];

      state.collection.splice(integrationSyncIdndex, 1, {
        ...integrationSyc,
        status: 1,
      });

      return {
        ...state,
        sync: true,
        syncSuccess: false,
        syncError: null,
        collection: state.collection,
      };

    case "INTEGRATION_SYNC_FULFILLED":
      const integrationIdndex = state.collection.findIndex(
        (i) => i.uuid == action.uuid
      );

      state.collection.splice(integrationIdndex, 1, action.payload);

      return {
        ...state,
        sync: false,
        syncSuccess: true,
        collection: [...state.collection],
      };

    case "INTEGRATION_SYNC_REJECTED": {
      const idx = state.collection.findIndex((i) => i.uuid == action.uuid);

      const user = state.collection[idx];

      state.collection.splice(idx, 1, {
        ...user,
        status: 2,
      });

      return {
        ...state,
        sync: false,
        syncError: action.payload,
        collection: [...state.collection],
      };
    }

    default:
      return state;
  }

  return state;
}
