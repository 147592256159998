/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/08/2018.
 */
import appConfig from "./appConfig";

export default class AxiosConfig {
  static _token = null;

  static _config = {
    baseURL: process.env.API_ADDRESS,
    headers: {
      Accept: "application/json, text/javascript, /; q=0.01",
      "Content-Type": "application/json",
      "X-Location-Path": window.location.pathname,
    },
  };

  static endpointAddress = process.env.API_ADDRESS;

  static calendarAddress = null;

  /**
   *
   * @param token
   */
  static setAuthToken(token) {
    AxiosConfig._token = token;
  }

  /**
   *
   * @returns {string}
   */
  static getAuthToken() {
    return AxiosConfig._token;
  }

  /**
   *
   * @returns {object}
   */
  static getConfig() {
    return AxiosConfig._config;
  }

  /**
   *
   * @returns {object}
   */
  static getAuthConfig() {
    return {
      ...AxiosConfig._config,
      headers: {
        ...AxiosConfig._config.headers,
        Authorization: "Bearer " + AxiosConfig._token,
      },
    };
  }

  /**
   *
   * @returns {*}
   */
  static getAuthConfigIfAvailable() {
    if (AxiosConfig._token) {
      return AxiosConfig.getAuthConfig();
    }

    return AxiosConfig.getConfig();
  }

  /**
   *
   * @param address
   */
  static setCalendarAddress(address) {
    AxiosConfig.calendarAddress = address;
  }

  /**
   *
   */
  static getEndpointAddress() {
    if (!AxiosConfig.calendarAddress) {
      return AxiosConfig.endpointAddress;
    }

    return AxiosConfig.calendarAddress;
  }

  static getSyncEndpointAddress() {
    return !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? ""
      : appConfig.manualSyncUrl;
  }

  /**
   *
   * @param params
   */
  static objectToURLQuery(params = {}) {
    return Object.keys(params)
      .filter((i) => params[i])
      .map(
        (key) =>
          key +
          "=" +
          (typeof params[key] === "object"
            ? AxiosConfig.objectToURLQuery(params[key])
            : params[key])
      )
      .join("&");
  }
}
