/**
 * Created by piotr.pozniak@thebeaverhead.com on 03/06/2021.
 */
import CalendarIntegrationStrategy from "./CalendarIntegrationStrategy";
import PrivateEventsSyncWarning from "./PCOFilteringStrategy/PrivateEventsSyncWarning";
import FilterLabelCTA from "./PCOFilteringStrategy/FilterLabelCTA";
import { IT, PLANS } from "../../../consts";
import FeaturedFilterLabelCTA from "./PCOFilteringStrategy/FeaturedFilterLabelCTA";

class PCOStrategy extends CalendarIntegrationStrategy {
  type = IT.pco;

  availableFilters = ["tag", "tag_group"];

  constructor(widgetModel = null) {
    super(widgetModel);

    if (this.widgetModel) {
      //console.log(this.widgetModel.integration);

      if (
        this.widgetModel.integration.settings &&
        this.widgetModel.integration.settings.has_feeds
      ) {
        this.availableFilters.push("group");
      }
      this.availableFilters.push("groups_connection");

      if (this.widgetModel.has_group_events_included) {
        this.availableFilters.push("group_only");
        this.availableFilters.push("group_type");
      }
    }
  }

  filtersSettings = {
    tag: {
      label: "Tags",
      tooltip: {
        url: "https://help.display.church/en/articles/5553777-setup-events-filtering-filter-selection-pco",
        message: "Pull in events for these tags",
      },
      tooltipFeatured: {
        url: "https://help.display.church/en/articles/5815427-setup-design-featured-events",
        message: "Feature events for these tags",
      },
    },
    group: {
      label: "Feed groups",
      tooltip: {
        url: "https://help.display.church/en/articles/5553777-setup-events-filtering-filter-selection-pco",
        message: "Pull in events for these groups",
      },
      tooltipFeatured: {
        url: "https://help.display.church/en/articles/5815427-setup-design-featured-events",
        message: "Feature events for these groups",
      },
    },
  };

  featuredAllowedPlans = [PLANS.medium, PLANS.highest];

  filterLabelCTA = FilterLabelCTA();
  featuredFilterLabelCTA = FeaturedFilterLabelCTA();

  privateEventsSettingsWarning = PrivateEventsSyncWarning;

  /**
   *
   * @param data
   * @returns {{}}
   */
  filterRawDataToStateObject = (data) => {
    const filterObjects = this.availableFilters.reduce(
      (i, j) => ({ ...i, [j]: [] }),
      {}
    );
    data.map((filter) => {
      filter.value = filter.platform_metadata_id;
      filter.uuid = filter.platform_metadata_id;
      filter.label = this.filterRenderName(filter);
      filterObjects[filter.kind].push(filter);
    });

    // duplicate entries for group_only and event_connection. These use the same data as group.
    filterObjects.group_only = filterObjects.group;
    filterObjects.event_connection = filterObjects.group;

    return filterObjects;
  };

  /**
   *
   * @param data
   * @returns {*}
   */
  filterRawToDisplayObject = (data) => {
    return data.map((filter) => {
      filter.value = filter.platform_metadata_id;
      filter.uuid = filter.platform_metadata_id;
      filter.label = this.filterRenderName(filter);
      return filter;
    });
  };

  /**
   *
   * @param data
   * @param showInactive
   * @returns {*}
   */
  filterRenderName = (filter) => {
    if (!filter || !filter) {
      return "";
    }

    const name = filter.data.attributes.name; /* +
      (filter.platform_metadata_id
        ? " [" + filter.platform_metadata_id + "]"
        : "");*/

    return name;
  };

  privateEventsControl = {
    privateEventsTooltip: {
      message: "Show/hide events that were not published on Church Center",
      url: "https://help.display.church/en/articles/5545530-setup-show-private-events-pco",
    },
    toggleButtonLabel: "Show hidden events",
    allowedPlans: [PLANS.starter, PLANS.medium, PLANS.highest],
    disallowedPlanCTA:
      "You can show hidden events. Upgrade your plan to unlock this functionality.",
  };

  _filters = [
    {
      field: {
        value: "tag",
        label: "Tag",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    },
    {
      field: {
        value: "tag_group",
        label: "Tag Group",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    },
    {
      field: {
        value: "group",
        label: "Group (via Feed)",
      },
      allowedPlans: [PLANS.medium, PLANS.highest],
    },
    {
      field: {
        value: "groups_connection",
        label: "Group (via Connection)",
      },
      allowedPlans: [PLANS.medium, PLANS.highest],
    },
    {
      field: {
        value: "group_only",
        label: "Group",
      },
      allowedPlans: [PLANS.medium, PLANS.highest],
    },
    {
      field: {
        value: "group_type",
        label: "Group Type",
      },
      allowedPlans: [PLANS.medium, PLANS.highest],
    },
    {
      field: {
        value: "approval_status",
        label: "Approval status",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
      options: [
        { value: "A", label: "Approved" },
        { value: "P", label: "Pending" },
        { value: "R", label: "Rejected" },
      ],
      allowedValues: ["A"],
    },
    {
      field: {
        value: "featured",
        label: "Featured",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
      options: [
        { value: "true", label: "Yes" },
        { value: "false", label: "No" },
      ],
      operators: [
        {
          value: "is",
          label: "is",
        },
      ],
    },
  ];

  canUserFilter = {
    tag: true,
    tag_group: false,
    group: true,
    groups_connection: true,
    approval_status: false,
  };
}

export default PCOStrategy;
