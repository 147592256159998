/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/06/2021.
 */

import { CT, IT } from "../../../consts";

const eventDetailsShowAddToCalendar = [
  {
    field: "eventDetailsShowAddToCalendar",
    value: 1,
    defaultValue: 1,
  },
];

export const definitions = {
  title: "Events listing/Details colors",
  icon: "brush",
  groups: [
    {
      label: "Listing",
      options: [
        {
          title: "Left border",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.detailedList,
            CT.bubble,
            CT.card,
            CT.cardList,
            CT.cardSlider,
          ],
          applicable: "design",
          integrations: [IT.ccb, IT.google, IT.breeze],
          name: "accentColor",
          defaultValue: "#b476c9",
          style: {
            selector: [".events-list--row-wrapper"],
            property: "background-color",
          },
          dependency: [
            {
              field: "generalUseEventAccent",
              value: 0,
              defaultValue: 1,
              message:
                'This option is overwritten by "Use calendar color as accent color" option',
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },

        // duplication for PCO as it uses 'tag' color so the dependency message would be misleading
        {
          title: "Left border",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.pco],
          name: "accentColor",
          defaultValue: "#b476c9",
          style: {
            selector: [".events-list--row-wrapper"],
            property: "background-color",
          },
          dependency: [
            {
              field: "generalUseEventAccent",
              value: 0,
              defaultValue: 1,
              message:
                'This option is overwritten by "Use tag color as accent color" option',
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "All other borders",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "borderColor",
          defaultValue: "#e5e5e5",
          style: [
            {
              selector: ".events-list--row-separator",
              property: "background-color",
            },
            {
              selector: [".events-list--row::after"],
              property: "background-color",
            },
            {
              selector: [".events-list-container"],
              property: [
                "border-right-color",
                "border-top-color",
                "border-bottom-color",
              ],
            },
            {
              selector: [".event-row--details .row"],
              property: ["border-bottom-color"],
            },
            {
              selector: [
                ".event-row--details .row-doubled .col-content.col-content--right",
              ],
              property: ["border-bottom-color"],
            },
            {
              selector: [
                ".event-row--details .row-doubled .col-content.col-content--right",
              ],
              property: ["border-right-color"],
            },
            {
              selector: [".event-row--details .row-doubled"],
              property: ["border-bottom-color"],
            },
            {
              selector: [".event-details--wrapper"],
              property: ["border-left-color"],
            },
          ],

          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Background",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "eventBackgroundColor",
          defaultValue: "#fff",
          style: [
            { selector: ".events-list--row", property: "background-color" },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Background hover",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "eventBackgroundHoverColor",
          defaultValue: "#fbfbfb",
          style: [
            {
              selector: ".events-list--row:hover",
              property: "background-color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Main date and labels",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "dateColor",
          defaultValue: "#ABABAB",
          style: [
            {
              selector: [
                ".events-list--date-container",
                ".events-list--day-container .dce__h1",
                ".event-row--details h3",
                ".event-row--details .col-icon .material-icons",
              ],
              property: "color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Event name and main text",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "eventTitleColor",
          defaultValue: "#6B6B6B",
          style: [
            {
              selector: [
                ".events-list--event-description h2",
                ".event-row--details h4",
              ],
              property: "color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Additional info",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "eventSummaryColor",
          defaultValue: "#6B6B6B",
          style: [
            {
              selector: [
                ".events-list--event-description p",
                ".event-row--details p",
              ],
              property: "color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Additional info hover",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "eventSummaryHoverColor",
          defaultValue: "#262626",
          style: [
            {
              selector: [
                ".events-list--row-wrapper:hover .events-list--event-description p",
                ".events-list--row-wrapper:hover  .event-row--details p",
              ],
              property: "color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Details",
      options: [
        {
          title: "Popup background",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          applicable: "design",
          name: "popupBackgroundColor",
          defaultValue: "#d2d2d2",
          style: [
            {
              selector: [
                (uuid) => `.event-modal--wrapper.dce_calendar__${uuid}`,
                (uuid) => `.events-list-modal--wrapper.dce_calendar__${uuid}`,
              ],
              property: "background-color",
            },
          ],
          show: [
            {
              field: "generalOpenDetailsModal",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            message:
              "The colored background behind your event details display.",
            url: "https://help.display.church/en/articles/8532359-design-events-listing-details-details-popup-background-background",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Background",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          applicable: "design",
          name: "detailsBackgroundColor",
          defaultValue: "#fdfdfd",
          style: [
            {
              selector: ".event-details--wrapper",
              property: "background-color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/5907751-design-events-listing-details-colors-details",
            message: "Event details background color",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Link",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          applicable: "design",
          name: "anchorColor",
          defaultValue: "#6B6B6B",
          style: [
            {
              selector: [".event-row--details .anchor", ".p a"],
              property: "color",
            },
          ],

          tooltip: {
            url: "https://help.display.church/en/articles/5907751-design-events-listing-details-colors-details",
            message: "Color of URL in description",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Link hover",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          applicable: "design",
          name: "anchorColorHover",
          defaultValue: "#ABABAB",
          style: [
            {
              selector: [".event-row--details .anchor:hover", ".p a:hover"],
              property: "color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/5907751-design-events-listing-details-colors-details",
            message: "Color of URL on mouse over",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Event's schedule",
      options: [
        {
          title: "Schedule header background",
          integrations: [IT.pco],
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "scheduleDateBg",
          defaultValue: "#f8d189",
          style: [
            {
              selector: ["li.dce-schedule-header"],
              property: "background-color",
            },
          ],
          show: [
            {
              field: "eventDetailsShowSchedule",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            message: 'Background color in schedule "title" line.',
            url: "https://help.display.church/en/articles/5999585-design-events-listing-details-colors-details-schedule",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Schedule header",
          integrations: [IT.pco],
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "scheduleDate",
          defaultValue: "#ffffff",
          style: [
            {
              selector: ["li.dce-schedule-header"],
              property: "color",
            },
          ],
          show: [
            {
              field: "eventDetailsShowSchedule",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            message: 'Text color in schedule "title" line.',
            url: "https://help.display.church/en/articles/5999585-design-events-listing-details-colors-details-schedule",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Schedule item background",
          integrations: [IT.pco],
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "scheduleDateItemBg",
          defaultValue: "#f7f7f7",
          style: [
            {
              selector: ["li.dce-schedule-event-time"],
              property: "background-color",
            },
          ],
          show: [
            {
              field: "eventDetailsShowSchedule",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            message: "Background color of all schedule items.",
            url: "https://help.display.church/en/articles/5999585-design-events-listing-details-colors-details-schedule",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Schedule item text",
          integrations: [IT.pco],
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "scheduleDateItem",
          defaultValue: "#6b6b6b",
          style: [
            {
              selector: [
                "li.dce-schedule-event-time .dce-schedule-time",
                "li.dce-schedule-event-time .dce-schedule-description",
              ],
              property: "color",
            },
          ],
          show: [
            {
              field: "eventDetailsShowSchedule",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            message: "Text color of all schedule items.",
            url: "https://help.display.church/en/articles/5999585-design-events-listing-details-colors-details-schedule",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Schedule item circle",
          integrations: [IT.pco],
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "scheduleDateCircle",
          defaultValue: "#f5ad51",
          style: [
            {
              selector: ["li.dce-schedule-event-time::before"],
              property: "color",
            },
          ],
          show: [
            {
              field: "eventDetailsShowSchedule",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            message: "Bullet point color of all schedule items.",
            url: "https://help.display.church/en/articles/5999585-design-events-listing-details-colors-details-schedule",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Schedule item left border",
          integrations: [IT.pco],
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "scheduleDateItemLeftBorder",
          defaultValue: "#dfdddd",
          style: [
            {
              selector: ["li.dce-schedule-event-time"],
              property: "border-left-color",
            },
          ],
          show: [
            {
              field: "eventDetailsShowSchedule",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            message: "Left border color of all schedule items.",
            url: "https://help.display.church/en/articles/5999585-design-events-listing-details-colors-details-schedule",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Event recurrence series",
      options: [
        {
          title: "Recurring series header background",
          integrations: [IT.ccb, IT.pco, IT.google, IT.breeze],
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "recurringSeriesDateBg",
          defaultValue: "#f8d189",
          style: [
            {
              selector: ["li.dce-recurrence-header"],
              property: "background-color",
            },
          ],
          show: [
            {
              field: "eventDetailsShowRecurringSeries",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Recurring series  header",
          integrations: [IT.ccb, IT.pco, IT.google, IT.breeze],
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "recurringSeriesDate",
          defaultValue: "#ffffff",
          style: [
            {
              selector: ["li.dce-recurrence-header"],
              property: "color",
            },
          ],
          show: [
            {
              field: "eventDetailsShowRecurringSeries",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Recurring series item background",
          integrations: [IT.ccb, IT.pco, IT.google, IT.breeze],
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "recurringSeriesDateItemBg",
          defaultValue: "#f7f7f7",
          style: [
            {
              selector: ["li.dce-recurrence-event-time"],
              property: "background-color",
            },
          ],
          show: [
            {
              field: "eventDetailsShowRecurringSeries",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Recurring series item text",
          integrations: [IT.ccb, IT.pco, IT.google, IT.breeze],
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "recurringSeriesDateItem",
          defaultValue: "#6b6b6b",
          style: [
            {
              selector: [
                "li.dce-recurrence-event-time .dce-recurrence-time",
                "li.dce-recurrence-event-time .dce-recurrence-description",
              ],
              property: "color",
            },
          ],
          show: [
            {
              field: "eventDetailsShowRecurringSeries",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Recurring series item circle",
          integrations: [IT.ccb, IT.pco, IT.google, IT.breeze],
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "recurringSeriesDateCircle",
          defaultValue: "#f5ad51",
          style: [
            {
              selector: ["li.dce-recurrence-event-time::before"],
              property: "color",
            },
          ],
          show: [
            {
              field: "eventDetailsShowRecurringSeries",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Recurring series item left border",
          integrations: [IT.ccb, IT.pco, IT.google, IT.breeze],
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "recurringSeriesDateItemLeftBorder",
          defaultValue: "#dfdddd",
          style: [
            {
              selector: ["li.dce-recurrence-event-time"],
              property: "border-left-color",
            },
          ],
          show: [
            {
              field: "eventDetailsShowRecurringSeries",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Recurring series show more button",
          integrations: [IT.ccb, IT.pco, IT.google, IT.breeze],
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "recurringSeriesShowMoreBtn",
          defaultValue: "#237ebd",
          style: [
            {
              selector: [".dce-recurrence--more-btn"],
              property: "background",
            },
          ],
          show: [
            {
              field: "eventDetailsShowRecurringSeries",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Recurring series show more button on hover",
          integrations: [IT.ccb, IT.pco, IT.google, IT.breeze],
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "recurringSeriesShowMoreBtnHover",
          defaultValue: "#237ebd",
          style: [
            {
              selector: [".dce-recurrence--more-btn:hover"],
              property: "background",
            },
          ],
          show: [
            {
              field: "eventDetailsShowRecurringSeries",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Recurring series show more button text",
          integrations: [IT.ccb, IT.pco, IT.google, IT.breeze],
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "recurringSeriesShowMoreBtnText",
          defaultValue: "#fff",
          style: [
            {
              selector: [".dce-recurrence--more-btn"],
              property: "color",
            },
          ],
          show: [
            {
              field: "eventDetailsShowRecurringSeries",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Recurring series show more button text on hover",
          integrations: [IT.ccb, IT.pco, IT.google, IT.breeze],
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "recurringSeriesShowMoreBtnTextHover",
          defaultValue: "#fff",
          style: [
            {
              selector: [".dce-recurrence--more-btn:hover"],
              property: "color",
            },
          ],
          show: [
            {
              field: "eventDetailsShowRecurringSeries",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },

    {
      label: "Accordion",
      options: [
        {
          title: "Collapse details bar",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
          ],
          name: "eventListCollapseBG",
          defaultValue: "#f7f7f7",
          style: [
            {
              selector: [".event-list--details-close"],
              property: "background-color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Collapse details bar hover",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
          ],
          name: "eventListCollapseHoverBG",
          defaultValue: "#f1f1f1",
          style: [
            {
              selector: [".event-list--details-close:hover"],
              property: "background-color",
            },
            {
              selector: [".event-list--details-close"],
              property: "border-bottom-color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Collapse event icon",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
          ],
          name: "eventListCollapseIcon",
          defaultValue: "#808080",
          style: [
            {
              selector: [".event-list--details-close i"],
              property: "color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "RSVP",
      options: [
        {
          title: "Form background",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.ccb],
          name: "rsvpBackground",
          defaultValue: "#a4d690",
          style: [
            {
              selector: [".evors_incard_form"],
              property: "background-color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "rsvpShowForOpenForAll",
            value: 1,
            defaultValue: 1,
            message: "This option requires 'Show RSVP Form' option to be on",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option background",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.ccb],
          name: "rsvpOptionBgColor",
          defaultValue: "#ffffff",
          style: [
            {
              selector: [".evors_btn"],
              property: "background-color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "rsvpShowForOpenForAll",
            value: 1,
            defaultValue: 1,
            message: "This option requires 'Show RSVP Form' option to be on",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option selected background",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.ccb],
          name: "rsvpOptionSelectedBgColor",
          defaultValue: "#fddfa6",
          style: [
            {
              selector: [".evors_btn.active"],
              property: "background-color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "rsvpShowForOpenForAll",
            value: 1,
            defaultValue: 1,
            message: "This option requires 'Show RSVP Form' option to be on",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option label",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.ccb],
          name: "rsvpOptionColor",
          defaultValue: "#808080",
          style: [
            {
              selector: [".evors_btn"],
              property: "color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "rsvpShowForOpenForAll",
            value: 1,
            defaultValue: 1,
            message: "This option requires 'Show RSVP Form' option to be on",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option selected",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.ccb],
          name: "rsvpOptionSelectedColor",
          defaultValue: "#ffffff",
          style: [
            {
              selector: [".evors_btn.active"],
              property: "color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "rsvpShowForOpenForAll",
            value: 1,
            defaultValue: 1,
            message: "This option requires 'Show RSVP Form' option to be on",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Form header color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.ccb],
          name: "rsvpHeaderColor",
          defaultValue: "#fff",
          style: [
            {
              selector: [".event-rsvp--header"],
              property: "color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "rsvpShowForOpenForAll",
            value: 1,
            defaultValue: 1,
            message: "This option requires 'Show RSVP Form' option to be on",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Input background",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.ccb],
          name: "rsvpInputBgColor",
          defaultValue: "rgba(255, 255, 255, 0.25)",
          style: [
            {
              selector: [".evors_incard_form .form_row input"],
              property: "background-color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "rsvpShowForOpenForAll",
            value: 1,
            defaultValue: 1,
            message: "This option requires 'Show RSVP Form' option to be on",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Input placeholder color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.ccb],
          name: "rsvpInputPlaceholderColor",
          defaultValue: "#d5e4c5",
          style: [
            {
              selector: [".evors_incard_form .form_row input::placeholder"],
              property: "color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "rsvpShowForOpenForAll",
            value: 1,
            defaultValue: 1,
            message: "This option requires 'Show RSVP Form' option to be on",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Input color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.ccb],
          name: "rsvpInputColor",
          defaultValue: "#ffffff",
          style: [
            {
              selector: [".evors_incard_form .form_row input"],
              property: "color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "rsvpShowForOpenForAll",
            value: 1,
            defaultValue: 1,
            message: "This option requires 'Show RSVP Form' option to be on",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Submit button background",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.ccb],
          name: "rsvpSubmitBgColor",
          defaultValue: "#ffffff",
          style: [
            {
              selector: [".submit_rsvp_form", ".event-form-submit"],
              property: "background-color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "rsvpShowForOpenForAll",
            value: 1,
            defaultValue: 1,
            message: "This option requires 'Show RSVP Form' option to be on",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Submit button color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.ccb],
          name: "rsvpSubmitColor",
          defaultValue: "#a4d690",
          style: [
            {
              selector: [
                ".submit_rsvp_form",
                ".event-form-submit" /*, ".evors_btn"*/,
              ],
              property: "color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "rsvpShowForOpenForAll",
            value: 1,
            defaultValue: 1,
            message: "This option requires 'Show RSVP Form' option to be on",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Close button background",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.ccb],
          name: "rsvpCloseBgColor",
          defaultValue: "rgba(255, 255, 255, 0.3)",
          style: [
            {
              selector: [".evors_incard_form a.evors_incard_close"],
              property: "background-color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "rsvpShowForOpenForAll",
            value: 1,
            defaultValue: 1,
            message: "This option requires 'Show RSVP Form' option to be on",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Close button color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.ccb],
          name: "rsvpCloseColor",
          defaultValue: "#ffffff",
          style: [
            {
              selector: [
                ".evors_incard_form a.evors_incard_close::after",
                ".evors_incard_form a.evors_incard_close::before",
              ],
              property: "background-color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "rsvpShowForOpenForAll",
            value: 1,
            defaultValue: 1,
            message: "This option requires 'Show RSVP Form' option to be on",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Forms",
      options: [
        {
          title: "Submit button background",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.ccb, IT.pco],
          name: "rsvpSubmitBgColor",
          defaultValue: "#ffffff",
          style: [
            {
              selector: [".submit_rsvp_form", ".event-form-submit"],
              property: "background-color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "formShowForms",
            value: 1,
            defaultValue: 0,
            message: "This option requires 'Show Form' option to be on",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Submit button color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.ccb, IT.pco],
          name: "rsvpSubmitColor",
          defaultValue: "#a4d690",
          style: [
            {
              selector: [
                ".submit_rsvp_form",
                ".event-form-submit" /*, ".evors_btn"*/,
              ],
              property: "color",
            },
          ],
          tooltip: null,
          dependency: {
            field: "formShowForms",
            value: 1,
            defaultValue: 0,
            message: "This option requires 'Show Form' option to be on",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Event Details call-to-action",
      options: [
        {
          title: "Button color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.card,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "cPCTA1stBtn",
          defaultValue: "#00aafb",
          style: {
            selector: [".event-row--details-row .dce--event-card--cta-btn"],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/8443017-calendar-calls-to-action-styling",
          },
          show: [
            {
              field: "cPopupShowCta1st",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Button color on hover",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.card,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "cPCTA1stBtnHover",
          defaultValue: "#1ac4ff",
          style: {
            selector: [
              ".event-row--details-row .dce--event-card--cta-btn:hover:hover",
              ".event-row--details-row .dce--event-card--cta-btn:hover:active",
              ".event-row--details-row .dce--event-card--cta-btn:hover:focus",
            ],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/8443017-calendar-calls-to-action-styling",
          },
          show: [
            {
              field: "cPopupShowCta1st",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.card,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "cPCTA1stBtnLabel",
          defaultValue: "#fff",
          style: {
            selector: [".event-row--details-row .dce--event-card--cta-btn"],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/8443017-calendar-calls-to-action-styling",
          },
          show: [
            {
              field: "cPopupShowCta1st",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color on hover",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.card,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "cPCTA1stBtnLabelHover",
          defaultValue: "#fff",
          style: {
            selector: [
              ".event-row--details-row .dce--event-card--cta-btn:hover",
              ".event-row--details-row .dce--event-card--cta-btn:active",
              ".event-row--details-row .dce--event-card--cta-btn:focus",
            ],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/8443017-calendar-calls-to-action-styling",
          },
          show: [
            {
              field: "cPopupShowCta1st",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Add to calendar",
      options: [
        {
          title: "Icon border color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "cPopupSubscribeIconBorderColor",
          defaultValue: "#e0e0e0",
          style: {
            selector:
              ".event-row--details-row__add-to-calendar .event-row--details-row__add-to-calendar-item img",
            property: "border-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: eventDetailsShowAddToCalendar,
        },
        {
          title: "Icon border color hover",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "cPopupSubscribeIconBorderColorHover",
          defaultValue: "#e0e0e0",
          style: {
            selector:
              ".event-row--details-row__add-to-calendar .event-row--details-row__add-to-calendar-item:hover img",
            property: "border-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: eventDetailsShowAddToCalendar,
        },
        {
          title: "Icon background",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "cPopupSubscribeIconBg",
          defaultValue: "#fff",
          style: {
            selector:
              ".event-row--details-row__add-to-calendar .event-row--details-row__add-to-calendar-item img",
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: eventDetailsShowAddToCalendar,
        },
        {
          title: "Icon background hover",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "cPopupSubscribeIconBgHover",
          defaultValue: "#e0e0e0",
          style: {
            selector:
              ".event-row--details-row__add-to-calendar .event-row--details-row__add-to-calendar-item:hover img",
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: eventDetailsShowAddToCalendar,
        },
      ],
    },
  ],
};
