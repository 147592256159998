/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/04/2022.
 */

import { GT, IT } from "../../../consts";

export const definitions = {
  title: "Outline",
  icon: "apps",
  groups: [
    {
      label: "Information",
      options: [
        {
          title: "Show group image if available",
          templates: [GT.outlined],
          name: "gSImageMain",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/Hide the group image (if there is one).",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Effects",
      options: [
        {
          title: "Hover effect",
          templates: [GT.outlined],
          name: "gOHoverEffect",
          defaultValue: "dce--outlined-card_effect-expand",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "What happens if you hover over a group.",
          },
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "None", value: "dce--outlined-card_effect-none" },
                { label: "Expand", value: "dce--outlined-card_effect-expand" },
                { label: "Flip", value: "dce--outlined-card_effect-flip" },
              ],
            },
          },
        },
      ],
    },
  ],
};
