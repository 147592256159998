/**
 * Created by piotr.pozniak@thebeaverhead.com on 01/07/2022.
 */

import { GT, IT } from "../../../consts";
import CTA_OPTIONS from "./cta_options";

export const definitions = {
  title: "Simple list",
  icon: "dns",
  groups: [
    {
      label: "Main information",
      options: [
        {
          title: "Show group image if available",
          templates: [GT.twoColumn],
          name: "gSImageMain",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/Hide the group image (if there is one).",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show group name",
          templates: [GT.twoColumn],
          name: "gcgShowName",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show address",
          templates: [GT.twoColumn],
          name: "gcgShowAddress",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show meeting date and time",
          templates: [GT.twoColumn],
          name: "gcgShowMeetingTime",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show group description",
          templates: [GT.twoColumn],
          name: "gSDescriptionCard",
          style: null,
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/Hide the group description (if there is one).",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    /*{
      label: "Details",
      options: [
        {
          title: "Show interaction type",
          templates: [GT.card],
          integrations: [IT.ccb],
          name: "gcgShowInteractionType",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show childcare information",
          templates: [GT.card],
          integrations: [IT.ccb],
          name: "gcgShowChildcare",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show group type",
          templates: [GT.card],
          name: "gcgShowGroupType",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show membership type",
          templates: [GT.card],
          name: "gcgShowMembershipType",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show leader",
          templates: [GT.card],
          name: "gcgShowLeader",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show department",
          templates: [GT.card],
          integrations: [IT.ccb],
          name: "gcgShowDepartment",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show group capacity",
          templates: [GT.card],
          integrations: [IT.ccb],
          name: "gcgShowCapacity",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show members count",
          templates: [GT.card],
          name: "gcgShowMembersCount",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show enrollment availability",
          templates: [GT.card],
          integrations: [IT.pco],
          name: "gcgShowEnrollmentOpen",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },*/
    {
      label: "Primary call-to-action",
      options: [
        {
          title: "Show CTA button",
          templates: [GT.twoColumn],
          name: "gtCShowCta1st",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA action",
          templates: [GT.twoColumn],
          name: "gtCCTAAction1st",
          defaultValue: "groupPage",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          show: [
            {
              field: "gtCShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_OPTIONS,
            },
          },
        },
        {
          title: "Use default CTA button label",
          templates: [GT.twoColumn],
          name: "gtC1stDefaultCTALabel",
          defaultValue: 1,
          show: [
            {
              field: "gtCShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA button label",
          templates: [GT.twoColumn],
          name: "gtC1stCTALabel",
          defaultValue: "Join group",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          show: [
            {
              field: "gtC1stDefaultCTALabel",
              value: 0,
              defaultValue: 1,
            },
            {
              field: "gtCShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "text",
            options: {
              placeholder: "CTA button label",
            },
          },
        },
        {
          title: "Subject line",
          templates: [GT.twoColumn],
          name: "gCTASubject1st",
          defaultValue: "Join Your Small Group",
          show: [
            {
              field: "gtCCTAAction1st",
              value: "contactLeader",
              defaultValue: "groupPage",
            },
            {
              field: "gtCShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Message",
          templates: [GT.twoColumn],
          name: "gCTAMessage1st",
          defaultValue:
            "Hello! I am interested in joining your small group. Can I get more information? Thank you!",
          show: [
            {
              field: "gtCCTAAction1st",
              value: "contactLeader",
              defaultValue: "groupPage",
            },
            {
              field: "gtCShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "textarea",
            options: {},
          },
        },
      ],
    },
    {
      label: "Secondary call-to-action",
      options: [
        {
          title: "Show CTA button",
          templates: [GT.twoColumn],
          name: "gtCShowCta2nd",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA action",
          templates: [GT.twoColumn],
          name: "gtCCTAAction2nd",
          defaultValue: "groupPage",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          show: [
            {
              field: "gtCShowCta2nd",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_OPTIONS,
            },
          },
        },
        {
          title: "Use default CTA button label",
          templates: [GT.twoColumn],
          name: "gtC2ndDefaultCTALabel",
          defaultValue: 1,
          show: [
            {
              field: "gtCShowCta2nd",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA button label",
          templates: [GT.twoColumn],
          name: "gtC2ndCTALabel",
          defaultValue: "Join group",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          show: [
            {
              field: "gtC2ndDefaultCTALabel",
              value: 0,
              defaultValue: 1,
            },
            {
              field: "gtCShowCta2nd",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "text",
            options: {
              placeholder: "CTA button label",
            },
          },
        },
        {
          title: "Subject line",
          templates: [GT.twoColumn],
          name: "gCTASubject2nd",
          defaultValue: "Join Your Small Group",
          show: [
            {
              field: "gtCCTAAction2nd",
              value: "contactLeader",
              defaultValue: "groupPage",
            },
            {
              field: "gtCShowCta2nd",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Message",
          templates: [GT.twoColumn],
          name: "gCTAMessage2nd",
          defaultValue:
            "Hello! I am interested in joining your small group. Can I get more information? Thank you!",
          show: [
            {
              field: "gtCCTAAction2nd",
              value: "contactLeader",
              defaultValue: "groupPage",
            },
            {
              field: "gtCShowCta2nd",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "textarea",
            options: {},
          },
        },
      ],
    },
  ],
};
