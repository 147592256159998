/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/04/2022.
 */

class GroupsStrategy {

  processData(data) {

  }
}

export default GroupsStrategy
