/**
 * Created by piotr.pozniak@thebeaverhead.com on 01/07/2022.
 */

import { GT } from "../../../consts";

export const definitions = {
  title: "Mosaic styles",
  icon: "brush",
  groups: [
    {
      label: "Main information",
      options: [
        {
          title: "Group name color",
          templates: [GT.mosaic],
          name: "gMGroupName",
          defaultValue: "#fff",
          style: {
            selector: [".dce--mosaic-tile-header h2"],
            property: "color",
          },
          show: [
            {
              field: "gMShowName",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Color of group name.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },

        {
          title: "Group description color",
          templates: [GT.mosaic],
          name: "gMGroupDesc",
          defaultValue: "#fff",
          style: {
            selector: [".dce--mosaic-tile-header p"],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Color of group description text.",
          },
          show: [
            {
              field: "gSDescriptionCard",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Controls",
      options: [
        {
          title: "Image placeholder background",
          templates: [GT.mosaic],
          name: "gMImagePlaceholder",
          defaultValue: "linear-gradient(to bottom, #6dd5ed, #2193b0)",
          style: {
            selector: [".dce--mosaic-tile_container-front"],
            property: "background",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message:
              "Color of group image if pictures are not available or hidden.",
          },
          show: [
            {
              field: "gMHoverEffect",
              value: "dce--mosaic_effect-none",
              defaultValue: "dce--mosaic_effect-slide",
            },
            {
              field: "gMHoverEffect",
              value: "dce--mosaic_effect-flip",
              defaultValue: "dce--mosaic_effect-slide",
              or: true,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Image placeholder background",
          templates: [GT.mosaic],
          name: "gMImagePlaceholders",
          defaultValue: "linear-gradient(to bottom, #6dd5ed, #2193b0)",
          style: {
            selector: [".dce--mosaic-tile.dce--mosaic_effect-slide"],
            property: "background",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message:
              "Color of group image if pictures are not available or hidden.",
          },
          show: [
            {
              field: "gMHoverEffect",
              value: "dce--mosaic_effect-slide",
              defaultValue: "dce--mosaic_effect-slide",
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Primary call-to-action",
      options: [
        {
          title: "Button color",
          templates: [GT.mosaic],
          name: "gMCTA1stBtn",
          defaultValue: "#000",
          style: {
            selector: [
              ".dce--mosaic-tile-btn--primary-cta.dce--mosaic-tile-btn",
            ],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Background color of button.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Button color on hover",
          templates: [GT.mosaic],
          name: "gMCTA1stBtnHover",
          defaultValue: "rgba(0,0,0,0.4)",
          style: {
            selector: [
              ".dce--mosaic-tile-btn--primary-cta.dce--mosaic-tile-btn:hover",
              ".dce--mosaic-tile-btn--primary-cta.dce--mosaic-tile-btn:active",
              ".dce--mosaic-tile-btn--primary-cta.dce--mosaic-tile-btn:focus",
            ],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Background color or button when you hover on it.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color",
          templates: [GT.mosaic],
          name: "gMCTA1stBtnLabel",
          defaultValue: "#fff",
          style: {
            selector: [
              ".dce--mosaic-tile-btn--primary-cta.dce--mosaic-tile-btn",
            ],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Text color of button.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color on hover",
          templates: [GT.mosaic],
          name: "gMCTA1stBtnLabelHover",
          defaultValue: "#fff",
          style: {
            selector: [
              ".dce--mosaic-tile-btn--primary-cta.dce--mosaic-tile-btn:hover",
              ".dce--mosaic-tile-btn--primary-cta.dce--mosaic-tile-btn:active",
              ".dce--mosaic-tile-btn--primary-cta.dce--mosaic-tile-btn:focus",
            ],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Text color of button when you hover on it.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Secondary call-to-action",
      options: [
        {
          title: "Button color",
          templates: [GT.mosaic],
          name: "gMCTA2ndBtn",
          defaultValue: "#000",
          style: {
            selector: [
              ".dce--mosaic-tile-btn--secondary-cta.dce--mosaic-tile-btn",
            ],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Background color of button.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Button color on hover",
          templates: [GT.mosaic],
          name: "gMCTA2ndBtnHover",
          defaultValue: "rgba(0,0,0,0.4)",
          style: {
            selector: [
              ".dce--mosaic-tile-btn--secondary-cta.dce--mosaic-tile-btn:hover",
              ".dce--mosaic-tile-btn--secondary-cta.dce--mosaic-tile-btn:active",
              ".dce--mosaic-tile-btn--secondary-cta.dce--mosaic-tile-btn:focus",
            ],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Background color or button when you hover on it.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color",
          templates: [GT.mosaic],
          name: "gMCTA2ndBtnLabel",
          defaultValue: "#fff",
          style: {
            selector: [
              ".dce--mosaic-tile-btn--secondary-cta.dce--mosaic-tile-btn",
            ],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Text color of button.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color on hover",
          templates: [GT.mosaic],
          name: "gMCTA2ndBtnLabelHover",
          defaultValue: "#fff",
          style: {
            selector: [
              ".dce--mosaic-tile-btn--secondary-cta.dce--mosaic-tile-btn:hover",
              ".dce--mosaic-tile-btn--secondary-cta.dce--mosaic-tile-btn:active",
              ".dce--mosaic-tile-btn--secondary-cta.dce--mosaic-tile-btn:focus",
            ],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Text color of button when you hover on it.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
