import { filterAvailableValuesForWidgetType, initBuilder } from "../builder";
import definitions from "../builder/calendar/definitions";
import { CT } from "../consts";

const CalendarBuilderMiddleware = (storeAPI) => (next) => (action) => {
  switch (action.type) {
    case "CALENDAR_UPDATE_FULFILLED":
    case "CALENDAR_FETCH_FULFILLED":
    case "CALENDAR_SET":
      if (
        !action.payload ||
        !action.payload.uuid ||
        !action.payload.integration
      ) {
        break;
      }

      const designBuilder = initBuilder(action.payload, definitions);
      const highlightsBuilder = initBuilder(
        {
          ...action.payload,
          template:
            action.payload.widget_settings.featured_template || CT.bubble,
        },
        definitions,
        "highlights"
      );

      // remove items from the highlights builder that are in the design builder

      action.payload.builder = {
        design: designBuilder,
        highlights: highlightsBuilder,
      };

      action.payload.widget_settings = {
        ...action.payload.widget_settings,
        design: filterAvailableValuesForWidgetType(
          action.payload.widget_settings.design || {},
          action.payload.builder.design.values
        ),
        highlights: filterAvailableValuesForWidgetType(
          action.payload.widget_settings.highlights || {},
          action.payload.builder.highlights.values
        ),
      };
      // action.payload.widget_settings = filterAvailableValuesForWidgetType(
      //   action.payload.widget_settings || {},
      //   action.payload.builder.values
      // );

      break;

    /*case "WIDGET_DESIGN_PROP_UPDATE":
      if (action.payload[0].field === "featured_template") {
        const calendar = storeAPI.getState().calendar;
        console.log(calendar);
        const featuredTemplate =
          calendar.model.widget_settings.featured_template || CT.bubble;

        if (featuredTemplate !== action.payload[0].value) {
          console.log("featured template changed");
          action.payload.builder.highlights = initBuilder(
            {
              ...action.payload,
              template: action.payload.widget_settings.featured_template,
            },
            definitions
          );

          action.payload.widget_settings.highlights =
            filterAvailableValuesForWidgetType(
              action.payload.widget_settings.highlights || {},
              action.payload.builder.highlights.values
            );
        }
      }
      break;*/
  }

  next(action);
};

export default CalendarBuilderMiddleware;
