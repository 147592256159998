/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/06/2021.
 */
import { useCalendarStore } from "../hooks/redux/calendar";

import { dceDebug } from "../helpers/debug";
import { useGroupStore } from "../hooks/redux/group";
import { useAlertStore } from "../hooks/redux/alert";
import { useWidgetStore } from "../hooks/redux/widget";

export const isCTAllowed = (current, allowed) => {
  return allowed.indexOf(current) >= 0;
};

/**
 *
 * @param widget
 * @param definitions
 * @returns {{values: {}, definitions: *}}
 */
export const initBuilder = (widget, definitions, settingsType = "design") => {
  let _values = {};

  const integrationType = widget.integration.type;
  const integrationSettings = widget.integration.settings;
  const template = widget.template;

  const widgetCreatedTimestamp = Number.parseInt(
    widget.created || new Date().getTime() / 1000
  );

  const _definitions = definitions
    .map((i) => {
      const groups = i.groups
        .map((j) => {
          const options = j.options
            .map((option, ndx) => {
              if (
                !isCTAllowed(template, option.templates) ||
                (option.integrations &&
                  !isCTAllowed(integrationType, option.integrations))
              ) {
                return null;
              }

              if (option.integrationSettings) {
                let meetsAllSettings = true;
                for (const key in option.integrationSettings) {
                  if (
                    !integrationSettings ||
                    (integrationSettings &&
                      integrationSettings[key] !==
                        option.integrationSettings[key])
                  ) {
                    meetsAllSettings = false;
                    break;
                  }
                }

                if (!meetsAllSettings) {
                  return null;
                }
              }

              if (option.applicable) {
                if (option.applicable !== settingsType) {
                  return null;
                }
              }

              _values[option.name] = {
                style: option.style,
                show: option.show,
                name: option.name,
                defaultValue: option.defaultValue,
                templates: option.templates,
              };

              return option;
            })
            .filter((i) => i)
            .filter((i) => {
              if (!i.version) {
                return true;
              }
              if (i.version.created === "before") {
                return Number.parseInt(i.version.value) > widgetCreatedTimestamp
                  ? true
                  : false;
              }
            });

          if (options.length) {
            return {
              ...j,
              options,
            };
          }
          return null;
        })
        .filter((i) => i);

      if (groups.length) {
        return {
          ...i,
          groups,
        };
      }

      return null;
    })
    .filter((i) => i);

  return {
    definitions: _definitions,
    values: _values,
  };
};

/**
 *
 * @returns {*[]}
 */
export const getDefinitions = (widget, settingsName = "design") => {
  return widget ? widget.builder[settingsName].definitions : [];
};

/**
 *
 * @param widget
 * @param settingsName
 * @returns {{}}
 */
export const getAllOptionValues = (widget, settingsName = "design") => {
  const definitions = getDefinitions(widget, settingsName);

  const optionValues = {};
  definitions.map((definition) => {
    definition.groups.map((group) => {
      group.options.map((option) => {
        optionValues[option.name] = useOptionValue(
          widget,
          option.name,
          null,
          settingsName
        );

        const optionOptionValue = useOptionValue(
          widget,
          option.name + "Option",
          undefined,
          settingsName
        );

        if (optionOptionValue !== undefined) {
          optionValues[option.name + "Option"] = optionOptionValue;
        }

        const linkedOptionValue = useOptionValue(
          widget,
          option.name + "Linked",
          undefined,
          settingsName
        );

        if (linkedOptionValue !== undefined) {
          optionValues[option.name + "Linked"] = linkedOptionValue;
        }
      });
    });
  });

  return optionValues;
};

/**
 *
 * @param widgetModel
 * @param name
 * @param overwriteDefaultValue
 * @param settingsName
 * @returns {*|null}
 */
export const useOptionValue = (
  widgetModel,
  name,
  overwriteDefaultValue = null,
  settingsName = "design"
) => {
  if (!widgetModel || !widgetModel.builder) {
    return null;
  }

  const prop = widgetModel.widget_settings[settingsName] || {};

  if (prop[name] !== undefined) {
    if (!isNaN(prop[name]) && typeof prop[name] !== "string") {
      return Number.parseInt(prop[name]);
    }
    return prop[name];
  }

  const _values = widgetModel.builder[settingsName].values;

  if (overwriteDefaultValue === null && _values[name] === undefined) {
    return null;
  }

  return overwriteDefaultValue || _values[name].defaultValue;
};

/**
 *
 * @param widgetType
 * @returns {null}
 */
const findAppStylesheet = (widgetType) => {
  for (let i = 0; i < document.styleSheets.length; i++) {
    let style = null;

    try {
      const dummy = document.styleSheets[i].cssRules.length;
      style = document.styleSheets[i];
    } catch (e) {
      // ...
    }

    if (!style) {
      continue;
    }

    for (let j = 0; j < style.cssRules.length; j++) {
      if (style.cssRules[j].selectorText === `.dce_${widgetType}_stylesheet`) {
        return style;
      }
    }
  }

  return null;
};

/**
 *
 * @param name
 * @param defaultValue
 * @param settings
 * @returns {*}
 */
export const valueIfAvailable = (name, defaultValue, settings) => {
  if (!settings || settings[name] == undefined) {
    return defaultValue;
  }

  if (!isNaN(settings[name])) {
    return Number.parseInt(settings[name]);
  }
  return settings[name];
};

/**
 *
 * @param option
 * @param styleSheet
 * @param settings
 * @param widgetUUID
 * @param widgetType
 */
const modifyStylesheet = (
  option,
  styleSheet,
  settings,
  widgetUUID,
  widgetType
) => {
  let value = option.defaultValue;

  if (
    valueIfAvailable(option.name + "Option", "default", settings) !== "default"
  ) {
    value = settings[option.name];
  }

  const styles = Array.isArray(option.style) ? option.style : [option.style];

  const widgetSelector = `.dce_${widgetType}__${widgetUUID}`;

  styles.forEach((style) => {
    const selectors = Array.isArray(style.selector)
      ? style.selector
      : [style.selector];
    const properties = Array.isArray(style.property)
      ? style.property
      : [style.property];

    selectors.map((s) => {
      let selector =
        typeof s == "function" ? s(widgetUUID) : widgetSelector + " " + s;

      properties.map((p) =>
        changeStylesheetRule(styleSheet, selector, p, value)
      );
    });
  });
};

/**
 *
 * @param settings
 * @param widgetSettings
 * @returns {*}
 */
export const calculateDependency = (settings, widgetSettings) => {
  const evaluated = settings
    .map((i) => {
      const dependencyFieldValue = valueIfAvailable(
        i.field,
        i.defaultValue,
        widgetSettings
      );

      if (typeof i.value == "function") {
        return i.value(dependencyFieldValue);
      }

      return { value: dependencyFieldValue === i.value, and: !i.or, or: i.or };
    })
    .reduce((a, c) => (c.or ? a || c.value : a && c.value), true);

  return evaluated;
};

/**
 *
 * @param widget
 * @param settings
 * @returns {boolean}
 */
export const updateStylesheet = (widget, settingsName = "design") => {
  const widgetType = widget.widget_type;
  const settings = widget.widget_settings[settingsName];
  const styleSheet = findAppStylesheet(widgetType);
  const builder = widget.builder[settingsName];

  if (!styleSheet) {
    return false;
  }

  const calendarSuffix = settingsName === "design" ? "" : `_${settingsName}`;

  const widgetSelector = `.dce_${widgetType}__${widget.uuid}${calendarSuffix}`;

  for (const key in builder.values) {
    const option = builder.values[key];

    if (!option.style) {
      continue;
    }

    // calculate dependency ?
    if (option.show) {
      const show = calculateDependency(option.show, settings);
      if (!show) {
        continue;
      }
    }

    modifyStylesheet(
      option,
      styleSheet,
      settings,
      `${widget.uuid}${calendarSuffix ? " ." : ""}${calendarSuffix}`,
      // `${widget.uuid}`,
      widgetType
    );
  }

  for (const key in builder.values) {
    const option = builder.values[key];

    if (key.match(/^fontFamily.*/)) {
      const fontFamily = valueIfAvailable(key, option.defaultValue, settings);

      if (fontFamily !== "inherit") {
        loadCustomFonts(widgetSelector, fontFamily, key);
      }
    }
  }
};

/**
 *
 * @param stylesheet
 * @param selector
 * @param property
 * @param value
 * @returns {boolean}
 */
const changeStylesheetRule = (stylesheet, selector, property, value) => {
  dceDebug(
    "changeStylesheetRule(" + selector + ", " + property + ", " + value + ")"
  );
  if (!property) {
    dceDebug(
      "Property for selector " + selector + " is not defined: ",
      property,
      value
    );
    return false;
  }

  selector = (selector || "").toLowerCase();
  property = (property || "").toLowerCase();
  value = (value || "").toLowerCase();

  for (let i = 0; i < stylesheet.cssRules.length; i++) {
    let rule = stylesheet.cssRules[i];

    if (rule.selectorText === selector) {
      rule.style.setProperty(property, value);
      //rule.style[property] = value;
      return true;
    }
  }

  try {
    const rule = `${selector}  {${property}: ${value}; }`;
    stylesheet.insertRule(rule, 0);
  } catch (e) {
    console.error(e);
  }
};

/**
 *
 * @param widgetSelector
 * @param fontFamily
 */
function loadCustomFonts(widgetSelector, fontFamily, id) {
  if (!fontFamily) {
    return;
  }

  const fontUrl =
    "https://fonts.googleapis.com/css?family=" +
    fontFamily.replace(" ", "+") +
    ":300,400,500,700,900";

  const fontId = widgetSelector.trim() + "-dce-custom-font-" + id;
  const data = document.getElementById(fontId);

  if (data && data.href == fontUrl) {
    return;
  }

  //  if (!window.__DCE__DEV__) {
  if (data) {
    data.remove();
  }
  //  }

  /*for (let i = data.length-1; i >= 0; --i) {
    data[i].remove();
  }*/

  let el = document.createElement("link");
  el.rel = "stylesheet";
  el.href = fontUrl;
  el.id = fontId;

  document.querySelector("head").appendChild(el);
}

/**
 * This function removes all values from events_setings JSON string that
 * are not present in the builder values. When cloning calendar, options from
 * a different calendar type can come across. This function removes all values
 * that are not allowed for specific calendar type (based on the builder).
 * @param widgetSettings
 * @param allowedValues
 * @returns {string}
 */
export const filterAvailableValuesForWidgetType = (
  widgetSettings,
  allowedValues
) => {
  const properCalendarSettings = {};
  Object.keys(allowedValues).map((i, idx) => {
    if (widgetSettings[i] !== undefined) {
      properCalendarSettings[i] = widgetSettings[i];

      if (widgetSettings[i + "Option"] !== undefined) {
        properCalendarSettings[i + "Option"] = widgetSettings[i + "Option"];
      }
      if (widgetSettings[i + "Linked"] !== undefined) {
        properCalendarSettings[i + "Linked"] = widgetSettings[i + "Linked"];
      }
    }
  });

  return properCalendarSettings;
};

window.updateStylesheet = updateStylesheet;
