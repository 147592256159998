/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/02/2023.
 */

const initialState = {
  collection: [],

  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  run: false,
  runSuccess: false,
  runError: null,

  terminate: false,
  terminateSuccess: false,
  terminateError: null,
};

export default function reducer(
  state = { ...initialState, collection: [] },
  action
) {
  switch (action.type) {
    case "QUEUE_WORKERS_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: false,
      };

    case "QUEUE_WORKERS_FETCH_FULFILLED":
      return {
        ...state,
        collection: action.payload,
        fetch: false,
        fetchSuccess: true,
      };

    case "QUEUE_WORKERS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.payload,
      };

    case "QUEUE_WORKER_RUN":
      return {
        ...state,
        run: true,
        runSuccess: false,
        runError: false,
      };

    case "QUEUE_WORKER_RUN_FULFILLED":
      return {
        ...state,
        run: false,
        runSuccess: true,
      };

    case "QUEUE_WORKER_RUN_REJECTED":
      return {
        ...state,
        run: false,
        runSuccess: false,
        runError: action.payload,
      };

    case "QUEUE_WORKER_TERMINATE":
      return {
        ...state,
        terminate: true,
        terminateSuccess: false,
        terminateError: false,
      };

    case "QUEUE_WORKER_TERMINATE_FULFILLED":
      return {
        ...state,
        terminate: false,
        terminateSuccess: true,
        collection: state.collection.map((i) => {
          if (i.id === action.queueId) {
            i.terminate = true;
          }
          return i;
        }),
      };

    case "QUEUE_WORKER_TERMINATE_REJECTED":
      return {
        ...state,
        terminate: false,
        terminateSuccess: false,
        terminateError: action.payload,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        collection: [...state.collection],
      };

    case "USER_LOGOUT":
      return { ...initialState, collection: [] };

    default:
      return state;
  }

  return state;
}
