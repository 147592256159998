/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/07/2022.
 */

import { GT, IT } from "../../../consts";

export const definitions = {
  title: "Popup styles",
  icon: "brush",
  groups: [
    {
      label: "Overlay",
      options: [
        {
          title: "Background",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "popupBackgroundColor",
          defaultValue: "#d2d2d2",
          style: [
            {
              selector: [
                (uuid) => `.dce-groups-modal--wrapper.dce_groups__${uuid}`,
              ],
              property: "background-color",
            },
          ],
          show: [
            {
              field: "gPopupShow",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            message:
              "The colored background behind your group details display.",
            url: "http://help.display.church/en/articles/6445770-groups-widget-design-popup-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Header section",
      options: [
        {
          title: "Background",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPHeaderBg",
          defaultValue:
            "linear-gradient(rgb(109, 213, 237), rgb(33, 147, 176))",
          style: {
            selector: [".dce-groups-modal--header"],
            property: "background",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6445770-groups-widget-design-popup-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },

        {
          title: "Group name color",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPGroupName",
          defaultValue: "#ffffff",
          style: {
            selector: [".dce-groups-modal--header h1"],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6445770-groups-widget-design-popup-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },

        {
          title: "Meeting time and location color",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPMeetingTimeLocationColor",
          defaultValue: "#ffffff",
          style: {
            selector: [
              ".dce-groups-modal--header-datetime_time",
              ".dce-groups-modal--header-datetime_location",
            ],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6445770-groups-widget-design-popup-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Meeting time and location icons color",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPMeetingTimeLocationIconColor",
          defaultValue: "#ffffff",
          style: {
            selector: [
              ".dce-groups-modal--header-datetime_time i",
              ".dce-groups-modal--header-datetime_location i",
            ],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6445770-groups-widget-design-popup-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Sections",
      options: [
        {
          title: "Section color",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPSectionBg",
          defaultValue: "#f3f3f3",
          style: {
            selector: [
              ".dce-groups-modal-row--details .dce-groups-modal-row--details-row",
            ],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6445770-groups-widget-design-popup-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Section header color",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPSectionHeader",
          defaultValue: "#6b6b6b",
          style: {
            selector: [
              ".dce-groups-modal-row--details h3",
              ".dce-groups-modal-row--details .col-icon .material-icons",
            ],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6445770-groups-widget-design-popup-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Section text",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPSectionText",
          defaultValue: "#656565",
          style: {
            selector: [".dce-groups-modal-row--details .p"],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6445770-groups-widget-design-popup-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Section link",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPSectionLink",
          defaultValue: "#5050af",
          style: {
            selector: [".dce-groups-modal-row--details .p a"],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6445770-groups-widget-design-popup-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Section link hover",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPSectionHover",
          defaultValue: "#6868ad",
          style: {
            selector: [".dce-groups-modal-row--details .p a:hover"],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6445770-groups-widget-design-popup-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Primary call-to-action",
      options: [
        {
          title: "Button color",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPCTA1stBtn",
          defaultValue: "#F02A9C",
          style: {
            selector: [
              ".dce-groups-modal-row--primary-cta .dce--groups-card--cta-btn",
            ],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6445770-groups-widget-design-popup-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Button color on hover",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPCTA1stBtnHover",
          defaultValue: "#cd2387",
          style: {
            selector: [
              ".dce-groups-modal-row--primary-cta .dce--groups-card--cta-btn:hover",
              ".dce-groups-modal-row--primary-cta .dce--groups-card--cta-btn:active",
              ".dce-groups-modal-row--primary-cta .dce--groups-card--cta-btn:focus",
            ],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6445770-groups-widget-design-popup-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPCTA1stBtnLabel",
          defaultValue: "#fff",
          style: {
            selector: [
              ".dce-groups-modal-row--primary-cta .dce--groups-card--cta-btn",
            ],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6445770-groups-widget-design-popup-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color on hover",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPCTA1stBtnLabelHover",
          defaultValue: "#fff",
          style: {
            selector: [
              ".dce-groups-modal-row--primary-cta .dce--groups-card--cta-btn:hover",
              ".dce-groups-modal-row--primary-cta .dce--groups-card--cta-btn:active",
              ".dce-groups-modal-row--primary-cta .dce--groups-card--cta-btn:focus",
            ],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6445770-groups-widget-design-popup-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Secondary call-to-action",
      options: [
        {
          title: "Button color",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPCTA2ndBtn",
          defaultValue: "#D2D2D2",
          style: {
            selector: [
              ".dce-groups-modal-row--secondary-cta .dce--groups-card--cta-btn",
            ],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6445770-groups-widget-design-popup-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Button color on hover",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPCTA2ndBtnHover",
          defaultValue: "#eae8e8",
          style: {
            selector: [
              ".dce-groups-modal-row--secondary-cta .dce--groups-card--cta-btn:hover",
              ".dce-groups-modal-row--secondary-cta .dce--groups-card--cta-btn:active",
              ".dce-groups-modal-row--secondary-cta .dce--groups-card--cta-btn:focus",
            ],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6445770-groups-widget-design-popup-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPCTA2ndBtnLabel",
          defaultValue: "#535353",
          style: {
            selector: [
              ".dce-groups-modal-row--secondary-cta .dce--groups-card--cta-btn",
            ],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6445770-groups-widget-design-popup-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color on hover",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPCTA2ndBtnLabelHover",
          defaultValue: "#888888",
          style: {
            selector: [
              ".dce-groups-modal-row--secondary-cta .dce--groups-card--cta-btn:hover",
              ".dce-groups-modal-row--secondary-cta .dce--groups-card--cta-btn:active",
              ".dce-groups-modal-row--secondary-cta .dce--groups-card--cta-btn:focus",
            ],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6445770-groups-widget-design-popup-styles",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
