/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/02/2019.
 */

const initialState = {
  collection: [],
  pagination: {},
  total: 0,
  page: 1,
  ipp: 20,

  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  create: false,
  createSuccess: false,
  createError: null,

  update: false,
  updateSuccess: false,
  updateError: null,

  delete: false,
  deleteSuccess: false,
  deleteError: null,
};

export default function reducer(
  state = { ...initialState, collection: [] },
  action
) {
  switch (action.type) {
    case "USERS_LICENSES_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: false,
        page: action.payload.page,
        ipp: action.payload.ipp,
      };

    case "USERS_LICENSES_FETCH_FULFILLED":
      return {
        ...state,
        collection: action.payload.organizations,
        pagination: action.payload.pagination,
        fetch: false,
        fetchSuccess: true,
      };

    case "USERS_LICENSES_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.payload,
      };

    case "LICENSE_UPDATE_FULFILLED":
      const licenseIndex = state.collection.findIndex(
        (i) => i.uuid == action.payload.uuid
      );

      if (licenseIndex >= 0) {
        state.collection.splice(licenseIndex, 1, action.payload);
      } else {
        state.collection.push(action.payload);
      }

      return {
        ...state,
        collection: [...state.collection],
        total: state.total + 1,
        update: false,
        updateSuccess: true,
      };

    case "LICENSE_DELETE_FULFILLED":
      return {
        ...state,
        collection: state.collection.filter(
          (i) => i.uuid != action.payload.uuid
        ),
        delete: false,
        deleteSuccess: true,
      };
    case "CLEAR_ERRORS":
      return {
        ...initialState,
        collection: [...state.collection],
      };

    case "USER_LOGOUT":
      return { ...initialState, collection: [] };

    default:
      return state;
  }

  return state;
}
