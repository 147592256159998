/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/04/2022.
 */
import GroupsStrategy from "./GroupsStrategy";
import { getLinkFromAnchor, linkify } from "./../../helpers/widgetItems";

const sanitizeValue = (value, defaultValue) => {
  return value && !Array.isArray(value) ? value : defaultValue;
};

class CCBGroupsStrategy extends GroupsStrategy {
  processData(data) {
    return data.map((i) => {
      const data = i.data;

      const description = linkify(
        sanitizeValue(data.description, "")
      ).replaceAll("\n", "<br/>");

      const leader = data.main_leader
        ? {
            name: data.main_leader.full_name,
            email:
              data.main_leader.email &&
              typeof data.main_leader.email === "string"
                ? data.main_leader.email
                : null,
          }
        : null;

      const group_type = i.group_type
        ? {
            name: i.group_type.data.name,
          }
        : null;

      const department = data.department
        ? {
            name: data.department.$,
          }
        : null;

      const address =
        data.addresses && data.addresses.address
          ? data.addresses.address
          : null;

      const addressData = address
        ? {
            city: sanitizeValue(address.city, null),
            line_1: sanitizeValue(address.line_1, null),
            line_2: sanitizeValue(address.line_2, null),
            //name: sanitizeValue(address.name, null),
            state: sanitizeValue(address.state, null),
            street_address: sanitizeValue(address.street_address, null),
            zip: sanitizeValue(address.zip, null),
          }
        : null;

      const ccbchurchAddress = data.calendar_feed
        .replace("webcal://", "")
        .match(/(\w)*/)[0];

      const join_group_url = `https://${ccbchurchAddress}.ccbchurch.com/group_detail.php?ax=add&group_id=${i.platform_metadata_id}`;

      const form =
        data.registration_forms && data.registration_forms.form
          ? Array.isArray(data.registration_forms.form)
            ? data.registration_forms &&
              data.registration_forms.form[
                data.registration_forms.form.length - 1
              ]
            : data.registration_forms && data.registration_forms.form
          : null;
      /*
      const registrationForm = form
        ? {
            name: form.name,
            description: form.description,
            url: form.url,
          }
        : null;*/

      const descriptionAnchors = description.match(
        /(<a[\s]+([^>]+)>((?:.(?!\<\/a\>))*.)<\/a>)/g
      );
      const links =
        descriptionAnchors && descriptionAnchors.length
          ? [descriptionAnchors[0]]
          : [];

      if (descriptionAnchors && descriptionAnchors.length > 1) {
        links.push(descriptionAnchors.splice(-1)[0]);
      }

      const ctas = {
        groupPage: {
          url: join_group_url,
          label: "Join group",
        },
        form: form ? { url: form.url, label: form.name } : null,
        linkFirst: links[0] ? getLinkFromAnchor(links[0]) : null,
        linkLast: links[1] ? getLinkFromAnchor(links[1]) : null,
        contactLeader: {
          url: `mailto:${leader.email}`,
          label: "Contact leader",
        },
      };

      // links for CCB groups are not anchors, we made them achors in the linkify function
      // this means that labels would be the same as the url
      // here we will default link labels to "Learn more"
      if (ctas.linkFirst) {
        ctas.linkFirst.label = "Learn more";
      }
      if (ctas.linkLast) {
        ctas.linkLast.label = "Learn more";
      }

      const meeting =
        (data.meeting_day && data.meeting_day.$) ||
        (data.meeting_time && data.meeting_time.$)
          ? {
              meeting_day: sanitizeValue(data.meeting_day.$, null),
              meeting_time: sanitizeValue(data.meeting_time.$, null),
            }
          : null;

      const isChildcareProvided =
        sanitizeValue(data.childcare_provided, null) === "true";

      const isUnlimited = data.group_capacity === "Unlimited";
      const isNumericCapacity =
        !isNaN(data.group_capacity) && isFinite(data.group_capacity);
      const isCapacityGreaterThanMembers =
        isNumericCapacity &&
        Number(data.group_capacity) <= Number(data.current_members);

      const RegistrationStatus =
        isUnlimited || !isCapacityGreaterThanMembers ? "Open" : "Closed";

      const primitives = {
        RegistrationStatus,
        InteractionType: data.interaction_type,
        Childcare: isChildcareProvided ? "Yes" : "No",
        GroupType: i.group_type ? i.group_type.data.name : null,
        Department: department ? department.name : null,
        Capacity: data.group_capacity,
        MembersCount: data.current_members * 1,
        MembershipType: data.membership_type,
        Leader: leader ? leader.name : null,
      };

      return {
        name: data.name,
        description: description,
        id: i.id,
        integration_metadata_id: i.platform_metadata_id,
        slug: i.id,
        display_location: sanitizeValue(data.location, null),
        cover_image: sanitizeValue(data.image, null),
        leader,
        contact: null,
        interaction_type: sanitizeValue(data.interaction_type, null),
        is_childcare_provided:
          sanitizeValue(data.childcare_provided, null) === "true",
        group_type: group_type,
        membership_type: data.membership_type,
        department,
        capacity: data.group_capacity,
        members_count: data.current_members,
        address: addressData,
        meeting,
        ctas,
        primitives,
      };
    });
  }
}

export default CCBGroupsStrategy;
