/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/10/2018.
 */
import {
  overwriteWidgetFilters,
  overwriteWidgetSettings,
  updateWidgetSettings,
} from "./widgetReducerTools";

const initialState = {
  model: null,

  fetch: false,
  fetchError: null,
  fetchSuccess: null,

  update: false,
  updateSuccess: false,
  updateError: null,

  delete: false,
  deleteSuccess: false,
  deleteError: null,

  rsvp: false,
  rsvpSuccess: false,
  rsvpError: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "CALENDAR_SET":
      if (!action.payload) {
        return { ...initialState };
      }

      if (action.payload.widget_settings) {
        return {
          ...initialState,
          model: {
            ...action.payload,
            calendar_filters: null,
          },
        };
      }

      return {
        ...initialState,
        model: action.payload,
      };

    case "CALENDAR_FETCH_FULFILLED":
      try {
        return {
          ...state,
          fetch: false,
          fetchSuccess: true,
          model: {
            ...state.model,
            ...action.payload,
          },
        };
      } catch (e) {
        console.log("Calendar Fetch Fulfilled, an exception occured: ", e);
      }

    case "CALENDAR_UPDATE":
      return {
        ...state,
        update: true,
        updateSuccess: false,
        updateError: null,
      };

    case "CALENDAR_UPDATE_FULFILLED":
      return {
        ...state,
        model: {
          ...action.payload,
        },
        update: false,
        updateSuccess: true,
      };

    case "CALENDAR_UPDATE_REJECTED":
      return {
        ...state,
        update: false,
        updateError: action.payload,
      };

    case "CALENDAR_DELETE":
      return {
        ...state,
        delete: true,
        deleteSuccess: false,
        deleteError: null,
      };

    case "CALENDAR_DELETE_FULFILLED":
      return {
        ...state,
        model: null,
        delete: false,
        deleteSuccess: true,
      };

    case "CALENDAR_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteError: action.payload,
      };

    case "WIDGET_DESIGN_PROP_UPDATE":
      if (!state.model) {
        break;
      }
      return updateWidgetSettings(state, action);

    case "WIDGET_DESIGN_PROP_OVERWRITE":
      if (!state.model) {
        break;
      }
      return overwriteWidgetSettings(state, action);

    case "WIDGET_FILTER_PROP_OVERWRITE":
      if (!state.model) {
        break;
      }
      return overwriteWidgetFilters(state, action);

    case "EVENT_RSVP":
      return {
        ...state,
        rsvp: true,
        rsvpSuccess: false,
        rsvpError: null,
      };

    case "EVENT_RSVP_FULFILLED":
      return {
        ...state,
        rsvp: false,
        rsvpSuccess: true,
      };

    case "EVENT_RSVP_REJECTED":
      return {
        ...state,
        rsvp: false,
        rsvpError: action.payload,
      };

    case "CALENDAR_FILTERS_FETCH_FULFILLED":
      return {
        ...state,
        model: {
          ...state.model,
          calendar_filters:
            action.payload && action.payload.length
              ? action.payload.map((i) => i.id)
              : [],
        },
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        model: state.model || null,
      };

    default:
      return state;
  }

  return state;
}
