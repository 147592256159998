const initialState = {
  collection: [],

  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  add: false,
  addSuccess: false,
  addError: null,

  edit: false,
  editSuccess: false,
  editError: null,

  delete: false,
  deleteSuccess: false,
  deleteError: null,

  resendInvitation: false,
  resendInvitationSuccess: false,
  resendInvitationError: null,
};

export default function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case "ORGANIZATION_MEMBERS_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "ORGANIZATION_MEMBERS_FULFILLED":
      return {
        ...state,
        collection: action.payload?.organizations_users,
        fetch: false,
        fetchSuccess: true,
        fetchError: null,
      };
    case "ORGANIZATION_MEMBERS_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.payload,
      };

    case "ORGANIZATION_MEMBERS_ADD":
      return {
        ...state,
        add: true,
        addSuccess: false,
        addError: null,
      };
    case "ORGANIZATION_MEMBERS_ADD_FULFILLED":
      return {
        ...state,
        add: false,
        addSuccess: true,
        addError: null,
      };
    case "ORGANIZATION_MEMBERS_ADD_REJECTED":
      return {
        ...state,
        add: false,
        addSuccess: false,
        addError: action.payload,
      };

    case "ORGANIZATION_MEMBERS_EDIT":
      return {
        ...state,
        edit: true,
        editSuccess: false,
        editError: null,
      };
    case "ORGANIZATION_MEMBERS_EDIT_FULFILLED":
      return {
        ...state,
        edit: false,
        editSuccess: true,
        editError: null,
      };
    case "ORGANIZATION_MEMBERS_EDIT_REJECTED":
      return {
        ...state,
        edit: false,
        editSuccess: false,
        editError: action.payload,
      };

    case "ORGANIZATION_MEMBERS_DELETE":
      return {
        ...state,
        delete: true,
        deleteSuccess: false,
        deleteError: null,
      };
    case "ORGANIZATION_MEMBERS_DELETE_FULFILLED":
      return {
        ...state,
        delete: false,
        deleteSuccess: true,
        deleteError: null,
      };
    case "ORGANIZATION_MEMBERS_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteSuccess: false,
        deleteError: action.payload,
      };

    case "ORGANIZATION_MEMBERS_REINVITE":
      return {
        ...state,
        resendInvitation: true,
        resendInvitationSuccess: false,
        resendInvitationError: null,
        resendInvitationUUID: action.uuid,
      };
    case "ORGANIZATION_MEMBERS_REINVITE_FULFILLED":
      return {
        ...state,
        resendInvitation: false,
        resendInvitationSuccess: true,
        resendInvitationError: null,
      };
    case "ORGANIZATION_MEMBERS_REINVITE_REJECTED":
      return {
        ...state,
        resendInvitation: false,
        resendInvitationSuccess: false,
        resendInvitationError: action.payload,
      };
    case "ORGANIZATION_MEMBERS_REINVITE_RESET":
      return {
        ...state,
        resendInvitation: null,
        resendInvitationSuccess: false,
        resendInvitationError: null,
        resendInvitationUUID: null,
      };

    case "CHECK_INVITATION":
      return {
        ...state,
        checkInvitation: true,
        checkInvitationSuccess: false,
        checkInvitationError: null,
      };
    case "CHECK_INVITATION_FULFILLED":
      return {
        ...state,
        checkInvitation: false,
        checkInvitationSuccess: true,
        checkInvitationError: null,
        invitationOrganizationData: action.payload.organization,
      };
    case "CHECK_INVITATION_REJECTED":
      return {
        ...state,
        checkInvitation: false,
        checkInvitationSuccess: false,
        checkInvitationError: action.payload,
      };

    case "ACCEPT_INVITATION":
      return {
        ...state,
        acceptInvitation: true,
        acceptInvitationSuccess: false,
        acceptInvitationError: null,
      };
    case "ACCEPT_INVITATION_FULFILLED":
      return {
        ...state,
        acceptInvitation: false,
        acceptInvitationSuccess: true,
        acceptInvitationError: null,
        acceptInvitationData: action.payload,
      };
    case "ACCEPT_INVITATION_REJECTED":
      return {
        ...state,
        acceptInvitation: false,
        acceptInvitationSuccess: false,
        acceptInvitationError: action.payload,
      };

    case "REJECT_INVITATION":
      return {
        ...state,
        rejectInvitation: true,
        rejectInvitationSuccess: false,
        rejectInvitationError: null,
      };
    case "REJECT_INVITATION_FULFILLED":
      return {
        ...state,
        rejectInvitation: false,
        rejectInvitationSuccess: true,
        rejectInvitationError: null,
        rejectInvitationData: action.payload,
      };
    case "REJECT_INVITATION_REJECTED":
      return {
        ...state,
        rejectInvitation: false,
        rejectInvitationSuccess: false,
        rejectInvitationError: action.payload,
      };

    default:
      return state;
  }

  return state;
}
