const initialState = {
  integrationFiltersCollection: [],
  //calendarFiltersCollection: [],
  widgetFiltersCollection: [],

  fetchIntegrationFilters: false,
  fetchIntegrationFiltersSuccess: null,
  fetchIntegrationFiltersError: null,
  /*

  fetchCalendarFilters: false,
  fetchCalendarFiltersSuccess: null,
  fetchCalendarFiltersError: null,
*/

  fetchWidgetFilters: false,
  fetchWidgetFiltersSuccess: null,
  fetchWidgetFiltersError: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "INTEGRATION_FILTERS_FETCH":
      return {
        ...state,
        fetchIntegrationFilters: true,
        fetchIntegrationFiltersSuccess: false,
        fetchIntegrationFiltersError: null,
      };

    case "INTEGRATION_FILTERS_FETCH_FULFILLED":
      return {
        ...state,
        fetchIntegrationFilters: false,
        fetchIntegrationFiltersSuccess: true,
        integrationFiltersCollection: action.payload,
      };

    case "INTEGRATION_FILTERS_FETCH_REJECTED":
      return {
        ...state,
        fetchIntegrationFilters: false,
        fetchIntegrationFiltersError: action.payload,
      };

    case "WIDGET_FILTERS_FETCH":
      return {
        ...state,
        fetchWidgetFilters: true,
        fetchWidgetFiltersSuccess: false,
        fetchIntegrationFiltersError: null,
      };

    case "WIDGET_FILTERS_FETCH_FULFILLED":
      return {
        ...state,
        fetchWidgetFilters: false,
        fetchWidgetFiltersSuccess: true,
        widgetFiltersCollection: action.payload,
      };

    case "WIDGET_FILTERS_FETCH_REJECTED":
      return {
        ...state,
        fetchWidgetFilters: false,
        fetchWidgetFiltersError: action.payload,
      };

    case "CALENDAR_SET":
    case "ALERT_SET":
    case "FILTERS_CLEAR":
      return {
        ...initialState,
      };

    default:
      return state;
  }

  return state;
}
