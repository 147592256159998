const initialState = {
  collection: [],

  fetch: false,
  fetchError: null,
  fetchSuccess: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "FONTS_FETCH":
      return {
        ...state,
        fetch: true,
      };

    case "FONTS_FETCH_FULFILLED":
      return {
        ...state,
        collection: action.payload,
        fetch: false,
        fetchSuccess: true,
        fetchError: false,
      };

    case "FONTS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: true,
      };

    default:
      return state;
  }

  return state;
}
