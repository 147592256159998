/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/03/2023.
 */

import { AT } from "../../../consts";

const FontSizesOptions = [
  {
    label: "Extra small",
    value: "12px",
  },
  {
    label: "Small",
    value: "14px",
  },
  {
    label: "Medium",
    value: "16px",
  },
  {
    label: "Large",
    value: "18px",
  },
  {
    label: "Extra large",
    value: "20px",
  },
];

export const definitions = {
  title: "Countdown timer",
  icon: "av_timer",
  groups: [
    {
      label: "Setup",
      options: [
        {
          title: "Show countdown timer",
          templates: [AT.banner],
          name: "dcaShowCountdown",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "Show/hide the timer.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show count down timer for all day events",
          templates: [AT.banner],
          name: "dcaShowCountdownForAllDay",
          defaultValue: 0,
          tooltip: {
            message: "Show/hide the timer regardless of all day events.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
          show: [
            {
              field: "dcaShowCountdown",
              value: 1,
              defaultValue: 1,
            },
          ],
        },
      ],
    },
    {
      label: "Typography",
      options: [
        {
          title: "Font family",
          templates: [AT.banner],
          name: "fontFamilyCDT",
          defaultValue: "Teko",
          style: {
            selector: (uuid) =>
              ".dc-alerts-banner table.dc--banner-container_countdown .dc--banner-container_countdown_thead",
            property: "font-family",
          },
          show: [
            {
              field: "dcaShowCountdown",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "Choose your preferred timer font.",
          },
          control: {
            kind: "fontFamily",
            options: {
              placeholder: "Search Google Fonts",
              inherit: true,
            },
          },
        },
        {
          title: "Size",
          templates: [AT.banner],
          name: "dceAFontSizeCDT",
          defaultValue: "16px",
          style: {
            selector: [
              (uuid) =>
                ".dc-alerts-banner table.dc--banner-container_countdown",
            ],
            property: ["font-size"],
          },
          show: [
            {
              field: "dcaShowCountdown",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "Chose your preferred timer number and text size.",
          },
          control: {
            kind: "range",
            options: FontSizesOptions,
          },
        },
      ],
    },
  ],
};
