/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/03/2023.
 */

export const CTA_OPTIONS_CCB = [
  {
    label: "Open first URL from event's description",
    value: "linkFirst",
  },
  {
    label: "Open last URL from event's description",
    value: "linkLast",
  },
  {
    label: "Open form",
    value: "form",
  },
  {
    label: "Open CCB event page",
    value: "eventPage",
  },
  {
    label: "Contact leader",
    value: "organizer",
  },
];
export const CTA_OPTIONS_PCO = [
  {
    label: "Open first URL from event's description",
    value: "linkFirst",
  },
  {
    label: "Open last URL from event's description",
    value: "linkLast",
  },
  {
    label: "Open form",
    value: "form",
  },
  {
    label: "Open Church Center event page",
    value: "eventPage",
  },
];

export const CTA_OPTIONS_GOOGLE = [
  {
    label: "Open first URL from event's description",
    value: "linkFirst",
  },
  {
    label: "Open last URL from event's description",
    value: "linkLast",
  },
  {
    label: "Contact organizer",
    value: "organizer",
  },
];

export const CTA_OPTIONS_BREEZE = [
  {
    label: "Open first URL from event's description",
    value: "linkFirst",
  },
  {
    label: "Open last URL from event's description",
    value: "linkLast",
  },
  {
    label: "Contact organizer",
    value: "organizer",
  },
];
