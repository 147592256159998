import { CT, IT } from "../../../consts";

export const definitions = {
  title: (
    <span>
      Branding <span className={"badge badge-success"}>NEW</span>
    </span>
  ),
  icon: "fingerprint",
  groups: [
    {
      label: "Header",
      options: [
        {
          title: "Show logo on the hosted page",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          applicable: "design",
          name: "brandShowLogo",
          defaultValue: 0,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show back button on the hosted page",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          applicable: "design",
          name: "brandShowBackButton",
          defaultValue: 0,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Back button label",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          applicable: "design",
          name: "brandBackButtonLabel",
          defaultValue: "Back to home",
          show: [
            {
              field: "brandShowBackButton",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Back button URL",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          applicable: "design",
          name: "brandBackButtonURL",
          defaultValue: "",
          show: [
            {
              field: "brandShowBackButton",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Link color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "brandingLinkColor",
          defaultValue: "#5e5e5e",
          show: [
            {
              field: "brandShowBackButton",
              value: 1,
              defaultValue: 0,
            },
          ],
          style: {
            selector: [".dc-calendar-branding--header-backbutton-container a"],
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Link color on hover",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "brandingLinkColorHover",
          defaultValue: "#444444B2",
          show: [
            {
              field: "brandShowBackButton",
              value: 1,
              defaultValue: 0,
            },
          ],
          style: {
            selector: [
              ".dc-calendar-branding--header-backbutton-container a:hover",
            ],
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
