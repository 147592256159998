/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/10/2023.
 */

const WidgetsMiddleware = (storeAPI) => (next) => (action) => {
  switch (action.type) {
    case "WIDGETS_FETCH_FULFILLED":
      // console.log(action);
      action.payload.widgets = action.payload.widgets.map((i) => {
        if (i.integration && !Number.parseInt(i.integration.available)) {
          i.integration = null;
        }
        return i;
      });

      break;

    case "WIDGET_FETCH_FULFILLED":
    case "WIDGET_UPDATE_FULFILLED":
      if (
        action.payload.integration &&
        !Number.parseInt(action.payload.integration.available)
      ) {
        action.payload.integration = null;
      }
      break;
  }

  next(action);
};

export default WidgetsMiddleware;
