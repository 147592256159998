import { PLANS } from "../../../consts";

/**
 * Created by piotr.pozniak@thebeaverhead.com on 03/06/2021.
 */

class CalendarIntegrationStrategy {
  constructor(widget) {
    this.widgetModel = widget;
  }

  type = null;

  widgetModel = null;

  /**
   * list names of event_metadata.kind values used for events filtering. E.g. group.
   * @type {[]}
   */
  availableFilters = [];

  /**
   * settings per available filter. properties:
   * - label - used to display name of the filter
   * @type {{}}
   */
  filtersSettings = {};

  /**
   * an jsx object rendered to inform what filters in calendar settings do.
   * @type {null}
   */
  filterLabelCTA = "Overload this component in your strategy";

  /**
   * an jsx object rendered to inform about mismatch in private events configuration.
   * Happens when user wants to display private events but sync_private is turned off.
   * @type {string}
   */
  privateEventsSettingsWarning = "Overload this component in your strategy";

  /**
   *
   * @type {[]}
   */
  featuredAllowedPlans = [];

  /**
   * processes returned events_metadata data into AsyncSelector collection grouped
   * by events_metadata.kind.
   * @param data
   * @returns {}
   * @example return data:
   * {
   *   "groups": [
   *     {
   *       label: "display label",
   *       value: eg. id
   *       uuid: same as value,
   *     }
   *   ]
   * }
   *
   */
  filterRawDataToStateObject = (data) => {
    console.warn(
      "CalendarIntegrationStrategy::filterRawDataToStateObject not inherited"
    );

    return {};
  };

  /**
   * processes returned events_metadata data into AsyncSelector collection
   * @param data
   * @returns
   * @example return data:
   * [
   *     {
   *       label: "display label",
   *       value: eg. id
   *       uuid: same as value,
   *     }
   *  ]
   *
   *
   */
  filterRawToDisplayObject = (data) => {
    console.warn(
      "CalendarIntegrationStrategy::filterRawToDisplayObject not inherited"
    );

    return {};
  };

  /**
   * renders a display name from events_metadata object
   * @param filter
   */
  filterRenderName = (filter) => {
    console.warn("CalendarIntegrationStrategy::filterRenderName not inherited");
  };

  /**
   * if not null, will show private events filtering to set show_private_events flag.
   * {
   *   privateEventsTooltip: {
   *     message: "",
   *     url: ""
   *   },
   *   toggleButtonLabel: "Show private events"
   * }
   * @type {null}
   */
  privateEventsControl = null;

  /**
   * An object where key is a filter kind. Tells whether user can filter by this kind.
   * Applies to header filter selectors and header filter labels (summary of used filters).
   * @type {{}}
   */
  canUserFilter = {};
}

export default CalendarIntegrationStrategy;
