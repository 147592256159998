const initialState = {
  initialData: null,
  model: null,

  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  create: false,
  createSuccess: false,
  createError: null,

  markVendorPaid: false,
  markVendorPaidSuccess: false,
  markVendorPaidError: null,
};

export default function reducer(
  state = { ...initialState, collection: [] },
  action
) {
  switch (action.type) {
    case "PAYOUT_INITIAL_DATA_FETCH":
    case "PAYOUT_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
        initialData: null,
        model: null,
      };

    case "PAYOUT_INITIAL_DATA_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,
        initialData: action.payload,
      };

    case "PAYOUT_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,
        model: action.payload,
      };

    case "PAYOUT_INITIAL_DATA_FETCH_REJECTED":
    case "PAYOUT_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchError: action.payload,
      };

    case "PAYOUT_CREATE":
      return {
        ...state,
        create: true,
        createSuccess: false,
        createError: null,
      };

    case "PAYOUT_CREATE_FULFILLED":
      return {
        ...state,
        create: false,
        createSuccess: true,
        model: action.payload,
      };

    case "PAYOUT_CREATE_REJECTED":
      return {
        ...state,
        create: false,
        createError: action.payload,
      };

    case "VENDOR_PAYOUT_PAID":
      return {
        ...state,
        markVendorPaid: true,
        markVendorPaidSuccess: false,
        markVendorPaidError: null,
      };

    case "VENDOR_PAYOUT_PAID_FULFILLED":
      return {
        ...state,
        markVendorPaid: false,
        markVendorPaidSuccess: true,
        model: action.payload,
      };

    case "VENDOR_PAYOUT_PAID_REJECTED":
      return {
        ...state,
        markVendorPaid: false,
        markVendorPaidError: action.payload,
      };

    default:
      return state;
  }

  return state;
}
