/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/10/2023.
 */
import ItemsStrategy from "../ItemsStrategy";

const sanitizeValue = (value, defaultValue) => {
  return value && !Array.isArray(value) ? value : defaultValue;
};

class PCOSignupsStrategy extends ItemsStrategy {
  processData(data) {
    let items = data.map((i) => {
      const attributes = i.data.attributes;
      // console.log(attributes);
      const description = sanitizeValue(attributes.description, "");
      const strippedDescription = sanitizeValue(
        attributes.stripped_description,
        ""
      );
      /*
      const headerImageJSON = sanitizeValue(attributes.header_image, "");

      const headerImage = headerImageJSON ? JSON.parse(headerImageJSON) : {};

      const contact = attributes.contact_email
        ? attributes.contact_email.split(",")[0].trim()
        : null;*/
      /*

      const leader = contact
        ? {
            name: contact,
            email: contact,
          }
        : null;

      const meeting = attributes.schedule
        ? {
            meeting_day: sanitizeValue(attributes.schedule, null),
            meeting_time: null,
          }
        : null;

      const group_type = i.group_type.data.attributes;

      const locationData = i.location ? i.location.data.attributes : null;

      const publicChurchUrl = attributes.public_church_center_web_url;
*/

      /*      const descriptionAnchors = description.match(
        /(<a[\s]+([^>]+)>((?:.(?!\<\/a\>))*.)<\/a>)/g
      );
      const links =
        descriptionAnchors && descriptionAnchors.length
          ? [descriptionAnchors[0]]
          : [];

      if (descriptionAnchors && descriptionAnchors.length > 1) {
        links.push(descriptionAnchors.splice(-1)[0]);
      }*/
      const starts_at =
        attributes.starts_at && !isNaN(new Date(attributes.starts_at).getTime())
          ? new Date(attributes.starts_at)
          : null;

      const ends_at =
        attributes.ends_at && !isNaN(new Date(attributes.ends_at).getTime())
          ? new Date(attributes.ends_at)
          : null;

      const output = {
        name: attributes.name,
        description: strippedDescription || "",
        id: i.id,
        cover_image: attributes.logo_url,
        formUrl: attributes.new_registration_url,
        isFeatured: attributes.featured,
        frontendUrl: attributes.public_url,
        starts_at,
        ends_at,
        /*        integration_metadata_id: i.platform_metadata_id,
        slug: i.id,
        display_location: attributes.location,
        contact,
        leader,
        group_type,
        members_count: attributes.memberships_count,
        meeting,
        enrollment_open:
          sanitizeValue(attributes.enrollment_open, "false") === "true",
        address,
        join_group_url,
        ctas,
        primitives,*/
      };
      return output;
    });

    const sortItemsByFeaturedFlag =
      this.model?.widget_settings?.sorting?.is_featured_first;

    if (sortItemsByFeaturedFlag) {
      items = items.sort((a, b) => {
        if (a.isFeatured && !b.isFeatured) {
          return -1;
        }
        return 0;
      });
    }

    return items;
  }
}

export default PCOSignupsStrategy;
