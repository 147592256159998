/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/02/2023.
 */

import { definitions as Banner } from "./banner";
import { definitions as HiddenOptions } from "./hidden";
import { definitions as CountDown } from "./countdown";
import { definitions as BannerCTA } from "./banner_cta";
import { definitions as BannerDesign } from "./banner_design";

const definitions = [Banner, BannerDesign, BannerCTA, CountDown, HiddenOptions];

export default definitions;
