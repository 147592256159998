/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/06/2021.
 */

import { CT } from "../../../consts";

export const definitions = {
  title: "Slider",
  icon: "view_carousel",
  groups: [
    {
      label: "Presentation",
      options: [
        {
          title: "Number of slides to show",
          templates: [CT.cardSlider],
          name: "sliderSlidesToShow",
          defaultValue: 2,
          tooltip: {
            url: "https://help.display.church/en/articles/5071995-design-slider-number-of-slides-to-show",
            message: "Set the number of slides to show at one time.",
          },
          dependency: {
            field: "sliderLoop",
            value: 1,
            defaultValue: 1,
            message: "This option works better if you set slides looping",
          },
          checkDependency: [
            { field: "sliderCenterSlides", value: 1, defaultValue: 1 },
            {
              field: "sliderSlidesToShow",
              value: (val) => val > 1,
              defaultValue: 1,
            },
            { field: "sliderLoop", value: 0, defaultValue: 1 },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "One", value: 1 },
                { label: "Two", value: 2 },
                { label: "Three", value: 3 },
                { label: "Four", value: 4 },
                { label: "Five", value: 5 },
              ],
            },
          },
        },
        {
          title: "% of the next slide to show",
          templates: [CT.cardSlider],
          name: "sliderNextSlideToShow",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/5112610-design-slider-of-the-next-slide-to-show",
            message: "Show a preview of the upcoming slide.",
          },
          dependency: {
            field: "sliderCenterSlides",
            value: 0,
            defaultValue: 1,
            message: "This option works better for not centered slides",
          },
          checkDependency: [
            {
              field: "sliderNextSlideToShow",
              value: (val) => val != 0,
              defaultValue: 1,
            },
          ],
          show: [
            { field: "sliderCenterSlides", value: 0, defaultValue: 1 },
            { field: "sliderLoop", value: 0, defaultValue: 1 },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "Do not show upcoming slides", value: 0 },
                { label: "10%", value: 1 },
                { label: "20%", value: 2 },
                { label: "30%", value: 3 },
              ],
            },
          },
        },
        {
          title: "Size of dots",
          templates: [CT.cardSlider],
          name: "sliderSizeOfDots",
          defaultValue: 16,
          tooltip: {
            url: "https://help.display.church/en/articles/5127725-design-slider-show-dots",
            message: "Increase/decrease dot size.",
          },
          show: [{ field: "sliderShowDots", value: 1, defaultValue: 1 }],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "Small", value: 6 },
                { label: "Medium", value: 10 },
                { label: "Large", value: 16 },
                { label: "Extra large", value: 20 },
              ],
            },
          },
        },
      ],
    },
    {
      label: "Behavior",
      options: [
        {
          title: "Center slides",
          templates: [CT.cardSlider],
          name: "sliderCenterSlides",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/5112648-design-slider-center-slides",
            message: "Center your slides nicely in the screen view.",
          },
          control: {
            kind: "checkbox",
            options: {
              updateValue: (val) => {
                if (val[0].value == 1) {
                  val.push({
                    field: "sliderLoop",
                    value: 1,
                  });

                  val.push({
                    field: "sliderNextSlideToShow",
                    value: 0,
                  });
                }
                return val;
              },
            },
          },
        },
        {
          title: "Loop slides",
          templates: [CT.cardSlider],
          name: "sliderLoop",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/5128825-design-slider-loop-slides",
            message: "Continuous looping of your event slides.",
          },
          dependency: {
            field: "infiniteScroll",
            value: 0,
            defaultValue: 0,
            message:
              "This option should not be turned on when using infinite scroll",
          },
          control: {
            kind: "checkbox",
            options: {
              updateValue: (val) => {
                if (val[0].value == 1) {
                  val.push({
                    field: "sliderNextSlideToShow",
                    value: 0,
                  });
                }
                return val;
              },
            },
          },
        },
        {
          title: "Show dots",
          templates: [CT.cardSlider],
          name: "sliderShowDots",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/5127725-design-slider-show-dots",
            message:
              "Indicate events before/after current view + navigation option.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },

        {
          title: "Auto play",
          templates: [CT.cardSlider],
          name: "sliderAutoPlay",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/5129014-design-slider-auto-play",
            message: "Automatic, timed scrolling through your event slides.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Auto play speed (slides/second)",
          templates: [CT.cardSlider],
          name: "sliderAutoplaySpeed",
          defaultValue: 5,
          tooltip: null,
          show: [{ field: "sliderAutoPlay", value: 1, defaultValue: 0 }],
          control: {
            kind: "number",
            options: {
              min: 1,
              placeholder: "Speed of autoplay in seconds",
            },
          },
        },
      ],
    },
    {
      label: "Dots",
      options: [
        {
          title: "Color",
          templates: [CT.cardSlider],
          name: "csDotsColor",
          defaultValue: "#000",
          style: {
            selector: [".calendar-card-view .slick-dots button::before"],
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
