import React from "react";
import CCBStrategy from "./CCBStrategy";
import { IT } from "../../../consts";
import PCOStrategy from "./PCOStrategy";
import GoogleStrategy from "./GoogleStrategy";
import BreezeStrategy from "./BreezeStrategy";

const IntegrationContext = React.createContext("");

/**
 *
 * @param integrationType
 * @param widget
 * @returns {CalendarIntegrationStrategy}
 */
export const getStrategy = (integrationType, widget) => {
  switch (integrationType) {
    case IT.ccb:
      return new CCBStrategy(widget);
    case IT.pco:
      return new PCOStrategy(widget);
    case IT.google:
      return new GoogleStrategy(widget);
    case IT.breeze:
      return new BreezeStrategy(widget);
  }
};

export default IntegrationContext;
