/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/03/2023.
 */

import { AT } from "../../../consts";

const FontSizesOptions = [
  {
    label: "Extra small",
    value: "12px",
  },
  {
    label: "Small",
    value: "14px",
  },
  {
    label: "Medium",
    value: "16px",
  },
  {
    label: "Large",
    value: "18px",
  },
  {
    label: "Extra large",
    value: "20px",
  },
];

export const definitions = {
  title: "Design",
  icon: "brush",
  groups: [
    {
      label: "Colors",
      options: [
        {
          title: "Initial background color",
          templates: [AT.banner],
          name: "dcaBgColorInitial",
          defaultValue: "#0071e3",
          style: {
            selector: [".dc--banner.bg-flash-effect"],
            property: ["--ribbon-background-color-opening"],
          },
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message:
              "The color of the banner background when it first appears.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Background color",
          templates: [AT.banner],
          name: "dcaBgColor",
          defaultValue: "#f5f5f7",
          style: {
            selector: [".dc--banner.bg-flash-effect"],
            property: ["--ribbon-background-color"],
          },
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message:
              "The color of the banner background after a second or two.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Initial text color",
          templates: [AT.banner],
          name: "dcaColorInitial",
          defaultValue: "#fff",
          style: {
            selector: [".dc--banner-container.color-flash-effect"],
            property: ["--ribbon-text-color-opening"],
          },
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "The color of the text when it first appears.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color",
          templates: [AT.banner],
          name: "dcaColor",
          defaultValue: "#1d1d1f",
          style: {
            selector: [".dc--banner-container.color-flash-effect"],
            property: ["--ribbon-text-color"],
          },
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "The color of the text after a second or two.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Close button color",
          templates: [AT.banner],
          name: "dcaCloseBtnColor",
          defaultValue: "#6e6e73",
          style: {
            selector: [".dc--banner-close_container svg"],
            property: ["fill"],
          },
          show: [
            {
              field: "dcaCanHide",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "The color of the 'x' in the second view.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Close button color on hover",
          templates: [AT.banner],
          name: "dcaCloseBtnColorHover",
          defaultValue: "#6e6e73",
          style: {
            selector: [".dc--banner-close_container:hover svg"],
            property: ["fill"],
          },
          show: [
            {
              field: "dcaCanHide",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "The color of the 'x' on hover.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Typography",
      options: [
        {
          title: "Font family",
          templates: [AT.banner],
          name: "fontFamily",
          defaultValue: "Roboto",
          style: {
            selector: (uuid) => ".dc-alerts-banner",
            property: "font-family",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "Choose your preferred event alert font.",
          },
          control: {
            kind: "fontFamily",
            options: {
              placeholder: "Search Google Fonts",
              inherit: true,
            },
          },
        },
      ],
    },
    {
      label: "Size",
      options: [
        {
          title: "Responsive",
          templates: [AT.banner],
          name: "dceAFontSizeDesktop",
          defaultValue: "16px",
          style: {
            selector: [(uuid) => ".dc-alerts-banner"],
            property: ["font-size"],
          },
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "Chose your preferred event alert size.",
          },
          control: {
            kind: "range",
            options: FontSizesOptions,
          },
        },
      ],
    },
  ],
};
