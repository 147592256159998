import { CT } from "../../../consts";

export const definitions = {
  title: "Tiles",
  icon: "padding",
  groups: [
    {
      label: "Display information",
      options: [
        {
          title: "Show Event name",
          templates: [CT.bubble],
          name: "bcShowEventName",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show Event date",
          templates: [CT.bubble],
          name: "bcShowEventDate",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "If recurring, show icon",
          templates: [CT.bubble],
          name: "bcShowRecurringIcon",
          defaultValue: 1,
          tooltip: null,
          show: [
            {
              field: "bcShowEventDate",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Presentation",
      options: [
        {
          title: "Wrap items",
          templates: [CT.bubble],
          name: "bcWrap",
          defaultValue: 0,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Gap between items",
          templates: [CT.bubble],
          name: "bcGap",
          defaultValue: "30px",
          style: [
            {
              selector: ".calendar-bubble-view_container",
              property: ["gap"],
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Tile size",
          templates: [CT.bubble],
          name: "bcBubbleSize",
          defaultValue: "small",
          tooltip: null,
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                {
                  label: "Tiny",
                  value: "tiny",
                },
                {
                  label: "Small",
                  value: "small",
                },
                {
                  label: "Medium",
                  value: "medium",
                },
                {
                  label: "Large",
                  value: "large",
                },
              ],
            },
          },
        },
        {
          title: "Tile style",
          templates: [CT.bubble],
          name: "bcBubbleStyle",
          defaultValue: "rounded",
          tooltip: null,
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                {
                  label: "Square",
                  value: "square",
                },
                {
                  label: "Rounded",
                  value: "rounded",
                },
                {
                  label: "Circle",
                  value: "circle",
                },
              ],
            },
          },
        },
        {
          title: "Show border around tile",
          templates: [CT.bubble],
          name: "bcBubbleImgBorder",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Border size",
          templates: [CT.bubble],
          name: "bcBubbleImgBorderSize",
          defaultValue: "1px",
          style: [
            {
              selector: ".bubble-view--bubble-image",
              property: ["border-width"],
            },
          ],
          show: [
            {
              field: "bcBubbleImgBorder",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
      ],
    },
  ],
};
