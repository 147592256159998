const initialState = {
  collection: [],

  fetch: false,
  fetchSuccess: false,
  fetchError: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "USER_LOGOUT":
      return initialState;

    case "PAYOUTS_FETCH":
      return {
        ...state,
        fetch: true,
        fetchError: null,
        fetchSuccess: false,
      };

    case "PAYOUTS_FETCH_FULFILLED":
      return {
        ...state,
        collection: action.payload.payouts.reverse(),
        fetch: false,
        fetchSuccess: true,
        fetchError: null,
      };

    case "PAYOUTS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.payload,
      };

    case "VENDOR_PAYOUT_PAID_FULFILLED":
      const payoutIndex = state.collection.findIndex(
        (i) => i.uuid == action.payload.uuid
      );

      state.collection.splice(payoutIndex, 1, action.payload);
      return {
        ...state,
        collection: [...state.collection],
      };

    case "PAYOUT_DELETE":
      return {
        ...state,
        delete: true,
        deleteSuccess: false,
        deleteError: null,
      };

    case "PAYOUT_DELETE_FULFILLED":
      const deletedPayoutIndex = state.collection.findIndex(
        (i) => i.uuid == action.payload.uuid
      );

      state.collection.splice(deletedPayoutIndex, 1);
      return {
        ...state,
        delete: false,
        deleteSuccess: true,
        collection: [...state.collection],
      };

    case "PAYOUT_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteError: action.payload,
      };

    default:
      return state;
  }

  return state;
}
