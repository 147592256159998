/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/06/2021.
 */

import { CT, IT } from "../../../consts";

export const definitions = {
  title: "Badges",
  icon: "star_border",
  groups: [
    {
      label: "Position",
      options: [
        {
          title: "Badges location",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          name: "recurringBadgeLocation",
          defaultValue: "image-top",
          tooltip: {
            url: "https://help.display.church/en/articles/5844206-design-badges",
            message: "Choose placement of badge in calendar view.",
          },

          show: [
            {
              field: "featuredShowBadge",
              value: 1,
              defaultValue: 0,
            },
            {
              field: "recurringShowBadge",
              value: 1,
              defaultValue: 0,
              or: true,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "Top of the cover image", value: "image-top" },
                { label: "Bottom of the cover image", value: "image-bottom" },
                { label: "Top of the card content", value: "title-top" },
                { label: "Bottom of the card content", value: "title-bottom" },
              ],
            },
          },
        },
      ],
    },
    {
      label: "Recurring Events",
      options: [
        {
          title: "Show badge",
          templates: [
            CT.eventList,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.weekly,
            CT.monthly,
          ],
          name: "recurringShowBadge",
          defaultValue: 0,
          tooltip: {
            message:
              "Display badge on image or near event title in calendar view.",
            url: "https://help.display.church/en/articles/5844206-design-badges",
          },

          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show badge in event details",
          templates: [
            CT.eventList,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.weekly,
            CT.monthly,
          ],
          name: "recurringShowBadgeInDetails",
          defaultValue: 0,
          tooltip: {
            message: "Display badge in popup or accordion details.",
            url: "https://help.display.church/en/articles/5844206-design-badges",
          },

          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Recurring event background",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
          ],

          name: "recurringBadgeBgColor",
          defaultValue: "#ff72e9",
          style: [
            {
              selector: [".dce--event-badge.dce--event-badge-recurring"],
              property: "background-color",
            },
          ],
          tooltip: {
            message: "Color of area behind the badge text.",
            url: "https://help.display.church/en/articles/5844206-design-badges",
          },
          show: [
            {
              field: "recurringShowBadge",
              value: 1,
              defaultValue: 0,
            },
            {
              field: "recurringShowBadgeInDetails",
              value: 1,
              defaultValue: 0,
              or: true,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Recurring event background",
          templates: [CT.monthly],

          name: "recurringBadgeBgColor",
          defaultValue: "#ff72e9",
          style: [
            {
              selector: [".dce--event-badge.dce--event-badge-recurring"],
              property: "background-color",
            },
          ],
          tooltip: {
            message: "Color of area behind the badge text.",
            url: "https://help.display.church/en/articles/5844206-design-badges",
          },
          show: [
            {
              field: "recurringShowBadgeInDetails",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Recurring event label",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
          ],
          name: "recurringBadgeColor",
          defaultValue: "#ffffff",
          style: [
            {
              selector: [".dce--event-badge.dce--event-badge-recurring"],
              property: "color",
            },
          ],
          tooltip: {
            message: "Color of the text characters themselves.",
            url: "https://help.display.church/en/articles/5844206-design-badges",
          },
          show: [
            {
              field: "recurringShowBadge",
              value: 1,
              defaultValue: 0,
            },
            {
              field: "recurringShowBadgeInDetails",
              value: 1,
              defaultValue: 0,
              or: true,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Recurring event label",
          templates: [CT.monthly],
          name: "recurringBadgeColor",
          defaultValue: "#ffffff",
          style: [
            {
              selector: [".dce--event-badge.dce--event-badge-recurring"],
              property: "color",
            },
          ],
          tooltip: {
            message: "Color of the text characters themselves.",
            url: "https://help.display.church/en/articles/5844206-design-badges",
          },
          show: [
            {
              field: "recurringShowBadgeInDetails",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Recurring icon color",
          templates: [CT.monthly],
          name: "fullCalRecurringBadgeColor",
          defaultValue: "#ffffff",
          style: [
            {
              selector: [".event-pill i.dce--event-badge-recurring"],
              property: "color",
            },
          ],
          tooltip: null,
          show: [
            {
              field: "recurringShowBadge",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Featured Events",
      options: [
        {
          title: "Show badge",
          templates: [
            CT.eventList,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.weekly,
            CT.monthly,
          ],
          name: "featuredShowBadge",
          applicable: "design",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/5844206-design-badges",
            message:
              "Display badge on image or near event title in calendar view.",
          },

          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show badge",
          templates: [
            CT.eventList,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.weekly,
            CT.monthly,
          ],
          applicable: "highlights",
          name: "featuredShowBadge",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/5844206-design-badges",
            message:
              "Display badge on image or near event title in calendar view.",
          },

          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show badge in event details",
          templates: [
            CT.eventList,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.weekly,
            CT.monthly,
          ],
          name: "featuredShowBadgeInDetails",
          applicable: "design",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/5844206-design-badges",
            message: "Display badge in pop-up or accordion details.",
          },

          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show badge in event details",
          templates: [
            CT.eventList,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.weekly,
            CT.monthly,
          ],
          name: "featuredShowBadgeInDetails",
          applicable: "highlights",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/5844206-design-badges",
            message: "Display badge in pop-up or accordion details.",
          },

          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Featured event background",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
          ],

          name: "featuredBadgeBgColor",
          defaultValue: "#2ADDA6",
          style: [
            {
              selector: [".dce--event-badge.dce--event-badge-featured"],
              property: "background-color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/5844206-design-badges",
            message: "Color of area behind the badge text.",
          },
          show: [
            {
              field: "featuredShowBadge",
              value: 1,
              defaultValue: 0,
            },
            {
              field: "featuredShowBadgeInDetails",
              value: 1,
              defaultValue: 0,
              or: true,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Featured event label",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
          ],
          name: "featuredBadgeColor",
          defaultValue: "#ffffff",
          style: [
            {
              selector: [".dce--event-badge.dce--event-badge-featured"],
              property: "color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/5844206-design-badges",
            message: "Color of the text characters themselves.",
          },
          show: [
            {
              field: "featuredShowBadge",
              value: 1,
              defaultValue: 0,
            },
            {
              field: "featuredShowBadgeInDetails",
              value: 1,
              defaultValue: 0,
              or: true,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Featured event background",
          templates: [CT.monthly],

          name: "featuredBadgeBgColor",
          defaultValue: "#2ADDA6",
          style: [
            {
              selector: [".dce--event-badge.dce--event-badge-featured"],
              property: "background-color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/5844206-design-badges",
            message: "Color of area behind the badge text.",
          },
          show: [
            {
              field: "featuredShowBadgeInDetails",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Featured event label",
          templates: [CT.monthly],
          name: "featuredBadgeColor",
          defaultValue: "#ffffff",
          style: [
            {
              selector: [".dce--event-badge.dce--event-badge-featured"],
              property: "color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/5844206-design-badges",
            message: "Color of the text characters themselves.",
          },
          show: [
            {
              field: "featuredShowBadgeInDetails",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Recurring icon color",
          templates: [CT.monthly],
          name: "fullCalFeaturedBadgeColor",
          defaultValue: "#ffffff",
          style: [
            {
              selector: [".event-pill i.dce--event-badge-featured"],
              property: "color",
            },
          ],
          tooltip: null,
          show: [
            {
              field: "featuredShowBadge",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
