/**
 * Created by piotr.pozniak@thebeaverhead.com on 03/06/2021.
 */
import FilterLabelCTA from "./CCBFilteringStrategy/FilterLabelCTA";
import CalendarIntegrationStrategy from "./CalendarIntegrationStrategy";
import PrivateEventsSyncWarning from "./CCBFilteringStrategy/PrivateEventsSyncWarning";
import { IT, PLANS } from "../../../consts";
import FeaturedFilterLabelCTA from "./CCBFilteringStrategy/FeaturedFilterLabelCTA";

class CCBStrategy extends CalendarIntegrationStrategy {
  type = IT.ccb;

  availableFilters = [
    "group",
    "campus",
    "department",
    "location",
    "event_organizer",
  ];

  filtersSettings = {
    group: {
      label: "Groups",
      tooltip: {
        url: "https://help.display.church/en/articles/4447435-setup-events-filtering-filter-selection",
        message: "Pull in events for these groups",
      },
      tooltipFeatured: {
        url: "https://help.display.church/en/articles/5815427-setup-design-featured-events",
        message: "Feature events for these groups",
      },
    },
    campus: {
      label: "Campuses",
      tooltip: {
        url: "https://help.display.church/en/articles/4447435-setup-events-filtering-filter-selection",
        message: "Pull in events for these campuses",
      },
      tooltipFeatured: {
        url: "https://help.display.church/en/articles/5815427-setup-design-featured-events",
        message: "Feature events for these campuses",
      },
    },
    location: {
      label: "Locations",
      tooltip: {
        url: "https://help.display.church/en/articles/4447435-setup-events-filtering-filter-selection",
        message: "Pull in events for these locations",
      },
      tooltipFeatured: {
        url: "https://help.display.church/en/articles/5815427-setup-design-featured-events",
        message: "Feature events for these locations",
      },
    },
    department: {
      label: "Departments",
      tooltip: {
        url: "https://help.display.church/en/articles/4447435-setup-events-filtering-filter-selection",
        message: "Pull in events for these departments",
      },
      tooltipFeatured: {
        url: "https://help.display.church/en/articles/5815427-setup-design-featured-events",
        message: "Feature events for these departments",
      },
    },
  };

  filterLabelCTA = FilterLabelCTA();
  featuredFilterLabelCTA = FeaturedFilterLabelCTA();
  filtersAllowedPlans = {
    group: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    campus: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    department: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    location: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    event_organizer: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
  };
  privateEventsSettingsWarning = PrivateEventsSyncWarning;

  featuredAllowedPlans = [PLANS.medium, PLANS.highest];

  /**
   *
   * @param data
   * @returns {{}}
   */
  filterRawDataToStateObject = (data) => {
    const filterObjects = this.availableFilters.reduce(
      (i, j) => ({ ...i, [j]: [] }),
      {}
    );

    data.map((filter) => {
      filter.value = filter.platform_metadata_id;
      filter.uuid = filter.platform_metadata_id;
      filter.label = this.filterRenderName(filter);
      filterObjects[filter.kind].push(filter);
    });

    return filterObjects;
  };

  /**
   *
   * @param data
   * @returns {*}
   */
  filterRawToDisplayObject = (data, showId) => {
    return data.map((filter) => {
      filter.value = filter.platform_metadata_id;
      filter.uuid = filter.platform_metadata_id;
      filter.label = this.filterRenderName(
        { ...filter.data, ...filter },
        showId
      );
      return filter;
    });
  };

  /**
   *
   * @param data
   * @param showInactive
   * @returns {*}
   */
  filterRenderName = (filter, showId = false) => {
    let name = filter.data.name;

    if (showId) {
      name +=
        (filter.platform_metadata_id
          ? " [" + filter.platform_metadata_id + "]"
          : "") +
        (filter.data.inactive && filter.data.inactive === "true"
          ? " (inactive)"
          : "");
    }

    return name;
  };

  privateEventsControl = {
    privateEventsTooltip: {
      message: "Show/hide private events on your calendar view.",
      url: "https://help.display.church/en/articles/4600729-setup-show-private-events-ccb",
    },
    toggleButtonLabel: "Show private events",
    allowedPlans: [PLANS.medium, PLANS.highest],
  };

  _filters = [
    {
      field: {
        value: "group",
        label: "Group",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    },
    {
      field: {
        value: "campus",
        label: "Campus",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    },
    {
      field: {
        value: "department",
        label: "Department",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    },
    {
      field: {
        value: "location",
        label: "Location",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    },
    {
      field: {
        value: "event_organizer",
        label: "Organizer",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    },
  ];

  canUserFilter = {
    group: true,
    campus: true,
    department: true,
    location: true,
  };
}

export default CCBStrategy;
