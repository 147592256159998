/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/02/2023.
 */
import {
  overwriteWidgetFilters,
  overwriteWidgetSettings,
  updateWidgetSettings,
} from "./widgetReducerTools";

const initialState = {
  model: null,

  fetch: false,
  fetchError: null,
  fetchSuccess: null,

  update: false,
  updateSuccess: false,
  updateError: null,

  delete: false,
  deleteSuccess: false,
  deleteError: null,

  show: true,
  lastClosed: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "ALERT_SET":
      if (!action.payload) {
        return { ...initialState };
      }
      if (action.payload.widget_settings) {
        return {
          ...initialState,
          model: {
            ...action.payload,
            alert_filters: null,
          },
        };
      }
      return {
        ...initialState,
        model: action.payload,
      };

    case "ALERT_FETCH_FULFILLED":
      try {
        return {
          ...state,
          fetch: false,
          fetchSuccess: true,
          model: {
            ...state.model,
            ...action.payload,
          },
        };
      } catch (e) {
        console.log("Group Fetch Fulfilled, an exception occurred: ", e);
      }

    case "ALERT_UPDATE":
      return {
        ...state,
        update: true,
        updateSuccess: false,
        updateError: null,
      };

    case "ALERT_UPDATE_FULFILLED":
      return {
        ...state,
        model: {
          ...action.payload,
        },
        update: false,
        updateSuccess: true,
      };

    case "ALERT_UPDATE_REJECTED":
      return {
        ...state,
        update: false,
        updateError: action.payload,
      };

    case "ALERT_DELETE":
      return {
        ...state,
        delete: true,
        deleteSuccess: false,
        deleteError: null,
      };

    case "ALERT_DELETE_FULFILLED":
      return {
        ...state,
        model: null,
        delete: false,
        deleteSuccess: true,
      };

    case "ALERT_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteError: action.payload,
      };

    case "WIDGET_DESIGN_PROP_UPDATE":
      if (!state.model) {
        break;
      }

      return updateWidgetSettings(state, action);

    case "WIDGET_DESIGN_PROP_OVERWRITE":
      if (!state.model) {
        break;
      }
      return overwriteWidgetSettings(state, action);

      case "WIDGET_FILTER_PROP_OVERWRITE":
      if (!state.model) {
        break;
      }
      return overwriteWidgetFilters(state, action);

    case "ALERT_SHOW":
      return {
        ...state,
        show: true,
        lastClosed: new Date(),
      };

    case "ALERT_HIDE":
      return {
        ...state,
        show: false,
        lastClosed: null,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        model: state.model ? { ...state.model } : null,
      };

    default:
      return state;
  }

  return state;
}
