/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/03/2023.
 */

import { AT, IT } from "../../../consts";
import {
  CTA_OPTIONS_BREEZE,
  CTA_OPTIONS_CCB,
  CTA_OPTIONS_GOOGLE,
  CTA_OPTIONS_PCO,
} from "./cta_options";

const FontSizesOptions = [
  {
    label: "Extra small",
    value: "12px",
  },
  {
    label: "Small",
    value: "14px",
  },
  {
    label: "Medium",
    value: "16px",
  },
  {
    label: "Large",
    value: "18px",
  },
  {
    label: "Extra large",
    value: "20px",
  },
];

export const definitions = {
  title: "Call to Action",
  icon: "call_to_action",
  groups: [
    {
      label: "Setup",
      options: [
        {
          title: "Show Call To Action button",
          templates: [AT.banner],
          name: "dcaShowCTA",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "Show/Hide CTA button.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Open links in a new tab",
          templates: [AT.banner],
          name: "dcaLinkTargetNewTab",
          defaultValue: 1,
          control: {
            kind: "checkbox",
            options: {},
          },
          show: [
            {
              field: "dcaShowCTA",
              value: 1,
              defaultValue: 1,
            },
          ],
        },
        {
          title: "CTA action",
          templates: [AT.banner],
          integrations: [IT.ccb],
          name: "dcaCTAAction",
          defaultValue: "linkFirst",
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "Choose what happens after viewer clicks your CTA button.",
          },
          show: [
            {
              field: "dcaShowCTA",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_OPTIONS_CCB,
            },
          },
        },
        {
          title: "CTA action",
          templates: [AT.banner],
          integrations: [IT.pco],
          name: "dcaCTAAction",
          defaultValue: "linkFirst",
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "Choose what happens after viewer clicks your CTA button.",
          },
          show: [
            {
              field: "dcaShowCTA",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_OPTIONS_PCO,
            },
          },
        },
        {
          title: "CTA action",
          templates: [AT.banner],
          integrations: [IT.google],
          name: "dcaCTAAction",
          defaultValue: "linkFirst",
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "Choose what happens after viewer clicks your CTA button.",
          },
          show: [
            {
              field: "dcaShowCTA",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_OPTIONS_GOOGLE,
            },
          },
        },
        {
          title: "CTA action",
          templates: [AT.banner],
          integrations: [IT.breeze],
          name: "dcaCTAAction",
          defaultValue: "linkFirst",
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "Choose what happens after viewer clicks your CTA button.",
          },
          show: [
            {
              field: "dcaShowCTA",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_OPTIONS_BREEZE,
            },
          },
        },
        {
          title: "Use default CTA button label",
          templates: [AT.banner],
          name: "dcaCTAActionDefaultLabel",
          defaultValue: 1,
          show: [
            {
              field: "dcaShowCTA",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "Use the default or not.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA button label",
          templates: [AT.banner],
          name: "dcaCTAActionLabel",
          defaultValue: "Learn more",
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "Type in the text line to change the default.",
          },
          show: [
            {
              field: "dcaCTAActionDefaultLabel",
              value: 0,
              defaultValue: 1,
            },
            {
              field: "dcaShowCTA",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "text",
            options: {
              placeholder: "CTA button label",
            },
          },
        },
      ],
    },
    {
      label: "Design",
      options: [
        {
          title: "Initial background color",
          templates: [AT.banner],
          name: "dcaCTABgColorInitial",
          defaultValue: "#DCB30E",
          style: {
            selector: [".dce--bar_cta-container_button_flash-effect"],
            property: ["--ribbon-background-color-opening"],
          },
          show: [
            {
              field: "dcaShowCTA",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message:
              "The color of the CTA button background when it first appears.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Background color",
          templates: [AT.banner],
          name: "dcaCTABgColor",
          defaultValue: "#DCB30E",
          style: {
            selector: [".dce--bar_cta-container_button_flash-effect"],
            property: ["--cta-button-bg-color"],
          },
          show: [
            {
              field: "dcaShowCTA",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message:
              "The color of the CTA button background after a second or two.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Background color on hover",
          templates: [AT.banner],
          name: "dcaCTABgColorHover",
          defaultValue: "#DCB30E",
          style: {
            selector: [".dce--bar_cta-container_button_flash-effect:hover"],
            property: "background-color",
          },
          show: [
            {
              field: "dcaShowCTA",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "The color of the CTA button background on hover.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Initial text color",
          templates: [AT.banner],
          name: "dcaCTAColorInitial",
          defaultValue: "#fff",
          style: {
            selector: [".dce--bar_cta-container_button_flash-effect"],
            property: "--cta-button-color-opening",
          },
          show: [
            {
              field: "dcaShowCTA",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "The color of the CTA button text when it first appears.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color",
          templates: [AT.banner],
          name: "dcaCTAColor",
          defaultValue: "#2C3648",
          style: {
            selector: [".dce--bar_cta-container_button_flash-effect"],
            property: "--cta-button-color",
          },
          show: [
            {
              field: "dcaShowCTA",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "The color of the CTA button text after a second or two.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
