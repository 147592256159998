/**
 * Created by piotr.pozniak@thebeaverhead.com on 10/11/2022.
 */

import { CT, IT } from "../../../consts";

export const definitions = {
  title: "Detailed list",
  icon: "dns",
  groups: [
    {
      label: "Event details",
      options: [
        {
          title: "Event date color",
          templates: [CT.detailedList],
          name: "dLEventDateColor",
          defaultValue: "#333",
          style: {
            selector: [".dce-detailed-list--row-content-details-date > span"],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "dLShowDate",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },

        {
          title: "Event repeat icon",
          templates: [CT.detailedList],
          name: "dLEventRepeatIconColor",
          defaultValue: "#334aff",
          style: {
            selector: [
              ".dce-detailed-list--row-content-details-date .dce-dl-event-repeat",
            ],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "dLShowRepeatIcon",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Event name",
          templates: [CT.detailedList],
          name: "dLEventNameColor",
          defaultValue: "#1a202c",
          style: {
            selector: [".dce-detailed-list--row-content-details-title h2"],
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },

        {
          title: "Event location color",
          templates: [CT.detailedList],
          name: "dLEventLocationColor",
          defaultValue: "#333",
          style: {
            selector: [".dce-detailed-list--row-content-details-location"],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "dLShowLocation",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Description color",
          templates: [CT.detailedList],
          integrations: [IT.ccb, IT.google, IT.breeze],
          name: "dLEventDescriptionColor",
          defaultValue: "#2d3748",
          style: {
            selector: [".dce-detailed-list--row-content-details-description p"],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "dLShowDescription",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Description color",
          templates: [CT.detailedList],
          integrations: [IT.pco],
          name: "dLEventDescriptionColor",
          defaultValue: "#2d3748",
          style: {
            selector: [".dce-detailed-list--row-content-details-description p"],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "dLShowSummary",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Listing",
      options: [
        {
          title: "Month color",
          templates: [CT.detailedList],
          name: "dLListMonthColor",
          defaultValue: "#1a202c",
          style: {
            selector: [".dce-detailed-list--divider-label"],
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Divider color",
          templates: [CT.detailedList],
          name: "dLListDividerColor",
          defaultValue: "#cbd5e0",
          style: {
            selector: [".dce-detailed-list--divider::after"],
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Week day color",
          templates: [CT.detailedList],
          name: "dLListWeekdayColor",
          defaultValue: "#2d3748",
          style: {
            selector: [".dce-detailed-list--row-date_month"],
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Day color",
          templates: [CT.detailedList],
          name: "dLListDayColor",
          defaultValue: "#1a202c",
          style: {
            selector: [".dce-detailed-list--row-date_day"],
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Featured events",
      options: [
        {
          title: "Indicator color",
          templates: [CT.detailedList],
          name: "dLFeaturedIndicatorColor",
          defaultValue: "#334aff",
          style: {
            selector: [
              ".dce-detailed-list--row-date.dce-detailed-list--row-date.dce-detailed-list--row-date_featured::after",
            ],
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Badge color",
          templates: [CT.detailedList],
          name: "dLFeaturedBadgeColor",
          defaultValue: "#334aff",
          style: {
            selector: [
              ".dce-detailed-list--row-content-details-date .dce-detailed-list--row-date-featured",
              ".dce-detailed-list--row-content-details-date .material-icons",
            ],
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "RSVP",
      options: [
        {
          title: "Link color",
          templates: [CT.detailedList],
          integrations: [IT.ccb],
          name: "cLRSVPLabelColor",
          defaultValue: "#1a202c",
          style: {
            selector: [".dce-detailed-list--row-content-details-rsvp"],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "rsvpShowForOpenForAll",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Link color on hover",
          templates: [CT.detailedList],
          integrations: [IT.ccb],
          name: "cLRSVPLabelColorHover",
          defaultValue: "#334aff",
          style: {
            selector: [".dce-detailed-list--row-content-details-rsvp:hover"],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "rsvpShowForOpenForAll",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Link underline",
          templates: [CT.detailedList],
          integrations: [IT.ccb],
          name: "cLRSVPLabelUnderline",
          defaultValue: "#334aff",
          style: {
            selector: [".dce-detailed-list--row-content-details-rsvp"],
            property: "border-bottom-color",
          },
          tooltip: null,
          show: [
            {
              field: "rsvpShowForOpenForAll",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Primary call to action",
      options: [
        {
          title: "Link color",
          templates: [CT.detailedList],
          integrations: [IT.ccb],
          name: "cLCTALabelColor",
          defaultValue: "#1a202c",
          style: {
            selector: [".dce-detailed-list--row-content-details-cta"],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "cDetailedShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Link color on hover",
          templates: [CT.detailedList],
          integrations: [IT.ccb],
          name: "cLCTALabelColorHover",
          defaultValue: "#334aff",
          style: {
            selector: [".dce-detailed-list--row-content-details-cta:hover"],
            property: "color",
          },
          tooltip: null,
          show: [
            {
              field: "cDetailedShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Link underline",
          templates: [CT.detailedList],
          integrations: [IT.ccb],
          name: "cLCTALabelUnderline",
          defaultValue: "#334aff",
          style: {
            selector: [".dce-detailed-list--row-content-details-cta"],
            property: "border-bottom-color",
          },
          tooltip: null,
          show: [
            {
              field: "cDetailedShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
