/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/07/2024.
 */

import { getStrategy } from "../apps/calendar/strategies/IntegrationContext";

/**
 * TODO
 * This is a tempoprary solution when pulling CCB groups filters
 * coming from user defined fields. We are interested in demographic
 * fields only but the API will return all UDF fields.
 * Here we filter out all UDF fields except the ones with label "Demographic".
 */

const IntegrationFiltersMiddleware = (storeAPI) => (next) => (action) => {
  switch (action.type) {
    case "INTEGRATION_FILTERS_FETCH_FULFILLED":
    case "WIDGET_FILTERS_FETCH_FULFILLED":
      const strategy = getStrategy(action.integrationType);
      action.payload = action.payload.filter((i) => {
        if (i.kind !== "udf") {
          return true;
        }

        if (i.data?.label?.toLowerCase()?.includes("demographic")) {
          return true;
        }

        return false;
      });

      // sort the payload filters
      action.payload.sort((a, b) => {
        const aLabel = strategy.filterRenderName(a) || "";
        const bLabel = strategy.filterRenderName(b) || "";

        return aLabel.localeCompare(bLabel);
      });

      break;
  }
  next(action);
};

export default IntegrationFiltersMiddleware;
