/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/06/2021.
 */

import { CT, IT } from "../../../consts";

export const definitions = {
  title: "Events",
  icon: "display_settings",
  groups: [
    {
      label: "Detailed list",
      options: [
        {
          title: "Show event date",
          templates: [CT.detailedList],
          name: "dLShowDate",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show recurring event icon",
          templates: [CT.detailedList],
          name: "dLShowRepeatIcon",
          defaultValue: 1,
          tooltip: null,

          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show location",
          templates: [CT.detailedList],
          name: "dLShowLocation",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show description",
          templates: [CT.detailedList],
          integrations: [IT.ccb, IT.google, IT.breeze],
          name: "dLShowDescription",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show summary",
          templates: [CT.detailedList],
          integrations: [IT.pco],
          name: "dLShowSummary",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Weekly View Events",
      options: [
        {
          title: "Display events as",
          templates: [CT.weekly],
          name: "wwEventsLocation",
          defaultValue: "events_list",
          tooltip: null,
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "Events List", value: "events_list" },
                { label: "Pills", value: "pills" },
                { label: "Stacks", value: "stack" },
              ],
              updateValue: (val) => {
                if (val[0].value == "events_list") {
                  val.push({
                    field: "generalOpenDetailsModal",
                    value: 0,
                  });
                } else {
                  val.push({
                    field: "generalExpandDetailAccording",
                    value: 0,
                  });
                }
                return val;
              },
            },
          },
        },
        {
          title: "Show events indication",
          templates: [CT.weekly],
          name: "wwShowEventsIndication",
          defaultValue: 1,
          tooltip: null,
          dependency: {
            field: "wwEventsLocation",
            value: "events_list",
            defaultValue: "events_list",
            message: "Only applicable when displaing event details in list",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Card views events",
      options: [
        {
          title: "Show image container",
          templates: [CT.cardList, CT.cardSlider, CT.card],
          name: "eventDetailsShowImageContainer",
          defaultValue: 1,
          tooltip: {
            message:
              "Show / Hide the image and all other details in this area.",
            url: "https://help.display.church/en/articles/9063894-design-events-show-image-container",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Date location",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          name: "cwDateLocation",
          defaultValue: "bottom-right",
          tooltip: {
            url: "https://help.display.church/en/articles/4900979-design-events-card-view-date-location",
            message:
              "Card list/grid views: Show/hide event date on image of card.",
          },
          show: [
            {
              field: "eventDetailsShowImageContainer",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "Display Date in top left corner", value: "top-left" },
                {
                  label: "Display Date in top right corner",
                  value: "top-right",
                },
                {
                  label: "Display Date in bottom right corner",
                  value: "bottom-right",
                },
                {
                  label: "Display Date in bottom left corner",
                  value: "bottom-left",
                },
                { label: "Don't display date", value: "none" },
              ],
            },
          },
        },
        {
          title: "Show event title in card",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          name: "ccShowTitle",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show date in card",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          name: "ccShowDate",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4901457-design-events-show-date-in-card",
            message:
              "Card list/grid views: Show/hide event date in details section of card.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "",
          templates: [CT.cardList, CT.card, CT.cardSlider],
          name: "ccShowTimeOptions",
          defaultValue: "regular",
          tooltip: null,
          show: [
            {
              field: "ccShowDate",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "Regular", value: "regular" },
                { label: "Compact", value: "compact" },
                { label: "Short", value: "short" },
              ],
            },
          },
        },
        {
          title: "Show location in card",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.ccb, IT.pco, IT.google, IT.breeze],
          name: "ccShowLocation",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4896407-design-events-show-location-in-card",
            message:
              "Card list/grid views: Show event locations in details section of cards.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show description in card",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.ccb, IT.google, IT.breeze],
          name: "ccShowDescription",
          applicable: "design",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/4901500-design-events-show-description-in-card",
            message:
              "Card list/grid views: Show/hide event description in the details section of the card.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show description in card",
          templates: [CT.card, CT.cardSlider],
          integrations: [IT.ccb, IT.google, IT.breeze],
          name: "ccShowDescription",
          applicable: "highlights",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/4901500-design-events-show-description-in-card",
            message:
              "Card list/grid views: Show/hide event description in the details section of the card.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show description in card",
          templates: [CT.cardList],
          integrations: [IT.ccb, IT.google, IT.breeze],
          name: "ccShowDescription",
          applicable: "highlights",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4901500-design-events-show-description-in-card",
            message:
              "Card list/grid views: Show/hide event description in the details section of the card.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show summary in card",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.pco],
          name: "ccShowSummary",
          defaultValue: 0,
          applicable: "design",
          tooltip: {
            url: "https://help.display.church/en/articles/4901500-design-events-show-description-in-card",
            message:
              "Card list/grid views: Show/hide event summary in the details section of the card.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show summary in card",
          templates: [CT.card, CT.cardSlider],
          integrations: [IT.pco],
          name: "ccShowSummary",
          defaultValue: 0,
          applicable: "highlights",
          tooltip: {
            url: "https://help.display.church/en/articles/4901500-design-events-show-description-in-card",
            message:
              "Card list/grid views: Show/hide event summary in the details section of the card.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show summary in card",
          templates: [CT.cardList],
          integrations: [IT.pco],
          name: "ccShowSummary",
          defaultValue: 1,
          applicable: "highlights",
          tooltip: {
            url: "https://help.display.church/en/articles/4901500-design-events-show-description-in-card",
            message:
              "Card list/grid views: Show/hide event summary in the details section of the card.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Full calendar behavior",
      options: [
        {
          title: "Hide events list",
          templates: [CT.monthly],
          name: "mcHideEventsList",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4572054-design-events-hide-events-list",
            message: "Full calendar: do not show the lists of events",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "On mouse hover, show only Number of Events for cell",
          templates: [CT.monthly],
          name: "mcShowEventsCounter",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/4571942-design-events-number-details-names",
            message: "Full calendar only: number of daily events (mouseover)",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "On mouse hover, show both Number and Event details for cell",
          templates: [CT.monthly],
          name: "mcShowEventsDetailedInfo",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4571942-design-events-number-details-names",
            message:
              "Full calendar only: number and names of daily events (mouseover)",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show Event names in cell",
          templates: [CT.monthly],
          name: "mcShowEventsNames",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4571942-design-events-number-details-names",
            message: "Full calendar: names of daily events",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Selecting day opens modal with events list",
          templates: [CT.monthly],
          name: "mcShowNamesShowsDetails",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4572014-design-events-events-list-modal",
            message: "Full calendar: pop up with event details",
          },
          /*          dependency: {
            name: "mcShowEventsNames",
            value: 1,
            defaultValue: 0,
            message:
              "This option requires 'Show Event names in cell' to be turned on.",
          },*/
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Event details",
      options: [
        {
          title: "Show timezone next to event time",
          templates: [
            CT.monthly,
            CT.eventList,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
          ],
          name: "generalShowTz",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/5555606-design-events-show-timezone-next-to-event-time",
            message:
              "Event times are shown in local time. Highlight this with the timezone.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Use tag color as accent color",
          templates: [
            CT.monthly,
            CT.eventList,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "generalUseEventAccent",
          integrations: [IT.pco],
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/5604046-design-events-use-tag-color-as-accent-color",
            message: "Use same color as PCO tag (if available).",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Use calendar color as accent color",
          templates: [
            CT.monthly,
            CT.eventList,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "generalUseEventAccent",
          integrations: [IT.google, IT.breeze],
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/5799302-design-events-use-calendar-color-as-accent-color-gcal",
            message:
              "Color of event listing left border; image space if no image.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Expand event details like an accordion",
          templates: [CT.monthly, CT.eventList],
          name: "generalExpandDetailAccording",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4572067-design-events-event-details-as-accordion-or-pop-up",
            message:
              "Event details in accordion format for calendars and events lists.",
          },
          checkDependency: [
            {
              field: "generalExpandDetailAccording",
              value: 1,
              defaultValue: 1,
            },
            { field: "generalOpenDetailsModal", value: 1, defaultValue: 0 },
          ],
          dependency: {
            field: "generalOpenDetailsModal",
            value: 0,
            defaultValue: 0,
            message: "Either accordion or modal is allowed",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        // this option is for weekly view only!
        {
          title: "Expand event details like an accordion",
          templates: [CT.weekly],
          name: "generalExpandDetailAccording",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4572067-design-events-event-details-as-accordion-or-pop-up",
            message:
              "Event details in accordion format for calendars and events lists.",
          },
          show: [
            {
              field: "wwEventsLocation",
              value: "events_list",
              defaultValue: "events_list",
            },
          ],
          checkDependency: [
            {
              field: "generalExpandDetailAccording",
              value: 1,
              defaultValue: 1,
            },
            { field: "generalOpenDetailsModal", value: 1, defaultValue: 0 },
          ],
          dependency: {
            field: "generalOpenDetailsModal",
            value: 0,
            defaultValue: 0,
            message: "Either accordion or modal is allowed",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Open event details as a pop-up",
          templates: [CT.eventList, CT.weekly, CT.monthly],
          name: "generalOpenDetailsModal",
          applicable: "design",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/4572067-design-events-event-details-as-accordion-or-pop-up",
            message:
              "Event details as a pop-up (modal) for calendars and events lists.",
          },
          checkDependency: [
            {
              field: "generalExpandDetailAccording",
              value: 1,
              defaultValue: 1,
            },
            { field: "generalOpenDetailsModal", value: 1, defaultValue: 0 },
          ],
          dependency: {
            field: "generalExpandDetailAccording",
            value: 0,
            defaultValue: 1,
            message: "Either accordion or modal is allowed",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Open event details as a pop-up",
          templates: [CT.cardList, CT.card, CT.cardSlider],
          name: "generalOpenDetailsModal",
          applicable: "design",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/4572067-design-events-event-details-as-accordion-or-pop-up",
            message:
              "Event details as a pop-up (modal) for calendars and events lists.",
          },
          checkDependency: [
            {
              field: "generalExpandDetailAccording",
              value: 1,
              defaultValue: 0,
            },
            { field: "generalOpenDetailsModal", value: 1, defaultValue: 0 },
          ],
          dependency: {
            field: "generalExpandDetailAccording",
            value: 0,
            defaultValue: 1,
            message: "Either accordion or modal is allowed",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Open event details as a pop-up",
          templates: [CT.detailedList, CT.bubble],
          name: "generalOpenDetailsModal",
          applicable: "design",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4572067-design-events-event-details-as-accordion-or-pop-up",
            message:
              "Event details as a pop-up (modal) for calendars and events lists.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Open event details as a pop-up",
          templates: [CT.card, CT.cardList, CT.cardSlider, CT.bubble],
          name: "generalOpenDetailsModal",
          applicable: "highlights",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4572067-design-events-event-details-as-accordion-or-pop-up",
            message:
              "Event details as a pop-up (modal) for calendars and events lists.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },

        {
          title: "Show summary header on pop-up",
          templates: [
            CT.monthly,
            CT.eventList,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "generalShowEventSummary",
          defaultValue: 1,
          show: [
            {
              field: "generalOpenDetailsModal",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/5788056-design-events-show-summary-on-pop-up",
            message: "Header of event name, date, time, & location.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "On click, go to straight to Church Center",
          templates: [
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.eventList,
            CT.weekly,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.pco],
          name: "generalGoToChurchCenter",
          defaultValue: 0,
          tooltip: null,
          checkDependency: [
            {
              field: "generalExpandDetailAccording",
              value: 0,
              defaultValue: 0,
            },
            { field: "generalOpenDetailsModal", value: 0, defaultValue: 0 },
          ],
          dependency:
            /*{
              field: "generalExpandDetailAccording",
              value: 1,
              defaultValue: 1,
              message: "This option will override accordion option.",
            },*/
            {
              field: "generalOpenDetailsModal",
              value: 1,
              defaultValue: 1,
              message: "This option will override modal option.",
            },

          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show event description",
          templates: [
            CT.monthly,
            CT.eventList,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "generalShowDescription",
          defaultValue: 1,
          show: [
            {
              field: "generalExpandDetailAccording",
              value: 1,
              defaultValue: 1,
            },
            {
              field: "generalOpenDetailsModal",
              value: 1,
              defaultValue: 0,
              or: true,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6065215-design-events-show-event-description",
            message: "Show/hide event explanation.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show summary text above event description",
          integrations: [IT.pco],
          templates: [
            CT.monthly,
            CT.eventList,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "generalShowShortSummary",
          defaultValue: 0,
          show: [
            {
              field: "generalExpandDetailAccording",
              value: 1,
              defaultValue: 1,
            },
            {
              field: "generalOpenDetailsModal",
              value: 1,
              defaultValue: 0,
              or: true,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6065261-design-events-show-summary-text-above-event-description",
            message: "Show info from PCO Summary field.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Underline description links",
          templates: [
            CT.monthly,
            CT.eventList,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "generalUnderlineLinks",
          defaultValue: 1,
          show: [
            {
              field: "generalExpandDetailAccording",
              value: 1,
              defaultValue: 1,
            },
            {
              field: "generalOpenDetailsModal",
              value: 1,
              defaultValue: 0,
              or: true,
            },
          ],
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show event location in header",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "eLShowLocation",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show event location",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.ccb, IT.pco, IT.google, IT.breeze],
          name: "eventDetailsShowLocation",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4667095-design-events-show-event-location",
            message:
              "Display event location for all calendars and event lists.",
          },
          show: [
            {
              field: "generalExpandDetailAccording",
              value: 1,
              defaultValue: 1,
            },
            {
              field: "generalOpenDetailsModal",
              value: 1,
              defaultValue: 0,
              or: true,
            },
          ],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Link location to Google Maps",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/4667095-design-events-show-event-location-link-location-to-google-maps",
            message: "Display clickable link to Google map.",
          },
          name: "eventDetailsLinkLocation",
          defaultValue: 1,
          show: [
            {
              field: "generalExpandDetailAccording",
              value: 1,
              defaultValue: 1,
            },
            {
              field: "generalOpenDetailsModal",
              value: 1,
              defaultValue: 0,
              or: true,
            },
          ],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: (
            <span>
              Show Location's Google Maps photos if available{" "}
              <span className={"badge badge-success"}>NEW</span>
            </span>
          ),
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.google],
          tooltip: null,
          name: "cPopupShowPlace",
          defaultValue: 1,
          show: [
            {
              field: "generalExpandDetailAccording",
              value: 1,
              defaultValue: 1,
            },
            {
              field: "generalOpenDetailsModal",
              value: 1,
              defaultValue: 0,
              or: true,
            },
          ],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show event time",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "eventDetailsShowTime",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/5788093-design-events-show-event-time",
            message: "If showing event time, choose the format.",
          },
          show: [
            {
              field: "generalExpandDetailAccording",
              value: 1,
              defaultValue: 1,
            },
            {
              field: "generalOpenDetailsModal",
              value: 1,
              defaultValue: 0,
              or: true,
            },
          ],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "eventDetailsShowTimeOptions",
          defaultValue: "regular",
          tooltip: null,
          show: [
            {
              field: "eventDetailsShowTime",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "Regular", value: "regular" },
                { label: "Compact", value: "compact" },
                { label: "Short", value: "short" },
              ],
            },
          },
        },
        {
          title: "Show event schedule",
          integrations: [IT.pco],
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "eventDetailsShowSchedule",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/5904140-design-events-show-event-schedule",
            message: "Show/hide all the times of a single event.",
          },
          show: [
            {
              field: "generalExpandDetailAccording",
              value: 1,
              defaultValue: 1,
            },
            {
              field: "generalOpenDetailsModal",
              value: 1,
              defaultValue: 0,
              or: true,
            },
          ],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show image when available",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "eventDetailsShowImage",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4628925-design-events-show-image-when-available",
            message: "Display event image for all calendars and event lists.",
          },
          show: [
            {
              field: "generalExpandDetailAccording",
              value: 1,
              defaultValue: 1,
            },
            {
              field: "generalOpenDetailsModal",
              value: 1,
              defaultValue: 0,
              or: true,
            },
          ],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Image aspect ratio",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "eventDetailsImageAspectRatio",
          defaultValue: "169",
          tooltip: {
            url: "https://help.display.church/en/articles/5393644-design-events-image-aspect-ratio-image-object-fit",
            message: "Adjust length and height of image",
          },
          show: [
            {
              field: "eventDetailsShowImage",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                {
                  label: "EventOn Original (40 viewport height)",
                  value: "eventon",
                },
                { label: "1:1", value: "11" },
                { label: "3:2", value: "32" },
                { label: "4:3", value: "43" },
                { label: "16:9", value: "169" },
              ],
            },
          },
        },
        {
          title: "Image object fit",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "eventDetailsImageObjectFit",
          defaultValue: "cover",
          tooltip: {
            url: "https://help.display.church/en/articles/5393644-design-events-image-aspect-ratio-image-object-fit",
            message: "Choose how image fills the given space",
          },
          style: [
            {
              selector: ".event-row--image-container .event-row--image",
              property: "object-fit",
            },
          ],
          show: [
            {
              field: "eventDetailsShowImageContainer",
              value: 1,
              defaultValue: 1,
            },
            {
              field: "eventDetailsShowImage",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "Cover", value: "cover" },
                { label: "Contain", value: "contain" },
                { label: "Fill", value: "fill" },
              ],
            },
          },
        },
        {
          title: "Show organizer",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.ccb, IT.breeze],
          name: "eventDetailsShowOrganizer",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4572120-design-events-show-organizer-show-organizer-name-as-link",
            message:
              "Display organizer’s name for all calendars and event lists.",
          },
          show: [
            {
              field: "generalExpandDetailAccording",
              value: 1,
              defaultValue: 1,
            },
            {
              field: "generalOpenDetailsModal",
              value: 1,
              defaultValue: 0,
              or: true,
            },
          ],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show organizer name as link",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.ccb, IT.breeze],
          name: "eventDetailsShowOrganizerEmail",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/4572120-design-events-show-organizer-name-show-organizer-name-as-link",
            message:
              "Add URL to organizer’s name for all calendars and event lists.",
          },
          show: [
            {
              field: "generalExpandDetailAccording",
              value: 1,
              defaultValue: 1,
            },
            {
              field: "generalOpenDetailsModal",
              value: 1,
              defaultValue: 0,
              or: true,
            },
          ],
          dependency: {
            field: "eventDetailsShowOrganizer",
            value: 1,
            defaultValue: 1,
            message:
              "This option is only applicable if you Show Organizer name.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show share calendar button",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "eventDetailsShowShareOptions",
          defaultValue: 1,
          tooltip: null,
          show: [
            {
              field: "generalExpandDetailAccording",
              value: 1,
              defaultValue: 1,
            },
            {
              field: "generalOpenDetailsModal",
              value: 1,
              defaultValue: 0,
              or: true,
            },
          ],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show subscribe calendar button",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "eventDetailsShowAddToCalendar",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4616084-design-events-show-add-to-calendar",
            message:
              "For all calendars and event lists: links so viewer can add to personal/app calendars.",
          },
          show: [
            {
              field: "generalExpandDetailAccording",
              value: 1,
              defaultValue: 1,
            },
            {
              field: "generalOpenDetailsModal",
              value: 1,
              defaultValue: 0,
              or: true,
            },
          ],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show recurring events times",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "eventDetailsShowRepeatingEventsTimes",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6328049-design-events-event-details-recurring-events-times-series",
            message: "Show/hide frequency and hours of the recurring event.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show recurrence series",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          integrations: [IT.ccb, IT.pco, IT.google, IT.breeze],
          name: "eventDetailsShowRecurringSeries",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/6328049-design-events-event-details-recurring-events-times-series",
            message:
              "Show/hide schedule of day, date, hours of recurring event.",
          },
          show: [
            {
              field: "eventDetailsShowRepeatingEventsTimes",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Details enhancement",
      options: [
        {
          title: (
            <span>
              Turn video links into preview{" "}
              <span className={"badge badge-success"}>NEW</span>
            </span>
          ),
          templates: [
            CT.monthly,
            CT.eventList,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "turnVideoLinkIntoPreview",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/9286370-design-events-details-enhancements-turn-video-links-into-preview",
            message:
              "Turn video links into a preview image for all calendars and event lists.",
          },
          show: [
            {
              field: "generalShowDescription",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Event list/card/grid details",
      options: [
        {
          title: "Show day of the week",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
          ],
          name: "eventDetailsShowDayOfWeek",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/4925816-design-events-show-day-of-the-week-in-event-list-grid",
            message:
              "All views except weekly: Show/hide weekday name in event list/grid.",
          },

          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Day of the week length",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
          ],
          name: "eventsShowDayWeekOption",
          defaultValue: "ddd",
          tooltip: null,
          show: [
            {
              field: "eventDetailsShowDayOfWeek",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "Narrow (eg. S, M,...)", value: "dd" },
                { label: "Short (eg. Sun, Mon, ...)", value: "ddd" },
                {
                  label: "Full (eg. Sunday, Monday, ...)",
                  value: "dddd",
                },
              ],
            },
          },
        },
      ],
    },
    {
      label: "Number of events",
      options: [
        {
          title: "Infinite scroll",
          templates: [
            CT.eventList,
            CT.cardSlider,
            CT.detailedList,
            CT.card,
            CT.cardList,
          ],
          applicable: "design",
          name: "infiniteScroll",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/4616123-design-events-infinite-scroll",
            message:
              "For event lists only: just the month’s events or all events.",
          },

          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Infinite scroll parent selector",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
          ],
          applicable: "design",
          name: "infiniteScrollParentSelector",
          defaultValue: "",
          tooltip: null,
          show: [
            {
              field: "infiniteScroll",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "text",
            options: {
              placeholder: "Optional selector",
            },
          },
        },
        {
          title: "Limit displayed events",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.card,
            CT.weekly,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "eventsNumber",
          defaultValue: "all",
          applicable: "design",
          tooltip: {
            url: "https://help.display.church/en/articles/4593620-design-events-number-of-events",
            message:
              "Filter events per timeline or limit # displayed for full calendars and events lists.",
          },
          show: [
            {
              field: "infiniteScroll",
              value: 0,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "radio",
            options: {
              radioOptions: [
                {
                  title: "Show all events for selected month",
                  value: "all",
                  template: [
                    CT.monthly,
                    CT.eventList,
                    CT.weekly,
                    CT.detailedList,
                    CT.card,
                    CT.cardList,
                    CT.bubble,
                  ],
                },
                {
                  title: "Show only upcoming events",
                  value: "upcoming",
                  template: [
                    CT.card,
                    CT.cardList,
                    CT.cardSlider,
                    CT.monthly,
                    CT.eventList,
                    CT.weekly,
                    CT.detailedList,
                    CT.bubble,
                  ],
                },
                {
                  title: "Show",
                  value: "upcoming-limit",
                  template: [
                    CT.eventList,
                    CT.card,
                    CT.cardList,
                    CT.cardSlider,
                    CT.detailedList,
                    CT.bubble,
                  ],
                  input: {
                    defaultValue: 3,
                    postLabel: "upcoming events",
                    type: "number",
                  },
                },
                {
                  title: "Show current week events only",
                  value: "current_week",
                  template: [
                    CT.card,
                    CT.cardList,
                    CT.cardSlider,
                    CT.detailedList,
                  ],
                },
                {
                  title: "Show only events within this time range",
                  value: "date_range",
                  template: [CT.weekly],
                  input: {
                    type: "daterange",
                    defaultValue: "",
                  },
                },
              ],
            },
          },
        },
        {
          title: "Show event page scroll",
          templates: [
            CT.eventList,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
          ],
          name: "showUpcomingNavigation",
          defaultValue: 0,
          tooltip: null,
          applicable: "design",
          /*dependency: {
            name: "eventsNumberOption",
            value: "upcoming",
            defaultValue: "upcoming-limit",
            message:
              'This option requires "Show upcoming events" behavior to be turned on',
          },*/
          show: [
            {
              field: "infiniteScroll",
              value: 0,
              defaultValue: 0,
            },
            {
              field: "eventsNumberOption",
              value: "upcoming-limit",
              defaultValue: "upcoming",
            },
          ],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
  ],
};
