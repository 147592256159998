/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/01/2021.
 */

import { RRule } from "rrule";
import { CT } from "../consts";
import {
  firstDayOfWeek,
  formatDate,
  getEndOfMonth,
  getEndOfWeek,
  getEventsDateRange,
} from "./date";
import moment from "moment/moment";

/**
 * Checks whether current calendar is in allowed calendars.
 * @param current
 * @param allowed
 * @returns {boolean}
 */
export const isCTAllowed = (current, allowed) => {
  return allowed.indexOf(current) >= 0;
};

const tzOffset = new Date().getTimezoneOffset();

export const toRRuleInput = (date) => {
  date.setMinutes(date.getMinutes() - tzOffset);
  return date;
};

export const fromRRuleOutput = (date) => {
  const dstOffset = date.getTimezoneOffset() - tzOffset;
  date.setMinutes(date.getMinutes() + tzOffset + dstOffset);
  return date;
};

/**
 *
 * @param rule
 * @returns {null|RRule}
 */
export const cleanRRule = (options) => {
  const initialRule = new RRule({
    ...options,
    dtstart: toRRuleInput(options.dtstart),
  });

  if (initialRule.options.until || initialRule.options.count) {
    if (initialRule.count() <= 1) {
      return null;
    }
  }

  // bug in RRULE https://github.com/jakubroztocil/rrule/issues/556#issuecomment-1264889494
  const nextOccurrence = fromRRuleOutput(initialRule.after(options.dtstart));

  if (options.until && nextOccurrence < options.until) {
    // TODO: this is a workaround for the bug in RRULE library where it will not show events happening
    // on the same day as until date, e.g. Rule is "until 17 Jan" and event is on 17 Jan, it will not show
    options.until.setHours(23, 59, 59, 999);
  }

  const cleanOptions = {
    ...options,
    dtstart: toRRuleInput(nextOccurrence),
  };

  return new RRule(cleanOptions);
};

/**
 *
 * @param url
 * @param newTab
 */
export const openEventPage = (event, newTab) => {
  if (event.ctas.eventPage.url) {
    window.open(event.ctas.eventPage.url, newTab ? event.slug : "_self");
  }
};

export const isValidEmail = (input) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);

/**
 * Get date range from option and value. Use this to translated the Number of events "show only events within this time range"
 * into a date range.
 * @param option
 * @param value
 * @returns {[Date,Date]|[null,null]}
 */
export const getDateRange = (option, value) => {
  if (option == "date_range") {
    if (value && value.indexOf(":") > -1) {
      const [start, end] = value.split(":");
      const startDate = new Date(start);
      const endDate = new Date(end);

      return [startDate, endDate];
    }
  }

  return [null, null];
};

/**
 * Sorts events with all-day events first, then by start_time
 * @param a
 * @param b
 * @returns {number}
 */
export const sortEventsWithAllDayFirst = (a, b) => {
  if (a.allDay && !b.allDay) {
    return -1; // eventA comes first
  } else if (!a.allDay && b.allDay) {
    return 1; // eventB comes first
  } else {
    // Both are either all-day events or neither are, so sort by start_time
    return a.start_time - b.start_time;
  }
};

/**
 *
 * @param filters
 * @param stateSelectedDate
 * @param eventsNumberOption
 * @param eventsNumberCount
 * @param calendarTemplate
 * @param currentPage
 * @returns {{endDate: Date, limit: null, page: null, filters, startDate: Date}|{endDate: string, limit: null, page: null, filters, startDate: string}|{endDate: null, limit, page: null, filters, startDate: string}|{endDate: null, limit: null, page: null, filters, startDate: null}|boolean}
 */
export const getFetchEventsParams = (
  filters,
  stateSelectedDate,
  eventsNumberOption,
  eventsNumberCount,
  calendarTemplate,
  currentPage = 1
) => {
  if (
    eventsNumberOption !== "date_range" &&
    (isCTAllowed(calendarTemplate, [CT.weekly]) ||
      eventsNumberOption === "current_week")
  ) {
    let startDate = firstDayOfWeek(new Date(stateSelectedDate));

    if (eventsNumberOption === "upcoming") {
      startDate = stateSelectedDate < new Date() ? new Date() : startDate;
    }

    // start date toISOString will return time in UTC, we need a local time
    const localStartDate = new Date(
      startDate.getTime() - startDate.getTimezoneOffset() * 60000
    );

    return {
      startDate: localStartDate.toISOString().split("T")[0],
      endDate: new Date(
        new Date(localStartDate).getTime() + 6 * 24 * 60 * 60 * 1000
      )
        .toISOString()
        .split("T")[0],
      limit: undefined,
      page: undefined,
      filters,
    };
  }

  if (eventsNumberOption === "date_range") {
    if (!eventsNumberCount || eventsNumberCount.indexOf(":") === -1) {
      return {
        startDate: undefined,
        endDate: undefined,
        limit: undefined,
        page: undefined,
        filters,
      };
    }
    const [rangeStartDateStr, rangeEndDateStr] = eventsNumberCount.split(":");

    const startDate =
      calendarTemplate === CT.weekly
        ? firstDayOfWeek(new Date(stateSelectedDate))
        : new Date(stateSelectedDate);

    const endDate =
      calendarTemplate === CT.weekly
        ? getEndOfWeek(startDate)
        : getEndOfMonth(startDate);

    const type = calendarTemplate === CT.weekly ? "week" : "month";

    const rangeStartDate = new Date(rangeStartDateStr);
    const rangeEndDate = new Date(rangeEndDateStr);

    if (startDate > rangeEndDate || endDate < rangeStartDate) {
      return false;
    }

    const [adjustedStartDate, adjustedEndDate] = getEventsDateRange(
      startDate,
      endDate,
      rangeStartDate,
      rangeEndDate,
      type
    );

    return {
      startDate: adjustedStartDate.toISOString().split("T")[0],
      endDate: adjustedEndDate.toISOString().split("T")[0],
      limit: undefined,
      page: undefined,
      filters,
    };
  }

  if (eventsNumberOption === "upcoming-limit") {
    return {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: undefined,
      limit: eventsNumberCount,
      page: currentPage,
      filters,
    };
  }

  if (eventsNumberOption === "all") {
    return {
      startDate: moment(stateSelectedDate)
        .startOf("month")
        .format("YYYY-MM-DD"),
      endDate: moment(stateSelectedDate).endOf("month").format("YYYY-MM-DD"),
      limit: undefined,
      page: undefined,
      filters,
    };
  }

  if (eventsNumberOption === "upcoming") {
    const selectedDateMoment = moment(stateSelectedDate);

    let startDate = selectedDateMoment.isBefore(moment())
      ? moment().format("YYYY-MM-DD")
      : moment(stateSelectedDate).format("YYYY-MM-DD");

    const endDate = moment(stateSelectedDate)
      .endOf("month")
      .format("YYYY-MM-DD");

    return {
      startDate,
      endDate,
      limit: undefined,
      page: undefined,
      filters,
    };
  }
};

/**
 * Get selected date based on eventsNumberOption and eventsNumberCount
 * @param eventsNumberOption
 * @param eventsNumberCount
 * @returns {moment.Moment}
 */
export const getSelectedDate = (eventsNumberOption, eventsNumberCount) => {
  if (eventsNumberOption === "date_range") {
    if (!eventsNumberCount || eventsNumberCount.indexOf(":") === -1) {
      return moment();
    }
    const [startDate] = eventsNumberCount.split(":");
    return moment(startDate);
  }

  return moment();
};
