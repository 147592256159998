/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/07/2022.
 */

import { CT, IT } from "../../../consts";

export const definitions = {
  title: "Featured highlights",
  icon: "featured_video",
  groups: [
    {
      label: "Callout",
      options: [
        {
          title: "Label",
          templates: [CT.card, CT.cardList, CT.cardSlider, CT.bubble],
          name: "cFeaturedLabel",
          applicable: "highlights",
          defaultValue: "FEATURED EVENTS",
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Label color",
          templates: [CT.card, CT.cardList, CT.cardSlider, CT.bubble],
          name: "cFeaturedLabelColor",
          applicable: "highlights",
          defaultValue: "#C4C4C4",
          tooltip: null,
          style: {
            selector: [
              // need to replace _highlights that's added as postfix,
              // this element is one div higher in the hierarchy
              // so we need to access it by going using original widget selector
              (widgetSelector) =>
                ".dce_calendar__" +
                widgetSelector.replace("._highlights", "") +
                " .dc-highlights .dce__h1",
            ],
            property: "color",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
