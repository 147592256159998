/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/10/2023.
 */

class ItemsStrategy {
  model = null;

  processData(data) {}

  setWidgetModel(model) {
    this.model = model;
  }
}

export default ItemsStrategy;
