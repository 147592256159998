/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/06/2021.
 */

import { CT, GT } from "../../../consts";
const FontSizesOptions = [
  {
    label: "Small",
    value: "14px",
  },
  {
    label: "Medium",
    value: "16px",
  },
  {
    label: "Large",
    value: "18px",
  },
  {
    label: "Extra large",
    value: "22px",
  },
];
const FontSizesOptionsCards = [
  {
    label: "Small",
    value: "12px",
  },
  {
    label: "Medium",
    value: "14px",
  },
  {
    label: "Large",
    value: "16px",
  },
  {
    label: "Extra large",
    value: "18px",
  },
];

export const definitions = {
  title: "Fonts",
  icon: "format_size",
  groups: [
    {
      label: "General",
      options: [
        {
          title: "Font family",
          templates: [
            CT.cardList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.eventList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "fontFamily",
          defaultValue: "Roboto",
          style: {
            selector: (uuid) => `.calendar.dce_calendar__${uuid}`,
            property: "font-family",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4495714-design-fonts-font-family",
            message: "Select the font for calendars and events lists.",
          },
          control: {
            kind: "fontFamily",
            options: {
              placeholder: "Search Google Fonts",
              inherit: true,
            },
          },
        },
      ],
    },
    {
      label: "Sizes",
      options: [
        {
          title: "Responsive fonts",
          templates: [
            CT.cardList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.eventList,
            CT.cardSlider,
          ],
          name: "cResponsiveFonts",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
          dependency: {
            field: "cResponsiveFonts",
            value: 0,
            defaultValue: 1,
            message:
              "To fully enable the responsive feature, please: (1) Click Save to confirm your action. (2) Refresh the page. (3) Come back here [Design - Fonts - Responsive fonts}. Now, moving the slider button will show an accurate view.",
          },
          version: {
            created: "before",
            value: 1662989037,
          },
        },
        {
          title: "Size",
          templates: [CT.eventList, CT.weekly, CT.detailedList, CT.bubble],
          name: "cFontSizeDesktop",
          defaultValue: "16px",
          style: {
            selector: [(uuid) => `.dce_calendar__${uuid}`],
            property: ["font-size"],
          },
          show: [
            {
              field: "cResponsiveFonts",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "range",
            options: FontSizesOptions,
          },
        },
        {
          title: "Size",
          templates: [CT.monthly],
          name: "cFontSizeDesktop",
          defaultValue: "18px",
          style: {
            selector: [(uuid) => `.dce_calendar__${uuid}`],
            property: ["font-size"],
          },
          show: [
            {
              field: "cResponsiveFonts",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "range",
            options: FontSizesOptions,
          },
        },
        {
          title: "Responsive",
          templates: [CT.cardList, CT.card, CT.cardSlider],
          name: "cFontSizeDesktop",
          defaultValue: "14px",
          style: {
            selector: [(uuid) => `.dce_calendar__${uuid}`],
            property: ["font-size"],
          },
          show: [
            {
              field: "cResponsiveFonts",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "range",
            options: FontSizesOptionsCards,
          },
        },
        {
          title: "Responsive Popup",
          templates: [
            CT.cardList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.eventList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "cFontSizeDesktopPopup",
          defaultValue: "16px",
          style: {
            selector: [
              (uuid) => `.event-modal--wrapper.dce_calendar__${uuid}`,
              (uuid) => `.events-list-modal--wrapper.dce_calendar__${uuid}`,
            ],
            property: ["font-size"],
          },
          show: [
            {
              field: "cResponsiveFonts",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "range",
            options: FontSizesOptions,
          },
        },
        {
          title: "Month/Year",
          templates: [CT.eventList, CT.monthly],
          name: "currentDateFontSize",
          defaultValue: "36px",
          style: {
            selector: [".month-selector .h1"],
            property: ["font-size", "line-height" /*"height"*/],
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4495810-design-fonts-month-year-size",
            message:
              "Size of the month and year font for calendars and events lists.",
          },
          show: [
            {
              field: "cResponsiveFonts",
              value: 0,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "fontSize",
            options: {
              placeholder: "Font Size",
              inherit: true,
            },
          },
          version: {
            created: "before",
            value: 1662989037,
          },
        },
        {
          title: "Event title size",
          templates: [
            CT.cardList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.eventList,
            CT.cardSlider,
            CT.bubble,
          ],
          name: "eventTitleFontSize",
          defaultValue: "22px",
          style: {
            selector: [".events-list--event-description h2"],
            property: ["font-size", "line-height" /*"height"*/],
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4498986-design-fonts-event-title-size",
            message:
              "Size of the event title font for calendars and events lists.",
          },
          show: [
            {
              field: "cResponsiveFonts",
              value: 0,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "fontSize",
            options: {
              placeholder: "Font Size",
              inherit: true,
            },
          },
          version: {
            created: "before",
            value: 1662989037,
          },
        },
        {
          title: "Event description size",
          templates: [
            CT.cardList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.eventList,
            CT.cardSlider,
            CT.bubble,
          ],
          name: "eventDescriptionFontSize",
          defaultValue: "11px",
          style: {
            selector: [".events-list--event-description p"],
            property: ["font-size", "line-height" /*"height"*/],
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4499040-design-fonts-event-description-size",
            message:
              "Size of the event description font for calendars and events lists",
          },
          show: [
            {
              field: "cResponsiveFonts",
              value: 0,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "fontSize",
            options: {
              placeholder: "Font Size",
              inherit: true,
            },
          },
          version: {
            created: "before",
            value: 1662989037,
          },
        },
        {
          title: "Event details size",
          templates: [
            CT.cardList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.eventList,
            CT.cardSlider,
            CT.bubble,
          ],
          name: "eventDetailsFontSize",
          defaultValue: "13px",
          style: {
            selector: [".event-row--details .p"],
            property: ["font-size", "line-height" /*"height"*/],
          },
          show: [
            {
              field: "cResponsiveFonts",
              value: 0,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/5221953-design-fonts-event-details-size",
            message: "Increase / decrease font size of event details.",
          },
          control: {
            kind: "fontSize",
            options: {
              placeholder: "Font Size",
              inherit: true,
            },
          },
          version: {
            created: "before",
            value: 1662989037,
          },
        },
      ],
    },
    {
      label: "Filters",
      options: [
        {
          title: "Font family",
          templates: [
            CT.cardList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.eventList,
            CT.cardSlider,
          ],
          name: "fontFamilyFilters",
          defaultValue: "Open Sans",
          style: {
            selector: (uuid) => ".calendar .calendar-filters",
            property: "font-family",
          },
          /*tooltip: {
            url:
              "https://help.display.church/en/articles/4495714-design-fonts-font-family",
            message: "Select the font for calendars and events lists.",
          },*/
          control: {
            kind: "fontFamily",
            options: {
              placeholder: "Search Google Fonts",
              inherit: true,
            },
          },
        },
      ],
    },
  ],
};
