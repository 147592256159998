/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/06/2021.
 */

import { CT, IT } from "../../../consts";

const filterConditionsForNonCardTemplates = [
  {
    field: "showgroupDropdown",
    value: 1,
    defaultValue: 1,
  },
  {
    field: "showcampusDropdown",
    value: 1,
    defaultValue: 1,
    or: true,
  },
  {
    field: "showlocationDropdown",
    value: 1,
    defaultValue: 1,
    or: true,
  },
  {
    field: "showdepartmentDropdown",
    value: 1,
    defaultValue: 1,
    or: true,
  },
];

const filterConditionsForCardTemplates = [
  {
    field: "showgroupDropdown",
    value: 1,
    defaultValue: 0,
  },
  {
    field: "showcampusDropdown",
    value: 1,
    defaultValue: 0,
    or: true,
  },
  {
    field: "showlocationDropdown",
    value: 1,
    defaultValue: 0,
    or: true,
  },
  {
    field: "showdepartmentDropdown",
    value: 1,
    defaultValue: 0,
    or: true,
  },
  {
    field: "showcalendarDropdown",
    value: 1,
    defaultValue: 0,
    or: true,
  },
  {
    field: "showtagDropdown",
    value: 1,
    defaultValue: 0,
    or: true,
  },
  {
    field: "showplaceDropdown",
    value: 1,
    defaultValue: 0,
    or: true,
  },
];

const filterConditionsForGoogleTemplates = [
  {
    field: "showcalendarDropdown",
    value: 1,
    defaultValue: 1,
  },
  {
    field: "showplaceDropdown",
    value: 1,
    defaultValue: 1,
    or: true,
  },
  {
    field: "showtagDropdown",
    value: 1,
    defaultValue: 1,
    or: true,
  },
];

const filterConditionsForBreezeTemplates = [
  {
    field: "showtagDropdown",
    value: 1,
    defaultValue: 1,
  },
  {
    field: "showcalendarDropdown",
    value: 1,
    defaultValue: 1,
    or: true,
  },
  {
    field: "showlocationDropdown",
    value: 1,
    defaultValue: 1,
    or: true,
  },
];

const filterConditionsForPcoTemplates = [
  {
    field: "showtagDropdown",
    value: 1,
    defaultValue: 1,
  },
  {
    field: "showgroupDropdown",
    value: 1,
    defaultValue: 1,
    or: true,
  },
];

export const definitions = {
  title: "Headers and links",
  icon: "title",
  groups: [
    {
      label: "Header",
      options: [
        {
          title: "Show filter names",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
          ],
          name: "filterNamesHeader",
          applicable: "design",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/5988390-design-headers-and-links-show-filter-names-filters",
            message: "Display selected filters names for your calendar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show month",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.detailedList,
            CT.card,
            CT.cardList,
          ],
          name: "showMonthHeader",
          applicable: "design",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4472141-design-headers-and-links-show-month",
            message: "Show/hide month name in calendars and events lists.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        // default values for cards
        {
          title: "Show month",
          templates: [CT.cardSlider],
          name: "showMonthHeader",
          applicable: "design",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/4472141-design-headers-and-links-show-month",
            message: "Show/hide month name in calendars and events lists.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show month navigation",
          templates: [CT.eventList, CT.monthly, CT.detailedList],
          name: "showPaginationNavigation",
          applicable: "design",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        // duplicate for cards
        {
          title: "Show month navigation",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          name: "showPaginationNavigation",
          applicable: "design",
          defaultValue: 0,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Allow changing weeks",
          templates: [CT.weekly],
          name: "wwAllowChangingWeeks",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4914259-design-headers-and-links-allow-changing-weeks",
            message:
              "Weekly view only: Allow scrolling through past/future weeks",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Links",
      options: [
        {
          title: "Open links in new tab",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
          ],
          name: "openLinksNewTab",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4472088-design-headers-and-links-open-links-in-new-tab",
            message: "Open calendar or events list link(s) in a new tab.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Filters",
      options: [
        {
          title: "Groups selector",
          templates: [CT.eventList, CT.monthly, CT.weekly, CT.detailedList],
          integrations: [IT.ccb, IT.pco],
          name: "showgroupDropdown",
          applicable: "design",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/5988390-design-headers-and-links-show-filter-names-filters",
            message: "Display/hide Groups filter on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        // duplicate for cards
        {
          title: "Groups selector",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.ccb, IT.pco],
          name: "showgroupDropdown",
          applicable: "design",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/5988390-design-headers-and-links-show-filter-names-filters",
            message: "Display/hide Groups filter on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Groups selector label",
          templates: [CT.eventList, CT.monthly, CT.weekly, CT.detailedList],
          integrations: [IT.ccb, IT.pco],
          name: "labelgroupDropdown",
          applicable: "design",
          defaultValue: "Groups",
          show: [
            {
              field: "showgroupDropdown",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Groups selector label",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.ccb, IT.pco],
          name: "labelgroupDropdown",
          applicable: "design",
          defaultValue: "Groups",
          show: [
            {
              field: "showgroupDropdown",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Campuses selector",
          templates: [CT.eventList, CT.monthly, CT.weekly, CT.detailedList],
          integrations: [IT.ccb],
          name: "showcampusDropdown",
          applicable: "design",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/5988390-design-headers-and-links-show-filter-names-filters",
            message: "Display/hide Campuses filter on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        // duplicate for cards
        {
          title: "Campuses selector",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.ccb],
          name: "showcampusDropdown",
          applicable: "design",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/5988390-design-headers-and-links-show-filter-names-filters",
            message: "Display/hide Campuses filter on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Campuses selector label",
          templates: [CT.eventList, CT.monthly, CT.weekly, CT.detailedList],
          integrations: [IT.ccb],
          name: "labelcampusDropdown",
          applicable: "design",
          defaultValue: "Campuses",
          show: [
            {
              field: "showcampusDropdown",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Campuses selector label",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.ccb],
          name: "labelcampusDropdown",
          applicable: "design",
          defaultValue: "Campuses",
          show: [
            {
              field: "showcampusDropdown",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Departments selector",
          templates: [CT.eventList, CT.monthly, CT.weekly, CT.detailedList],
          integrations: [IT.ccb],
          name: "showdepartmentDropdown",
          applicable: "design",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/5988390-design-headers-and-links-show-filter-names-filters",
            message: "Display/hide Departments filter on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        // duplicate for cards
        {
          title: "Departments selector",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.ccb],
          name: "showdepartmentDropdown",
          applicable: "design",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/5988390-design-headers-and-links-show-filter-names-filters",
            message: "Display/hide Departments filter on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Departments selector label",
          templates: [CT.eventList, CT.monthly, CT.weekly, CT.detailedList],
          integrations: [IT.ccb],
          name: "labeldepartmentDropdown",
          applicable: "design",
          defaultValue: "Departments",
          show: [
            {
              field: "showdepartmentDropdown",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Departments selector label",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.ccb],
          name: "labeldepartmentDropdown",
          applicable: "design",
          defaultValue: "Departments",
          show: [
            {
              field: "showdepartmentDropdown",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Locations selector",
          templates: [CT.eventList, CT.monthly, CT.weekly, CT.detailedList],
          integrations: [IT.ccb, IT.breeze],
          name: "showlocationDropdown",
          applicable: "design",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/5988390-design-headers-and-links-show-filter-names-filters",
            message: "Display/hide Locations filter on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        // duplicate for cards
        {
          title: "Locations selector",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.ccb, IT.breeze],
          name: "showlocationDropdown",
          applicable: "design",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/5988390-design-headers-and-links-show-filter-names-filters",
            message: "Display/hide Locations filter on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },

        {
          title: "Locations selector label",
          templates: [CT.eventList, CT.monthly, CT.weekly, CT.detailedList],
          integrations: [IT.ccb, IT.breeze],
          name: "labellocationDropdown",
          applicable: "design",
          defaultValue: "Locations",
          show: [
            {
              field: "showlocationDropdown",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Locations selector label",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.ccb, IT.breeze],
          name: "labellocationDropdown",
          applicable: "design",
          defaultValue: "Locations",
          show: [
            {
              field: "showlocationDropdown",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Locations selector",
          templates: [
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.detailedList,
          ],
          integrations: [IT.google],
          name: "showplaceDropdown",
          applicable: "design",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/5988390-design-headers-and-links-show-filter-names-filters",
            message: "Display/hide Locations filter on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Locations selector label",
          templates: [
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.detailedList,
          ],
          integrations: [IT.google],
          name: "labelplaceDropdown",
          applicable: "design",
          defaultValue: "Locations",
          show: [
            {
              field: "showplaceDropdown",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Calendars selector",
          templates: [CT.eventList, CT.monthly, CT.weekly, CT.detailedList],
          integrations: [IT.google, IT.breeze],
          name: "showcalendarDropdown",
          applicable: "design",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/5988390-design-headers-and-links-show-filter-names-filters",
            message: "Display/hide Calendars filter on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        // duplicate for cards
        {
          title: "Calendars selector",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.google, IT.breeze],
          name: "showcalendarDropdown",
          applicable: "design",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/5988390-design-headers-and-links-show-filter-names-filters",
            message: "Display/hide Calendars filter on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Calendars selector label",
          templates: [CT.eventList, CT.monthly, CT.weekly, CT.detailedList],
          integrations: [IT.google, IT.breeze],
          name: "labelcalendarDropdown",
          applicable: "design",
          defaultValue: "Calendars",
          show: [
            {
              field: "showcalendarDropdown",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Calendars selector label",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.google, IT.breeze],
          name: "labelcalendarDropdown",
          applicable: "design",
          defaultValue: "Calendars",
          show: [
            {
              field: "showcalendarDropdown",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Tags selector",
          templates: [CT.eventList, CT.monthly, CT.weekly, CT.detailedList],
          integrations: [IT.pco, IT.breeze, IT.google],
          name: "showtagDropdown",
          applicable: "design",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/5988390-design-headers-and-links-show-filter-names-filters",
            message: "Display/hide Tags filter on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        // duplicate for cards
        {
          title: "Tags selector",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.pco, IT.breeze, IT.google],
          name: "showtagDropdown",
          applicable: "design",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/5988390-design-headers-and-links-show-filter-names-filters",
            message: "Display/hide Tags filter on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Tags selector label",
          templates: [CT.eventList, CT.monthly, CT.weekly, CT.detailedList],
          integrations: [IT.pco, IT.breeze, IT.google],
          name: "labeltagDropdown",
          applicable: "design",
          defaultValue: "Tags",
          show: [
            {
              field: "showtagDropdown",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Tags selector label",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.pco, IT.breeze, IT.google],
          name: "labeltagDropdown",
          applicable: "design",
          defaultValue: "Tags",
          show: [
            {
              field: "showtagDropdown",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
      ],
    },
    {
      label: "Filters controls",
      options: [
        {
          title: "Show filter toggle button",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
          ],
          integrations: [IT.ccb, IT.pco, IT.google, IT.breeze],
          name: "filterToggleShow",
          applicable: "design",
          defaultValue: 1,
          show: [
            ...filterConditionsForNonCardTemplates,
            ...filterConditionsForCardTemplates,
          ],
          tooltip: {
            url: "",
            message: "Show filter toggle button on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Expand filters by default",
          templates: [CT.eventList, CT.monthly, CT.weekly],
          integrations: [IT.ccb],
          name: "filterExpanded",
          applicable: "design",
          defaultValue: 0,
          show: filterConditionsForNonCardTemplates,
          tooltip: {
            url: "",
            message: "Expand Filter menu on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Expand filters by default",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.ccb, IT.pco, IT.google, IT.breeze],
          name: "filterExpanded",
          applicable: "design",
          defaultValue: 0,
          show: filterConditionsForCardTemplates,
          tooltip: {
            url: "",
            message: "Expand Filter menu on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Expand filters by default",
          templates: [CT.eventList, CT.monthly, CT.weekly],
          integrations: [IT.google],
          name: "filterExpanded",
          applicable: "design",
          defaultValue: 0,
          show: filterConditionsForGoogleTemplates,
          tooltip: {
            url: "",
            message: "Expand Filter menu on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Expand filters by default",
          templates: [CT.eventList, CT.monthly, CT.weekly],
          integrations: [IT.breeze],
          name: "filterExpanded",
          applicable: "design",
          defaultValue: 0,
          show: filterConditionsForBreezeTemplates,
          tooltip: {
            url: "",
            message: "Expand Filter menu on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Expand filters by default",
          templates: [CT.eventList, CT.monthly, CT.weekly],
          integrations: [IT.pco],
          name: "filterExpanded",
          applicable: "design",
          defaultValue: 0,
          show: filterConditionsForPcoTemplates,
          tooltip: {
            url: "",
            message: "Expand Filter menu on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show label next to filters button",
          templates: [CT.eventList, CT.monthly, CT.weekly],
          integrations: [IT.ccb],
          name: "showFiltersLabel",
          applicable: "design",
          defaultValue: 0,
          show: filterConditionsForNonCardTemplates,
          tooltip: {
            url: "",
            message: "Display/hide Filter label on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show label next to filters button",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.ccb, IT.pco, IT.google, IT.breeze],
          name: "showFiltersLabel",
          applicable: "design",
          defaultValue: 0,
          show: filterConditionsForCardTemplates,
          tooltip: {
            url: "",
            message: "Display/hide Filter label on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show label next to filters button",
          templates: [CT.eventList, CT.monthly, CT.weekly],
          integrations: [IT.google],
          name: "showFiltersLabel",
          applicable: "design",
          defaultValue: 0,
          show: filterConditionsForGoogleTemplates,
          tooltip: {
            url: "",
            message: "Display/hide Filter label on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show label next to filters button",
          templates: [CT.eventList, CT.monthly, CT.weekly],
          integrations: [IT.breeze],
          name: "showFiltersLabel",
          applicable: "design",
          defaultValue: 0,
          show: filterConditionsForBreezeTemplates,
          tooltip: {
            url: "",
            message: "Display/hide Filter label on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show label next to filters button",
          templates: [CT.eventList, CT.monthly, CT.weekly],
          integrations: [IT.pco],
          name: "showFiltersLabel",
          applicable: "design",
          defaultValue: 0,
          show: filterConditionsForPcoTemplates,
          tooltip: {
            url: "",
            message: "Display/hide Filter label on action bar",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Filters label",
          templates: [CT.eventList, CT.monthly, CT.weekly],
          integrations: [IT.ccb],
          name: "headerFiltersLabel",
          applicable: "design",
          defaultValue: "Filters",
          tooltip: {
            url: "",
          },
          show: [
            ...filterConditionsForNonCardTemplates,
            {
              field: "showFiltersLabel",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "text",
            options: {
              placeholder: "Filters label",
            },
          },
        },
        {
          title: "Filters label",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.ccb, IT.pco, IT.google, IT.breeze],
          name: "headerFiltersLabel",
          applicable: "design",
          defaultValue: "Filters",
          tooltip: {
            url: "",
          },
          show: [
            ...filterConditionsForCardTemplates,
            {
              field: "showFiltersLabel",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "text",
            options: {
              placeholder: "Filters label",
            },
          },
        },
        {
          title: "Filters label",
          templates: [CT.eventList, CT.monthly, CT.weekly],
          integrations: [IT.google, IT.breeze],
          name: "headerFiltersLabel",
          applicable: "design",
          defaultValue: "Filters",
          tooltip: {
            url: "",
          },
          show: [
            ...filterConditionsForGoogleTemplates,
            {
              field: "showFiltersLabel",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "text",
            options: {
              placeholder: "Filters label",
            },
          },
        },
        {
          title: "Filters label",
          templates: [CT.eventList, CT.monthly, CT.weekly],
          integrations: [IT.breeze],
          name: "headerFiltersLabel",
          applicable: "design",
          defaultValue: "Filters",
          tooltip: {
            url: "",
          },
          show: [
            ...filterConditionsForBreezeTemplates,
            {
              field: "showFiltersLabel",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "text",
            options: {
              placeholder: "Filters label",
            },
          },
        },
        {
          title: "Filters label",
          templates: [CT.eventList, CT.monthly, CT.weekly],
          integrations: [IT.pco],
          name: "headerFiltersLabel",
          applicable: "design",
          defaultValue: "Filters",
          tooltip: {
            url: "",
          },
          show: [
            ...filterConditionsForPcoTemplates,
            {
              field: "showFiltersLabel",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "text",
            options: {
              placeholder: "Filters label",
            },
          },
        },
      ],
    },
  ],
};
