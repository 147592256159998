/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/02/2023.
 */

const AlertsMiddleware = (storeAPI) => (next) => (action) => {
  switch (action.type) {
    case "ALERTS_FETCH_FULFILLED":
      action.payload = action.payload.map((i) => {
        if (i.integration && !Number.parseInt(i.integration.available)) {
          i.integration = null;
        }
        return i;
      });

      break;

    case "ALERT_FETCH_FULFILLED":
    case "ALERT_UPDATE_FULFILLED":
      if (
        action.payload.integration &&
        !Number.parseInt(action.payload.integration.available)
      ) {
        action.payload.integration = null;
      }
      break;
  }

  next(action);
};

export default AlertsMiddleware;
