/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/07/2022.
 */

export const CTA_OPTIONS_CCB = [
  {
    label: "Open event page",
    value: "eventPage",
  },
  {
    label: "Link to a form",
    value: "form",
  },
  {
    label: "Use first URL from event's description",
    value: "linkFirst",
  },
  {
    label: "Use last URL from event's description",
    value: "linkLast",
  },
  {
    label: "Contact organizer",
    value: "organizer",
  },
];

export const CTA_POSITIONS = [
  {
    label: "Center",
    value: "center",
  },
  {
    label: "Left",
    value: "left",
  },
  {
    label: "Right",
    value: "right",
  },
];

export const CTA_OPTIONS_PCO = [
  {
    label: "Open event page",
    value: "eventPage",
  },
  {
    label: "Link to a PCO Signup Form",
    value: "form",
  },
  {
    label: "Use first URL from event's description",
    value: "linkFirst",
  },
  {
    label: "Use last URL from event's description",
    value: "linkLast",
  },
  {
    label: "Contact organizer",
    value: "organizer",
  },
];

export const CTA_OPTIONS_BREEZE_GOOGLE = [
  {
    label: "Use first URL from event's description",
    value: "linkFirst",
  },
  {
    label: "Use last URL from event's description",
    value: "linkLast",
  },
  {
    label: "Contact organizer",
    value: "organizer",
  },
];
