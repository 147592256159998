/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/04/2022.
 */

import { definitions as Frame } from "./frame";
import { definitions as Fonts } from "./fonts";
import { definitions as Outline } from "./outline";
import { definitions as OutlineStyles } from "./outlined_styles";
import { definitions as CardStyles } from "./card_styles";
import { definitions as HeadersAndLinks } from "./headers_and_links";
import { definitions as Navigation } from "./navigation";
import { definitions as CardsGrid } from "./cards";
import { definitions as Popup } from "./popup";
import { definitions as PopupStyles } from "./popup_styles";
import { definitions as TwoColumns } from "./two_columns";
import { definitions as Mosaic } from "./mosaic";
import { definitions as MosaicStyles } from "./mosaic_styles";
import { definitions as TwoColumnsStyles } from "./two_columns_styles";
import { definitions as Badges } from "./badges";
import { definitions as SharePopup } from "./share_popup";


const definitions = [
  Frame,
  HeadersAndLinks,
  Outline,
  CardsGrid,
  TwoColumns,
  Mosaic,
  Badges,
  Popup,
  SharePopup,
  OutlineStyles,
  CardStyles,
  TwoColumnsStyles,
  MosaicStyles,
  PopupStyles,
  Navigation,
  Fonts
];


export default definitions;
