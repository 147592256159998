/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/04/2022.
 */

export const WT = {
  calendar: "calendar",
  groups: "groups",
  alerts: "alerts",
  signups: "signup",
  email: "email",
};
