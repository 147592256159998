/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/06/2021.
 */

import { GT } from "../../../consts";

const FontSizesOptions = [
  {
    label: "Extra small",
    value: "12px",
  },
  {
    label: "Small",
    value: "14px",
  },
  {
    label: "Medium",
    value: "16px",
  },
  {
    label: "Large",
    value: "18px",
  },
  {
    label: "Extra large",
    value: "20px",
  },
];

export const definitions = {
  title: "Fonts",
  icon: "format_size",
  groups: [
    {
      label: "Font Family",
      options: [
        {
          title: "General",
          templates: [GT.card, GT.twoColumn, GT.outlined],
          name: "fontFamily",
          defaultValue: "Roboto",
          style: {
            selector: (uuid) => ".dce-groups",
            property: "font-family",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Set font type of text OTHER THAN group name.",
          },
          control: {
            kind: "fontFamily",
            options: {
              placeholder: "Search Google Fonts",
              inherit: true,
            },
          },
        },
        {
          title: "Group name",
          templates: [GT.twoColumn, GT.card],
          name: "fontFamilyGroupName",
          defaultValue: "Cabin",
          style: {
            selector: [
              ".dce--two-columns__row h2",
              ".dce--groups-cards-card_content-body h2",
            ],
            property: "font-family",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Set font type of group name.",
          },
          control: {
            kind: "fontFamily",
            options: {
              placeholder: "Search Google Fonts",
              inherit: true,
            },
          },
        },
        {
          title: "Group name",
          templates: [GT.outlined],
          name: "fontFamilyGroupName",
          defaultValue: "Roboto",
          style: {
            selector: [".dce--outlined-header"],
            property: "font-family",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Set font type of group name.",
          },
          control: {
            kind: "fontFamily",
            options: {
              placeholder: "Search Google Fonts",
              inherit: true,
            },
          },
        },
        {
          title: "Group name",
          templates: [GT.mosaic],
          name: "fontFamilyGroupName",
          defaultValue: "Roboto",
          style: {
            selector: [".dce--mosaic-tile-header h2"],
            property: "font-family",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Set font type of group name.",
          },
          control: {
            kind: "fontFamily",
            options: {
              placeholder: "Search Google Fonts",
              inherit: true,
            },
          },
        },
      ],
    },
    {
      label: "Size",
      options: [
        {
          title: "Responsive",
          templates: [GT.card, GT.twoColumn, GT.mosaic, GT.outlined],
          name: "gcFontSizeDesktop",
          defaultValue: "16px",
          style: {
            selector: [
              (uuid) => ".dce-groups",
              (uuid) => ".dce-groups-modal--wrapper",
            ],
            property: ["font-size"],
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Reduce/enlarge font sizes as global parameter.",
          },
          control: {
            kind: "range",
            options: FontSizesOptions,
          },
        },
      ],
    },
    /*{
      label: "Filters",
      options: [
        {
          title: "Font family",
          templates: [
            CT.cardList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.eventList,
            CT.cardSlider,
          ],
          name: "fontFamilyFilters",
          defaultValue: "Open Sans",
          style: {
            selector: uuid => ".calendar .calendar-filters",
            property: "font-family",
          },
          /!*tooltip: {
            url:
              "https://help.display.church/en/articles/4495714-design-fonts-font-family",
            message: "Select the font for calendars and events lists.",
          },*!/
          control: {
            kind: "fontFamily",
            options: {
              placeholder: "Search Google Fonts",
              inherit: true,
            },
          },
        },
      ],
    },*/
  ],
};
