/**
 * Created by piotr.pozniak@thebeaverhead.com on 01/07/2022.
 */

import { GT, IT } from "../../../consts";
import CTA_OPTIONS from "./cta_options";

export const definitions = {
  title: "Cards grid",
  icon: "grid_view",
  groups: [
    {
      label: "Main information",
      options: [
        {
          title: "Show group image if available",
          templates: [GT.card],
          name: "gSImageMain",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_b1ffd505b8",
            message: "Show/Hide the group image (if there is one).",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show address",
          templates: [GT.card],
          name: "gcgShowAddress",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_b1ffd505b8",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show meeting date and time",
          templates: [GT.card],
          name: "gcgShowMeetingTime",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_b1ffd505b8",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show group name",
          templates: [GT.card],
          name: "gcgShowName",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_b1ffd505b8",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show group description",
          templates: [GT.card],
          name: "gSDescriptionCard",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_b1ffd505b8",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Details",
      options: [
        {
          title: "Show registration status",
          templates: [GT.card],
          integrations: [IT.ccb],
          name: "gcgShowRegistrationStatus",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_b1ffd505b8",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show interaction type",
          templates: [GT.card],
          integrations: [IT.ccb],
          name: "gcgShowInteractionType",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_b1ffd505b8",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show childcare information",
          templates: [GT.card],
          integrations: [IT.ccb],
          name: "gcgShowChildcare",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_b1ffd505b8",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show group type",
          templates: [GT.card],
          name: "gcgShowGroupType",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_b1ffd505b8",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show membership type",
          templates: [GT.card],
          name: "gcgShowMembershipType",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_b1ffd505b8",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show leader",
          templates: [GT.card],
          integrations: [IT.ccb],
          name: "gcgShowLeader",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_b1ffd505b8",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show contact email",
          templates: [GT.card],
          integrations: [IT.pco],
          name: "gcgShowContactEmail",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_b1ffd505b8",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show department",
          templates: [GT.card],
          integrations: [IT.ccb],
          name: "gcgShowDepartment",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_b1ffd505b8",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show group capacity",
          templates: [GT.card],
          integrations: [IT.ccb],
          name: "gcgShowCapacity",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_b1ffd505b8",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show members count",
          templates: [GT.card],
          name: "gcgShowMembersCount",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_b1ffd505b8",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show enrollment availability",
          templates: [GT.card],
          integrations: [IT.pco],
          name: "gcgShowEnrollmentOpen",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_b1ffd505b8",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Primary call-to-action",
      options: [
        {
          title: "Show CTA button",
          templates: [GT.card],
          name: "gcCShowCta1st",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA action",
          templates: [GT.card],
          name: "gcCCTAAction1st",
          defaultValue: "groupPage",
          tooltip: null,
          show: [
            {
              field: "gcCShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_OPTIONS,
            },
          },
        },
        {
          title: "Use default CTA button label",
          templates: [GT.card],
          name: "gcC1stDefaultCTALabel",
          defaultValue: 1,
          show: [
            {
              field: "gcCShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA button label",
          templates: [GT.card],
          name: "gcC1stCTALabel",
          defaultValue: "Join group",
          tooltip: null,
          show: [
            {
              field: "gcC1stDefaultCTALabel",
              value: 0,
              defaultValue: 1,
            },
            {
              field: "gcCShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "text",
            options: {
              placeholder: "CTA button label",
            },
          },
        },
        {
          title: "Subject line",
          templates: [GT.card],
          name: "gcCCTASubject1st",
          defaultValue: "Join Your Small Group",
          show: [
            {
              field: "gcCCTAAction1st",
              value: "contactLeader",
              defaultValue: "groupPage",
            },
            {
              field: "gcCShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Message",
          templates: [GT.card],
          name: "gcCCTAMessage1st",
          defaultValue:
            "Hello! I am interested in joining your small group. Can I get more information? Thank you!",
          show: [
            {
              field: "gcCCTAAction1st",
              value: "contactLeader",
              defaultValue: "groupPage",
            },
            {
              field: "gcCShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "textarea",
            options: {},
          },
        },
      ],
    },
    {
      label: "Secondary call-to-action",
      options: [
        {
          title: "Show CTA button",
          templates: [GT.card],
          name: "gcCShowCta2nd",
          defaultValue: 0,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA action",
          templates: [GT.card],
          name: "gcCCTAAction2nd",
          defaultValue: "groupPage",
          tooltip: null,
          show: [
            {
              field: "gcCShowCta2nd",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_OPTIONS,
            },
          },
        },
        {
          title: "Use default CTA button label",
          templates: [GT.card],
          name: "gcC2ndDefaultCTALabel",
          defaultValue: 1,
          show: [
            {
              field: "gcCShowCta2nd",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA button label",
          templates: [GT.card],
          name: "gcC2ndCTALabel",
          defaultValue: "Join group",
          tooltip: null,
          show: [
            {
              field: "gcC2ndDefaultCTALabel",
              value: 0,
              defaultValue: 1,
            },
            {
              field: "gcCShowCta2nd",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "text",
            options: {
              placeholder: "CTA button label",
            },
          },
        },
        {
          title: "Subject line",
          templates: [GT.card],
          name: "gcCCTASubject2nd",
          defaultValue: "Join Your Small Group",
          show: [
            {
              field: "gcCCTAAction2nd",
              value: "contactLeader",
              defaultValue: "groupPage",
            },
            {
              field: "gcCShowCta2nd",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Message",
          templates: [GT.card],
          name: "gcCCTAMessage2nd",
          defaultValue:
            "Hello! I am interested in joining your small group. Can I get more information? Thank you!",
          show: [
            {
              field: "gcCCTAAction2nd",
              value: "contactLeader",
              defaultValue: "groupPage",
            },
            {
              field: "gcCShowCta2nd",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "textarea",
            options: {},
          },
        },
      ],
    },
  ],
};
