/**
 * Created by enes.kahrovic@thebeaverhead.com on 23/10/2018.
 */

const initialState = {
  model: null,

  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  update: false,
  updateSuccess: false,
  updateError: null,

  updateIntegrationUUID: null,

  checkAccess: false,
  checkAccessSuccess: false,
  checkAccessError: null,
  accessErrors: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "INTEGRATION_SET": {
      return { ...initialState, model: action.payload };
    }

    case "INTEGRATION_UPDATE":
      return {
        ...state,
        update: true,
        updateError: null,
        updateSuccess: false,
      };

    case "INTEGRATION_UPDATE_FULFILLED":
      return {
        ...state,
        model: action.payload,
        update: false,
        updateSuccess: true,
      };

    case "INTEGRATION_UPDATE_REJECTED":
      return {
        ...state,
        update: false,
        updateError: action.payload,
        updateIntegrationUUID: action.integrationUuid,
      };

    case "INTEGRATION_ACCESS_CHECK":
      return {
        ...state,
        checkAccess: true,
        checkAccessSuccess: false,
        checkAccessError: null,
      };

    case "INTEGRATION_ACCESS_CHECK_FULFILLED":
      return {
        ...state,
        checkAccess: false,
        checkAccessSuccess: true,
        accessErrors: action.payload,
      };

    case "INTEGRATION_ACCESS_CHECK_REJECTED":
      return {
        ...state,
        checkAccess: false,
        checkAccessError: action.payload,
      };

    default:
      return state;
  }

  return state;
}
