/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/10/2018.
 */

const initialState = {
  model: null,
  token: null,

  login: false,
  loginSuccess: false,
  loginError: null,

  register: false,
  registerSuccess: false,
  registerError: null,

  resetPassword: false,
  resetPasswordSuccess: false,
  resetPasswordError: null,

  remindPassword: false,
  remindPasswordSuccess: false,
  remindPasswordError: null,

  edit: false,
  editSuccess: false,
  editError: null,

  resendActivation: false,
  resendActivationSuccess: false,
  resendActivationError: null,

  checkSession: false,
  checkSessionSuccess: false,
  sessionExpired: false,

  switchOrganization: false,
  switchOrganizationSuccess: false,
  switchOrganizationError: false,
  switchingToOrgUUID: null,
};

export default function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case "USER_LOGIN":
      return {
        ...state,
        login: true,
        loginSuccess: false,
        loginError: null,
      };

    case "USER_PASSWORD_RESET_FULFILLED":
    case "USER_REGISTER_FULFILLED":
    case "USER_LOGIN_FULFILLED":
    case "USER_AUTOLOGIN_FULFILLED":
    case "USER_ACTIVATE_PENDING_ACCOUNT_FULFILLED":
    case "USER_AUTOLOGOUT":
      return {
        ...state,
        model: action.payload.user,
        token: action.payload.token,

        login: false,
        loginSuccess: true,
        resetPassword: false,
        resetPasswordSuccess: true,
        register: false,
        registerSuccess: true,
      };

    case "USER_LOGIN_REJECTED":
      return {
        ...state,
        login: false,
        loginSuccess: false,
        loginError: action.payload,
      };

    case "USER_LOGOUT":
      return { ...initialState };

    case "USER_PASSWORD_RESET":
      return {
        ...state,
        resetPassword: true,
        resetPasswordSuccess: false,
        resetPasswordError: false,
      };

    case "USER_PASSWORD_REMIND_FULFILLED":
      return {
        ...state,
        remindPassword: false,
        remindPasswordSuccess: true,
      };

    case "USER_PASSWORD_RESET_REJECTED":
      return {
        ...state,
        resetPassword: false,
        resetPasswordError: action.payload,
      };

    case "USER_PASSWORD_REMIND":
      return {
        ...state,
        remindPassword: true,
        remindPasswordSuccess: false,
        remindPasswordError: false,
      };

    case "USER_PASSWORD_REMIND_REJECTED":
      return {
        ...state,
        remindPassword: false,
        remindPasswordError: action.payload,
      };

    case "USER_REGISTER":
    case "USER_ACTIVATE_PENDING_ACCOUNT":
      return {
        ...state,
        register: true,
        registerSuccess: false,
        registerError: null,
      };

    case "USER_REGISTER_REJECTED":
    case "USER_ACTIVATE_PENDING_ACCOUNT_REJECTED":
      return {
        ...state,
        register: false,
        registerError: action.payload,
      };

    case "USER_EDIT":
      return action.isAdminRequest
        ? state
        : {
            ...state,
            edit: true,
            editSuccess: false,
            editError: null,
          };

    case "USER_EDIT_FULFILLED":
      return action.isAdminRequest
        ? state
        : {
            ...state,
            model: action.payload,

            edit: false,
            editSuccess: true,
          };

    case "USER_EDIT_REJECTED":
      return action.isAdminRequest
        ? state
        : {
            ...state,
            edit: false,
            editError: action.payload,
          };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        model: state.model,
        token: state.token,
      };

    case "USER_CHECK_SESSION":
      return {
        ...state,
        checkSession: true,
        checkSessionSuccess: false,
      };

    case "USER_CHECK_SESSION_REJECTED":
      return {
        ...state,
        sessionExpired: true,
        checkSession: false,
        checkSessionSuccess: false,
      };

    case "USER_CHECK_SESSION_FULFILLED":
      return {
        ...state,
        model: action.payload.user,
        token: action.payload.token,
        checkSession: false,
        checkSessionSuccess: true,
      };

    case "USER_RESEND_TOKEN":
      return {
        ...state,
        resendActivation: true,
        resendActivationSuccess: false,
        resendActivationError: null,
      };

    case "USER_RESEND_TOKEN_FULFILLED":
      return {
        ...state,
        resendActivation: false,
        resendActivationSuccess: true,
      };

    case "USER_RESEND_TOKEN_REJECTED":
      return {
        ...state,
        resendActivation: false,
        resendActivationError: action.payload,
      };

    case "USER_SWITCH_ORG":
      return {
        ...state,
        switchingToOrgUUID: action.payload.orgUUID,
        switchOrganization: true,
        switchOrganizationSuccess: false,
        switchOrganizationError: null,
      };

    case "USER_SWITCH_ORG_FULFILLED":
      return {
        ...state,
        model: action.payload.user,
        token: action.payload.token,
        switchOrganization: false,
        switchOrganizationSuccess: true,
        // switchingToOrgUUID: null,
      };

    case "USER_SWITCH_ORG_REJECTED":
      return {
        ...state,
        switchOrganization: false,
        switchOrganizationError: action.payload,
      };

    case "ORGANIZATION_EDIT_FULFILLED":
    case "UPLOAD_BRANDING_FILE_FULFILLED":
      const userOrgs = state.model.organizations.map((org) => {
        if (org.uuid === action.payload.uuid) {
          return action.payload;
        }
        return org;
      });

      return {
        ...state,
        model: {
          ...state.model,
          organizations: userOrgs,
        },
      };

    case "ORGANIZATION_CREATE_FULFILLED":
      state.model.organizations.push(action.payload);

      return {
        ...state,
        model: {
          ...state.model,
          organizations: [...state.model.organizations],
        },
      };

    case "USER_SET_DATA":
      return {
        ...state,
        model: action.payload.user,
        token: action.payload.token,
      };

    default:
      return state;
  }

  return state;
}
