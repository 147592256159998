/**
 * Created by piotr.pozniak@thebeaverhead.com on 01/07/2022.
 */

import { GT, IT } from "../../../consts";

export const definitions = {
  title: "Card styles",
  icon: "brush",
  groups: [
    {
      label: "Main information",
      options: [
        {
          title: "Accent color",
          templates: [GT.card],
          name: "gCAccent",
          defaultValue: "#2193b0",
          style: {
            selector: [
              //".col--left .group-cover-container",
              ".dce--groups-cards-card.dce--groups-cards-card--hover-effect--accent-simple .groups-cards-card--hover-effect--accent-simple",
              ".dce--groups-cards-card.dce--groups-cards-card--hover-effect--accent-expand .groups-cards-card--hover-effect--accent-expandable",
            ],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Card hover effect simple/expandable accent color.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },

        {
          title: "Group name color",
          templates: [GT.card],
          name: "gCGroupName",
          defaultValue: "#535353",
          style: {
            selector: [".dce--groups-cards-card_content-body h2"],
            property: "color",
          },
          show: [
            {
              field: "gcgShowName",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Color of group name.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },

        {
          title: "Location color",
          templates: [GT.card],
          name: "gcgLocationColor",
          defaultValue: "#A09E9E",
          style: {
            selector: [".dce--groups-card--address-line"],
            property: "color",
          },
          show: [
            {
              field: "gcgShowAddress",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Color of location.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Meeting time color",
          templates: [GT.card],
          name: "gcgMeetingColor",
          defaultValue: "#A09E9E",
          style: {
            selector: [".dce--groups-card--meeting"],
            property: "color",
          },
          show: [
            {
              field: "gcgShowMeetingTime",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Color of meeting time.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },

        {
          title: "Group description color",
          templates: [GT.card],
          name: "gCGroupDesc",
          defaultValue: "#535353",
          style: {
            selector: [".dce--groups-cards-card_content-body p"],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Color of group description text.",
          },
          show: [
            {
              field: "gSDescriptionCard",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Controls",
      options: [
        {
          title: "Image placeholder background",
          templates: [GT.card],
          name: "gBCImagePlaceholder",
          defaultValue: "linear-gradient(to bottom, #6dd5ed, #2193b0)",
          style: {
            selector: [".dce--groups-cards-card_header-image"],
            property: "background",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message:
              "Color of group image if pictures are not available or hidden.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Basic information",
      options: [
        {
          title: "Label color",
          templates: [GT.card],
          name: "gcgbLabelColor",
          defaultValue: "#A09E9E",
          style: {
            selector: [".dce--groups-card-details-header"],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Color of title of info label.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Value color",
          templates: [GT.card],
          name: "gcgbValueColor",
          defaultValue: "#535353",
          style: {
            selector: [".dce--groups-card-details-value"],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Color of data in info label.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Primary call-to-action",
      options: [
        {
          title: "Button color",
          templates: [GT.card],
          name: "gCCTA1stBtn",
          defaultValue: "#F02A9C",
          style: {
            selector: [
              ".dce--groups-card-details-item--primary-cta .dce--groups-card--cta-btn",
            ],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Button color on hover",
          templates: [GT.card],
          name: "gCCTA1stBtnHover",
          defaultValue: "#cd2387",
          style: {
            selector: [
              ".dce--groups-card-details-item--primary-cta .dce--groups-card--cta-btn:hover",
              ".dce--groups-card-details-item--primary-cta .dce--groups-card--cta-btn:active",
              ".dce--groups-card-details-item--primary-cta .dce--groups-card--cta-btn:focus",
            ],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color",
          templates: [GT.card],
          name: "gCCTA1stBtnLabel",
          defaultValue: "#fff",
          style: {
            selector: [
              ".dce--groups-card-details-item--primary-cta .dce--groups-card--cta-btn",
            ],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color on hover",
          templates: [GT.card],
          name: "gCCTA1stBtnLabelHover",
          defaultValue: "#fff",
          style: {
            selector: [
              ".dce--groups-card-details-item--primary-cta .dce--groups-card--cta-btn:hover",
              ".dce--groups-card-details-item--primary-cta .dce--groups-card--cta-btn:active",
              ".dce--groups-card-details-item--primary-cta .dce--groups-card--cta-btn:focus",
            ],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Secondary call-to-action",
      options: [
        {
          title: "Button color",
          templates: [GT.card],
          name: "gCCTA2ndBtn",
          defaultValue: "#D2D2D2",
          style: {
            selector: [
              ".dce--groups-card-details-item--secondary-cta .dce--groups-card--cta-btn",
            ],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Button color on hover",
          templates: [GT.card],
          name: "gCCTA2ndBtnHover",
          defaultValue: "#eae8e8",
          style: {
            selector: [
              ".dce--groups-card-details-item--secondary-cta .dce--groups-card--cta-btn:hover",
              ".dce--groups-card-details-item--secondary-cta .dce--groups-card--cta-btn:active",
              ".dce--groups-card-details-item--secondary-cta .dce--groups-card--cta-btn:focus",
            ],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color",
          templates: [GT.card],
          name: "gCCTA2ndBtnLabel",
          defaultValue: "#535353",
          style: {
            selector: [
              ".dce--groups-card-details-item--secondary-cta .dce--groups-card--cta-btn",
            ],
            property: "color",
          },
          totooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          oltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color on hover",
          templates: [GT.card],
          name: "gCCTA2ndBtnLabelHover",
          defaultValue: "#888888",
          style: {
            selector: [
              ".dce--groups-card-details-item--secondary-cta .dce--groups-card--cta-btn:hover",
              ".dce--groups-card-details-item--secondary-cta .dce--groups-card--cta-btn:active",
              ".dce--groups-card-details-item--secondary-cta .dce--groups-card--cta-btn:focus",
            ],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
