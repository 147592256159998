import { CT, IT } from "../../../consts";

export const definitions = {
  title: "Navigation",
  icon: "brush",
  groups: [
    {
      label: "Header",
      options: [
        {
          title: "Selected date",
          templates: [CT.eventList, CT.monthly, CT.card, CT.cardList],
          name: "currentDateColor",
          defaultValue: "#737373",
          style: {
            selector: [".month-selector .dce__h1", ".campuses-groups-header"],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4699311-design-navigation-selected-date",
            message:
              "Month + year color for monthly calendars and events lists.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Month selector",
      options: [
        {
          title: "Border",
          templates: [CT.eventList, CT.monthly, CT.card, CT.cardList],
          name: "monthSelectorBorderColor",
          defaultValue: "#737373",
          style: {
            selector: ".month-selector .navigation .arrow",
            property: "border-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4699625-design-navigation-month-selector-x-5",
            message: "Ring color for monthly calendars and events lists.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Background",
          templates: [CT.eventList, CT.monthly, CT.card, CT.cardList],
          name: "monthSelectorBackgroundColor",
          defaultValue: "inherit",
          style: {
            selector: ".month-selector .navigation .arrow",
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4699625-design-navigation-month-selector-x-5",
            message: "Ring color for monthly calendars and events lists.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Background hovered",
          templates: [CT.eventList, CT.monthly, CT.card, CT.cardList],
          name: "monthSelectorBackgroundHoveredColor",
          defaultValue: "#ededed",
          style: {
            selector: ".month-selector .navigation .arrow:hover",
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4699625-design-navigation-month-selector-x-5",
            message: "Ring color for monthly calendars and events lists.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Control",
          templates: [CT.eventList, CT.monthly, CT.card, CT.cardList],
          name: "monthSelectorControlColor",
          defaultValue: "#737373",
          style: {
            selector: ".month-selector .navigation .arrow",
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4699625-design-navigation-month-selector-x-5",
            message: "Ring color for monthly calendars and events lists.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Control hovered",
          templates: [CT.eventList, CT.monthly, CT.card, CT.cardList],
          name: "monthSelectorControlHoveredColor",
          defaultValue: "#fafafa",
          style: {
            selector: ".month-selector .navigation .arrow:hover",
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4699625-design-navigation-month-selector-x-5",
            message: "Ring color for monthly calendars and events lists.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Week selector",
      options: [
        {
          title: "Border",
          templates: [CT.weekly],
          name: "wwSelectorBorderColor",
          defaultValue: "#e2e2e2",
          style: {
            selector:
              ".date-range-selector__week-selector-arrow .date-range-selector__week-selector-arrow--navigation .arrow",
            property: "border-color",
          },
          tooltip: null,
          dependency: {
            field: "wwAllowChangingWeeks",
            value: 1,
            defaultValue: 1,
            message:
              'This requires "Allowing changing events" Header and Links option to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Background",
          templates: [CT.weekly],
          name: "wwSelectorBackgroundColor",
          defaultValue: "inherit",
          style: {
            selector:
              ".date-range-selector__week-selector-arrow .date-range-selector__week-selector-arrow--navigation .arrow",
            property: "background-color",
          },
          tooltip: null,
          dependency: {
            field: "wwAllowChangingWeeks",
            value: 1,
            defaultValue: 1,
            message:
              'This requires "Allowing changing events" Header and Links option to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Background hovered",
          templates: [CT.weekly],
          name: "wwSelectorBackgroundHoveredColor",
          defaultValue: "#e2e2e2",
          style: {
            selector:
              ".date-range-selector__week-selector-arrow .date-range-selector__week-selector-arrow--navigation .arrow:hover",
            property: "background-color",
          },
          tooltip: null,
          dependency: {
            field: "wwAllowChangingWeeks",
            value: 1,
            defaultValue: 1,
            message:
              'This requires "Allowing changing events" Header and Links option to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Selector control",
          templates: [CT.weekly],
          name: "wwSelectorControlColor",
          defaultValue: "#e2e2e2",
          style: {
            selector:
              ".date-range-selector__week-selector-arrow .date-range-selector__week-selector-arrow--navigation .arrow",
            property: "color",
          },
          tooltip: null,
          dependency: {
            field: "wwAllowChangingWeeks",
            value: 1,
            defaultValue: 1,
            message:
              'This requires "Allowing changing events" Header and Links option to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Selector control hovered",
          templates: [CT.weekly],
          name: "wwSelectorControlHoveredColor",
          defaultValue: "#fafafa",
          style: {
            selector:
              ".date-range-selector__week-selector-arrow .date-range-selector__week-selector-arrow--navigation .arrow:hover",
            property: "color",
          },
          tooltip: null,
          dependency: {
            field: "wwAllowChangingWeeks",
            value: 1,
            defaultValue: 1,
            message:
              'This requires "Allowing changing events" Header and Links option to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Selected week",
          templates: [CT.weekly],
          name: "wwSelectedWeekColor",
          defaultValue: "#adadad",
          style: {
            selector: ".date-range-selector-week-dropdown-value",
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Selected week background",
          templates: [CT.weekly],
          name: "wwSelectedWeekBgColor",
          defaultValue: "#f5f4f4",
          style: {
            selector: ".date-range-selector-week-dropdown-value",
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Weeks dropdown background",
          templates: [CT.weekly],
          name: "wwWeekSelectorBg",
          defaultValue: "#f79191",
          style: {
            selector: [
              ".date-range-selector-week-dropdown-controls",
              ".date-range-selector-week-dropdown-list",
              ".date-range-selector-week-dropdown-list-mover",
            ],
            property: "background-color",
          },
          tooltip: null,
          dependency: {
            field: "wwAllowChangingWeeks",
            value: 1,
            defaultValue: 1,
            message:
              'This requires "Allowing changing events" Header and Links option to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Weeks dropdown hovered background",
          templates: [CT.weekly],
          name: "wwWeekSelectorHoverBg",
          defaultValue: "#ef7777",
          style: {
            selector: [
              ".date-range-selector-week-dropdown-list-mover:hover",
              ".date-range-selector-week-dropdown-list-container li:hover",
            ],
            property: "background-color",
          },
          tooltip: null,
          dependency: {
            field: "wwAllowChangingWeeks",
            value: 1,
            defaultValue: 1,
            message:
              'This requires "Allowing changing events" Header and Links option to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Weeks dropdown selected item",
          templates: [CT.weekly],
          name: "wwWeekSelectorSelected",
          defaultValue: "#ef7777",
          style: {
            selector: [
              ".date-range-selector-week-dropdown-list-container li.selected",
            ],
            property: "background-color",
          },
          tooltip: null,
          dependency: {
            field: "wwAllowChangingWeeks",
            value: 1,
            defaultValue: 1,
            message:
              'This requires "Allowing changing events" Header and Links option to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Weeks dropdown option",
          templates: [CT.weekly],
          name: "wwWeekSelectorOption",
          defaultValue: "#fff",
          style: {
            selector: [
              ".date-range-selector-week-dropdown-controls",
              ".date-range-selector-week-dropdown-list-mover",
              ".date-range-selector-week-dropdown-list-container li",
            ],
            property: "color",
          },
          tooltip: null,
          dependency: {
            field: "wwAllowChangingWeeks",
            value: 1,
            defaultValue: 1,
            message:
              'This requires "Allowing changing events" Header and Links option to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Weeks dropdown border",
          templates: [CT.weekly],
          name: "wwWeekSelectorBorder",
          defaultValue: "#f7a6a6",
          style: {
            selector: [
              ".date-range-selector-week-dropdown-list-container ul",
              ".date-range-selector-week-dropdown-list-container ul li",
            ],
            property: "border-color",
          },
          tooltip: null,
          dependency: {
            field: "wwAllowChangingWeeks",
            value: 1,
            defaultValue: 1,
            message:
              'This requires "Allowing changing events" Header and Links option to be turned on',
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Filters",
      options: [
        {
          title: "Toggle background",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filtersToggleBgColor",
          defaultValue: "#d3d3d3",
          style: [
            {
              selector: [".month-selector--filters-button"],
              property: "background-color",
            },
            {
              selector: ".month-selector--filters-controls .filter_title",
              property: "color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/4725931-design-navigation-filter-toggle-switch-x-7",
            message:
              "Background of toggle switch for events lists and all calendar views.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Toggle hovered background",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filtersToggleBgHoverColor",
          defaultValue: "#d3d3d3c2",
          style: [
            {
              selector: ".month-selector--filters-button:hover",
              property: "background-color",
            },
            {
              selector: ".month-selector--filters-controls .filter_title:hover",
              property: "color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/4725931-design-navigation-filter-toggle-switch-x-7",
            message:
              "Background of toggle switch for events lists and all calendar views.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Toggle active background",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filtersToggleBgActiveColor",
          defaultValue: "#adadad",
          style: {
            selector: ".month-selector--filters-button.active",
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4725931-design-navigation-filter-toggle-switch-x-7",
            message:
              "Background of toggle switch for events lists and all calendar views.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Toggle color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filtersToggleColor",
          defaultValue: "#fff",
          style: {
            selector: ".month-selector--filters-button",
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4725931-design-navigation-filter-toggle-switch-x-7",
            message:
              "Background of toggle switch for events lists and all calendar views.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Toggle hover color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filtersToggleHoverColor",
          defaultValue: "#fff",
          style: {
            selector: ".month-selector--filters-button:hover",
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4725931-design-navigation-filter-toggle-switch-x-7",
            message:
              "Background of toggle switch for events lists and all calendar views.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Toggle active color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filtersToggleActiveColor",
          defaultValue: "#fff",
          style: {
            selector: ".month-selector--filters-button.active",
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4725931-design-navigation-filter-toggle-switch-x-7",
            message:
              "Background of toggle switch for events lists and all calendar views.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Background",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filtersContainerBg",
          defaultValue: "#eaeaea",
          style: {
            selector: ".calendar-filters",
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4725931-design-navigation-filter-toggle-switch-x-7",
            message:
              "Background of toggle switch for events lists and all calendar views.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Selected option",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filterSelectedOptionBg",
          defaultValue: "#eaeaea",
          style: {
            selector: ".calendar-filters--options_selected",
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },

        {
          title: "Selected option active bg",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filterDropdownActiveBG",
          defaultValue: "#a5a5a5",
          style: {
            selector: [
              ".calendar-filters--options_selected.active",
              ".calendar-filters--options_selected.active:hover",
            ],
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Selected option hover bg",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filterDropdownHoveredBG",
          defaultValue: "#d2d2d2",
          style: {
            selector: [
              ".calendar-filters--options .calendar-filters--options_selected:hover",
            ],
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Selected option color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filterSelectedOptionColor",
          defaultValue: "#606060",
          style: {
            selector:
              ".calendar-filters--options .calendar-filters--options_selected",
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Selected option hover color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filterDropdownHoveredColor",
          defaultValue: "#fff",
          style: {
            selector: [
              ".calendar-filters--options .calendar-filters--options_selected:hover",
            ],
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Selected option active color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filterDropdownActiveColor",
          defaultValue: "#fff",
          style: {
            selector: [".calendar-filters--options_selected.active"],
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option background color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filterOptionBgColor",
          defaultValue: "#fff",
          style: {
            selector: [".calendar-filters--options_dropdown p"],
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option background color hover",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filterOptionHoverBgColor",
          defaultValue: "#f7f7f7",
          style: {
            selector: [".calendar-filters--options_dropdown p:hover"],
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option background active color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filterOptionActiveBgColor",
          defaultValue: "#f7f7f7",
          style: {
            selector: [".calendar-filters--options_dropdown p.active"],
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option label color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filterOptionLabelColor",
          defaultValue: "#929292",
          style: {
            selector: [".calendar-filters--options_dropdown p"],
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },

        {
          title: "Option label active color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filterOptionLabelActiveColor",
          defaultValue: "#929292",
          style: {
            selector: [".calendar-filters--options_dropdown p.active"],
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option label color hover",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filterOptionLabelHoverColor",
          defaultValue: "#606060",
          style: {
            selector: [".calendar-filters--options_dropdown p:hover"],
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option border color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filterOptionsBorderColor",
          defaultValue: "#d9d9d9",
          style: [
            {
              selector: [".calendar-filters--options_dropdown p"],
              property: "border-bottom-color",
            },
            {
              selector: [".calendar-filters--options_dropdown"],
              property: "border-color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option border color hover",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filterOptionsBorderHoverColor",
          defaultValue: "#C7C7C7",
          style: [
            {
              selector: [".calendar-filters--options_dropdown p:hover"],
              property: "border-bottom-color",
            },
            {
              selector: [".calendar-filters--options_dropdown:hover"],
              property: "border-color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option accent color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filterOptionAccentColor",
          defaultValue: "#C7C7C7",
          style: [
            {
              selector: [".calendar-filters--options_dropdown p:hover"],
              property: "border-left-color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option selected icon color bg",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filterOptionIconBGColor",
          defaultValue: "#9e9e9e",
          style: [
            {
              selector: [".calendar-filters--options_dropdown p i"],
              property: "background-color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option selected icon color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
          ],
          name: "filterOptionIconColor",
          defaultValue: "#fff",
          style: [
            {
              selector: [".calendar-filters--options_dropdown p i"],
              property: "color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
