/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/04/2022.
 */
import CCBGroupsStrategy from "./GroupsStrategy/CCBGroupsStrategy";
import PCOGroupsStrategy from "./GroupsStrategy/PCOGroupsStrategy";
import { IT } from "../consts";

const selectStrategy = (kind) => {
  switch (kind) {
    case IT.ccb:
      return new CCBGroupsStrategy();
    case IT.pco:
      return new PCOGroupsStrategy();
  }
};

const GroupsItemsMiddleware = (storeAPI) => (next) => (action) => {
  const group = storeAPI.getState().group;

  if (!group.model || !group.model.integration) {
    return next(action);
  }
  const eventStrategy = selectStrategy(group.model.integration.type);

  switch (action.type) {
    case "GROUP_ITEMS_FETCH_FULFILLED":
    case "GROUP_ITEM_FETCH_FULFILLED":
      action.payload.items = eventStrategy.processData(action.payload.items);

      break;
  }

  next(action);
};

export default GroupsItemsMiddleware;
