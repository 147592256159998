/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/08/2022
 */

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const ExpandableAlert = (props) => {
  const [expanded, setExpanded] = useState(false);

  const onExpandClick = useCallback(
    (e) => {
      e.preventDefault();

      setExpanded(!expanded);
    },
    [expanded]
  );

  return (
    <div
      className={classnames(
        "alert word-break-words d-flex alert-expandable",
        `alert-${props.errorLevel}`
      )}
    >
      <p className={"m-r-5 m-b-0"}>
        <i className="material-icons icon-sm">{props.icon}</i>
      </p>
      <div>
        <p className={"m-b-0 m-r-5 d-inline-flex title"}>{props.header}</p>
        {expanded ? <p className={"content"}>{props.body}</p> : null}
        <a
          className={
            "text-secondary d-inline-flex justify-content-between link-read-more"
          }
          href={"#"}
          onClick={onExpandClick}
        >
          <span>Read {expanded ? "less " : "more "}</span>
          <span className={"d-flex"}>
            <i className={"material-icons"}>
              {expanded ? "keyboard_arrow_up" : "keyboard_arrow_down"}
            </i>{" "}
          </span>
        </a>
      </div>
    </div>
  );
};

ExpandableAlert.defaultProps = {};

ExpandableAlert.propTypes = {
  errorLevel: PropTypes.string,
  icon: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  body: PropTypes.object,
};

export default ExpandableAlert;
