/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/10/2023.
 */

import { ST, GT } from "../../../consts";

export const definitions = {
  title: "Frame",
  icon: "crop_square",
  groups: [
    {
      label: "Frame",
      options: [
        {
          title: "Frame color",
          templates: [ST.cardList, ST.cardGrid],
          name: "backgroundColor",
          style: {
            selector: (uuid) => ".signup-background.s" + uuid,
            property: "background-color",
          },
          defaultValue: "#fff",
          tooltip: {
            url: "https://help.display.church/en/articles/4113453-design-frame-and-cells-frame-color",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: true,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Frame width",
          templates: [ST.cardList, ST.cardGrid],
          name: "widgetPadding",
          defaultValue: "20px",
          style: {
            selector: [
              (uuid) => `.dce_signup__${uuid}.signup .dce--${ST.cardGrid}`,
              (uuid) => `.dce_signup__${uuid}.signup .dce--${ST.cardList}`,
            ],
            property: "padding",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4210700-design-frame-and-cells-frame-width-compact-view",
          },
          control: {
            kind: "cssBox",
            options: {
              placeholder: "CSS value for padding",
            },
          },
        },
        {
          title: "Dynamic responsiveness",
          templates: [ST.cardList, ST.cardGrid],
          name: "WAggressiveResp",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6290474-design-frame-and-cells-dynamic-responsiveness",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
  ],
};
