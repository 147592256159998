/**
 * Created by piotr.pozniak@thebeaverhead.com on 10/06/2021.
 */

import { CT } from "../../../consts";

export const definitions = {
  title: "Frame and cells",
  icon: "crop_square",
  groups: [
    {
      label: "Frame",
      options: [
        {
          title: "Frame color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "backgroundColor",
          applicable: "design",
          style: {
            selector: (uuid) => ".calendar-background.c" + uuid,
            property: "background-color",
          },
          defaultValue: "#fff",
          tooltip: {
            url: "https://help.display.church/en/articles/4113453-design-frame-and-cells-frame-color",
            message:
              "Set the background color around your calendar or events list",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: true,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Frame width",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "calendarPadding",
          applicable: "design",
          defaultValue: "15px",
          style: {
            selector: [(uuid) => `.dce_calendar__${uuid}.calendar`],
            property: "padding",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4210700-design-frame-and-cell-frame-width",
            message: "Set the frame width around your calendar or events list.",
          },
          control: {
            kind: "cssBox",
            options: {
              placeholder: "CSS value for padding",
            },
          },
        },

        {
          title: "Dynamic responsiveness",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
          ],
          name: "WAggressiveResp",
          applicable: "design",
          defaultValue: 0,
          tooltip: {
            message:
              "Automatically adapts calendar view to changing device window sizes.",
            url: "https://help.display.church/en/articles/6290474-design-frame-and-cells-aggressive-responsiveness",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Border",
      options: [
        {
          title: "Show border",
          templates: [CT.bubble],
          name: "bcShowBorder",
          defaultValue: 1,
          tooltip: {
            message: "Show / hide border around your entire Tiles view.",
            url: "https://help.display.church/en/articles/8813080-deisgn-frame-and-cells-border",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Border color",
          templates: [CT.bubble],
          name: "bcBorderColor",
          defaultValue: "#C4C4C4",
          style: {
            selector: [".calendar-bubble-view_container--frame"],
            property: "border-color",
          },
          show: [
            {
              field: "bcShowBorder",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            message: "Change the border color.",
            url: "https://help.display.church/en/articles/8813080-deisgn-frame-and-cells-border",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Border width",
          templates: [CT.bubble],
          name: "bcBorderWidth",
          defaultValue: "1px",
          style: {
            selector: [".calendar-bubble-view_container--frame"],
            property: "border-width",
          },
          show: [
            {
              field: "bcShowBorder",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            message: "Change the border width.",
            url: "https://help.display.church/en/articles/8813080-deisgn-frame-and-cells-border",
          },
          control: {
            kind: "cssDimension",
            options: {
              placeholder: "CSS value for border width",
            },
          },
        },
        {
          title: "Border rounding",
          templates: [CT.bubble],
          name: "bcBorderRadius",
          defaultValue: "8px",
          style: {
            selector: [
              ".calendar-bubble-view_container--frame",
              ".calendar-bubble-view_scroll",
            ],
            property: "border-radius",
          },
          show: [
            {
              field: "bcShowBorder",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            message: "Change the amount the border corners are rounded.",
            url: "https://help.display.church/en/articles/8813080-deisgn-frame-and-cells-border",
          },
          control: {
            kind: "cssDimension",
            options: {
              placeholder: "CSS value for border rounding",
            },
          },
        },
      ],
    },
    {
      label: "Cards",
      options: [
        {
          title: "Cards per row",
          templates: [CT.card],
          name: "cwColsCount",
          defaultValue: "1fr 1fr 1fr",
          tooltip: {
            url: "https://help.display.church/en/articles/4895900-frame-and-cells-cards-per-row",
            message: "Card grid view only: Number of cards per row",
          },
          style: [
            {
              selector: [
                ".dce--card_view .card-view",
                ".card-view--top-matches-container",
              ],
              property: "grid-template-columns",
            },
          ],
          control: {
            kind: "number",
            options: {
              min: 1,
              placeholder: "Number of cards per row",
              updateValue: (val) => {
                val[1].value = [...Array(val[1].value * 1).keys()]
                  .map((i) => "1fr")
                  .join(" ");
                return val;
              },
              renderValue: (val) => val.split(" ").length,
            },
          },
        },
        {
          title: "Card margin",
          templates: [CT.card, CT.cardList],
          name: "cwMargin",
          defaultValue: "2%",
          style: [
            {
              selector: [
                ".card-view .card-view--card",
                "h3.card-view--top-matches-container_header",
              ],
              property: "margin",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/4895922-frame-and-cells-card-left-and-right-margin",
            message:
              "Card list/grid views: L/R margins of the cards themselves",
          },
          control: {
            kind: "cssBox",
            options: {
              placeholder: "CSS value for margin",
            },
          },
        },
        {
          title: "Card content padding",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          name: "cwPadding",
          defaultValue: "2em",
          style: [
            {
              selector: ".card-view--card-content",
              property: ["padding"],
            },
          ],
          tooltip: null,
          control: {
            kind: "cssBox",
            options: {
              placeholder: "CSS value for margin",
            },
          },
        },
        {
          title: "Card corner rounding",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          name: "cwBorderRadius",
          defaultValue: "10px",
          style: [
            {
              selector: [
                ".card-view .card-view--card",
                ".card-view-slider .card-slider_card-wrapper",
              ],
              property: "border-radius",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/4895960-frame-and-cells-card-corner-rounding",
            message: "Card list/grid views: How much the corners are rounded",
          },
          control: {
            kind: "cssDimension",
            options: {
              placeholder: "CSS value for corner rounding",
            },
          },
        },
        {
          title: "Card hover effect",
          templates: [CT.card, CT.cardList],
          name: "cwHoverEffect",
          defaultValue: "zoom",
          tooltip: {
            url: "https://help.display.church/en/articles/4794162-frame-and-cells-card-hover-effect",
            message:
              "Card grid view only: What happens to the card during hover",
          },
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "None", value: "none" },
                { label: "Zoom in", value: "zoom" },
                { label: "Simple Accent", value: "accent-simple" },
                { label: "Expandable Accent", value: "accent-expand" },
              ],
            },
          },
        },
        {
          title: "Permanent accent color on the bottom of the card",
          templates: [CT.cardList, CT.card, CT.cardSlider],
          show: [
            {
              field: "cwHoverEffect",
              value: "none",
              defaultValue: "zoom",
            },
            {
              field: "cwHoverEffect",
              value: "zoom",
              defaultValue: "zoom",
              or: true,
            },
          ],
          name: "cwShowAccentBar",
          defaultValue: 0,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Monthly calendar",
      options: [
        {
          title: "Compact view",
          templates: [CT.monthly],
          name: "mcCompactView",
          defaultValue: 0,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Cells color",
          templates: [CT.monthly],
          name: "mcBgColor",
          defaultValue: "#ffffff",
          style: {
            selector: [".full-calendar table td", ".full-calendar table th"],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4314303-design-frame-and-cells-cells-color",
            message: "Full calendar only: color of cells",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Cells border color",
          templates: [CT.monthly],
          name: "mcBorderColor",
          defaultValue: "#f7f7f7",
          style: {
            selector: [
              ".full-calendar table th",
              ".full-calendar table td.day",
            ],
            property: [
              "border-left-color",
              "border-top-color",
              "border-right-color",
              "border-bottom-color",
            ],
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4314498-design-frame-and-cells-cells-border-color",
            message: "Full calendar only: color of cell borders",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Weekly View",
      options: [
        {
          title: "Enforce mobile layout",
          templates: [CT.weekly],
          name: "wwEnforceMobile",
          defaultValue: 0,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
  ],
};
