import { filterAvailableValuesForWidgetType, initBuilder } from "../builder";
import definitions from "../builder/groups/definitions";

const GroupsBuilderMiddleware = (storeAPI) => (next) => (action) => {
  switch (action.type) {
    case "GROUP_UPDATE_FULFILLED":
    case "GROUP_FETCH_FULFILLED":
    case "GROUP_SET":
      if (
        !action.payload ||
        !action.payload.uuid ||
        !action.payload.integration
      ) {
        break;
      }

      const designBuilder = initBuilder(action.payload, definitions);

      action.payload.builder = {
        design: designBuilder,
      };

      action.payload.builder = {
        design: initBuilder(action.payload, definitions),
      };

      action.payload.widget_settings = {
        ...action.payload.widget_settings,
        design: filterAvailableValuesForWidgetType(
          action.payload.widget_settings.design || {},
          action.payload.builder.design.values
        ),
      };

      break;
  }

  next(action);
};

export default GroupsBuilderMiddleware;
