/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */

const initialState = {
  collection: [],

  fetch: false,
  fetchError: null,
  fetchSuccess: null,

  page: 1,
  totalRows: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "GROUP_ITEMS_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "GROUP_ITEMS_FETCH_FULFILLED":
      const items = action.preservePreviousEvents
        ? state.collection.concat(action.payload.items)
        : action.payload.items;

      return {
        ...state,
        collection: items,
        page: action.payload.pagination ? action.payload.pagination.page : 0,
        totalRows: action.payload.pagination
          ? action.payload.pagination.count
          : items.length,
        fetch: false,
        fetchSuccess: true,
        fetchError: false,
      };

    case "GROUP_ITEMS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: true,
        collection: action.preservePreviousEvents ? state.collection : [],
      };

    case "GROUP_ITEMS_COLLECTION_RESET":
      return {
        ...initialState,
      };

    default:
      return state;
  }

  return state;
}
