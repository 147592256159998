/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/08/2022.
 */

import { CT, IT } from "../../../consts";

const boxShadowOptions = [
  { label: "None", value: "none" },
  {
    label: "Light",
    value: "0px 4px 8px 0px rgba(0, 0, 0, 0.17)",
  },
  {
    label: "Strong",
    value: "0px 7px 11px 0px rgba(0, 0, 0, 0.27)",
  },
];

export const definitions = {
  title: "Card's styles",
  icon: "brush",
  groups: [
    {
      label: "Card",
      options: [
        {
          title: "Card's image container color",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.ccb, IT.google, IT.breeze],
          name: "cardImgContainerColor",
          defaultValue: "#b476c9",
          style: {
            selector: [".card-view--card-header-image"],
            property: "background-color",
          },
          dependency: [
            {
              field: "generalUseEventAccent",
              value: 0,
              defaultValue: 1,
              message:
                'This option is overwritten by "Use calendar color as accent color" option',
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Card's image container color",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.pco],
          name: "cardImgContainerColor",
          defaultValue: "#b476c9",
          style: {
            selector: [".card-view--card-header-image"],
            property: "background-color",
          },
          dependency: [
            {
              field: "generalUseEventAccent",
              value: 0,
              defaultValue: 1,
              message:
                'This option is overwritten by "Use tag color as accent color" option',
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Background color",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          name: "cardBgColor",
          defaultValue: "#fff",
          style: {
            selector: [".card-view--card", ".card-slider_card-wrapper"],
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Drop shadow",
          templates: [CT.cardSlider],
          name: "carBoxShadow",
          defaultValue: "0px 4px 8px 0px rgba(0, 0, 0, 0.17)",
          style: {
            selector: [".card-slider_card-wrapper"],
            property: "box-shadow",
          },
          tooltip: null,
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: boxShadowOptions,
            },
          },
        },
        {
          title: "Drop shadow",
          templates: [CT.card, CT.cardList],
          name: "carBoxShadow",
          defaultValue: "0px 4px 8px 0px rgba(0, 0, 0, 0.17)",
          style: {
            selector: [".card-view--card"],
            property: "box-shadow",
          },
          tooltip: null,
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: boxShadowOptions,
            },
          },
        },

        {
          title: "Event title",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          name: "cardEventTitleColor",
          defaultValue: "#333",
          style: {
            selector: [".card-view--card-content-body h2"],
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Event details",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          name: "cardDetailsColor",
          defaultValue: "#333",
          style: [
            {
              selector: ".card-view--card-content-body p",
              property: "color",
            },
          ],

          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Event date",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          name: "cardDateColor",
          defaultValue: "#333",
          style: [
            {
              selector:
                ".card-view--card-content-body p.card-view--card-content-date",
              property: "color",
            },
          ],

          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Card Primary call to action",
      options: [
        {
          title: "Button color",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          name: "cCardPCTA1stBtn",
          defaultValue: "#00aafb",
          style: {
            selector: [".dce--event-card--cta-btn"],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/8443017-calendar-calls-to-action-styling",
          },
          show: [
            {
              field: "cCardShowCta1st",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Button color on hover",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          name: "cCardPCTA1stBtnHover",
          defaultValue: "#1ac4ff",
          style: {
            selector: [
              ".dce--event-card--cta-btn:hover:hover",
              ".dce--event-card--cta-btn:hover:active",
              ".dce--event-card--cta-btn:hover:focus",
            ],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/8443017-calendar-calls-to-action-styling",
          },
          show: [
            {
              field: "cCardShowCta1st",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          name: "cCardPCTA1stBtnLabel",
          defaultValue: "#fff",
          style: {
            selector: [".dce--event-card--cta-btn"],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/8443017-calendar-calls-to-action-styling",
          },
          show: [
            {
              field: "cCardShowCta1st",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Text color on hover",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          name: "cCardPCTA1stBtnLabelHover",
          defaultValue: "#fff",
          style: {
            selector: [
              ".dce--event-card--cta-btn:hover",
              ".dce--event-card--cta-btn:active",
              ".dce--event-card--cta-btn:focus",
            ],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/8443017-calendar-calls-to-action-styling",
          },
          show: [
            {
              field: "cCardShowCta1st",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
