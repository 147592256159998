

/**
 *
 * @param str
 * @returns {string}
 */
export function ucWords(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(" ");
}

/**
 *
 * @param str
 * @returns {string}
 */
export function ucFirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}


/**
 *
 * @param str
 * @returns {string}
 */
export function lcFirst(str) {
  return str.charAt(0).toLowerCase() + str.slice(1);
}


/**
 *
 * @param s
 * @param mask
 * @returns {*}
 */
export function customTrim(s, mask) {
  while (~mask.indexOf(s[0])) {
    s = s.slice(1);
  }
  while (~mask.indexOf(s[s.length - 1])) {
    s = s.slice(0, -1);
  }
  return s;
}
