/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/07/2022.
 */

import { GT, IT } from "../../../consts";
import CTA_OPTIONS from "./cta_options";

export const definitions = {
  title: "Mosaic",
  icon: "auto_awesome_mosaic",
  groups: [
    {
      label: "Main information",
      options: [
        {
          title: "Show group image if available",
          templates: [GT.mosaic],
          name: "gSImageMain",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/Hide the group image (if there is one).",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },

        {
          title: "Show group name",
          templates: [GT.mosaic],
          name: "gMShowName",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show group description",
          templates: [GT.mosaic],
          name: "gSDescriptionCard",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Effects",
      options: [
        {
          title: "Hover effect",
          templates: [GT.mosaic],
          name: "gMHoverEffect",
          defaultValue: "dce--mosaic_effect-slide",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "What happens when you hover over a group.",
          },
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "None", value: "dce--mosaic_effect-none" },
                { label: "Slide", value: "dce--mosaic_effect-slide" },
                { label: "Flip", value: "dce--mosaic_effect-flip" },
              ],
            },
          },
        },
        {
          title: "Group name horizontal position",
          templates: [GT.mosaic],
          name: "gGroupNameHPos",
          defaultValue: "flex-end",
          style: {
            selector: [".dce--mosaic-tile-header"],
            property: "align-items",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Placement of group name from left to right.",
          },
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "Left", value: "flex-start" },
                { label: "Center", value: "center" },
                { label: "Right", value: "flex-end" },
              ],
            },
          },
        },
        {
          title: "Group name vertical position",
          templates: [GT.mosaic],
          name: "gGroupNameVPos",
          defaultValue: "flex-end",
          style: {
            selector: [".dce--mosaic-tile-header"],
            property: "justify-content",
          },
          show: [
            {
              field: "gMHoverEffect",
              value: "dce--mosaic_effect-none",
              defaultValue: "dce--mosaic_effect-slide",
            },
            {
              field: "gMHoverEffect",
              value: "dce--mosaic_effect-flip",
              defaultValue: "dce--mosaic_effect-slide",
              or: true,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Placement of group name from top to bottom.",
          },
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "Top", value: "flex-start" },
                { label: "Middle", value: "center" },
                { label: "Bottom", value: "flex-end" },
              ],
            },
          },
        },
      ],
    },
    {
      label: "Primary call-to-action",
      options: [
        {
          title: "Show CTA button",
          templates: [GT.mosaic],
          name: "gMShowCta1st",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide first of two CTA buttons.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA action",
          templates: [GT.mosaic],
          name: "gMCTAAction1st",
          defaultValue: "groupPage",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "What clicking this button will do.",
          },
          show: [
            {
              field: "gMShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_OPTIONS,
            },
          },
        },
        {
          title: "Use default CTA button label",
          templates: [GT.mosaic],
          name: "gM1stDefaultCTALabel",
          defaultValue: 1,
          show: [
            {
              field: "gMShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Use (or not) the default text: Join group.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA button label",
          templates: [GT.mosaic],
          name: "gM1stCTALabel",
          defaultValue: "Join group",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Instead of the default, what your CTA button will say.",
          },
          show: [
            {
              field: "gM1stDefaultCTALabel",
              value: 0,
              defaultValue: 1,
            },
            {
              field: "gMShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "text",
            options: {
              placeholder: "CTA button label",
            },
          },
        },
        {
          title: "Subject line",
          templates: [GT.mosaic],
          name: "gMCTASubject1st",
          defaultValue: "Join Your Small Group",
          show: [
            {
              field: "gMCTAAction1st",
              value: "contactLeader",
              defaultValue: "groupPage",
            },
            {
              field: "gMShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Message",
          templates: [GT.mosaic],
          name: "gMCTAMessage1st",
          defaultValue:
            "Hello! I am interested in joining your small group. Can I get more information? Thank you!",
          show: [
            {
              field: "gMCTAAction1st",
              value: "contactLeader",
              defaultValue: "groupPage",
            },
            {
              field: "gMShowCta1st",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "textarea",
            options: {},
          },
        },
      ],
    },
    {
      label: "Secondary call-to-action",
      options: [
        {
          title: "Show CTA button",
          templates: [GT.mosaic],
          name: "gMShowCta2nd",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide second of two CTA buttons.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA action",
          templates: [GT.mosaic],
          name: "gMCTAAction2nd",
          defaultValue: "groupPage",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "What clicking this button will do.",
          },
          show: [
            {
              field: "gMShowCta2nd",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_OPTIONS,
            },
          },
        },
        {
          title: "Use default CTA button label",
          templates: [GT.mosaic],
          name: "gM2ndDefaultCTALabel",
          defaultValue: 1,
          show: [
            {
              field: "gMShowCta2nd",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Use (or not) the default text: Join group.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA button label",
          templates: [GT.mosaic],
          name: "gM2ndCTALabel",
          defaultValue: "Join group",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Instead of the default, what your CTA button will say.",
          },
          show: [
            {
              field: "gM2ndDefaultCTALabel",
              value: 0,
              defaultValue: 1,
            },
            {
              field: "gMShowCta2nd",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "text",
            options: {
              placeholder: "CTA button label",
            },
          },
        },
        {
          title: "Subject line",
          templates: [GT.mosaic],
          name: "gMCTASubject2nd",
          defaultValue: "Join Your Small Group",
          show: [
            {
              field: "gMCTAAction2nd",
              value: "contactLeader",
              defaultValue: "groupPage",
            },
            {
              field: "gMShowCta2nd",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Message",
          templates: [GT.mosaic],
          name: "gMCTAMessage2nd",
          defaultValue:
            "Hello! I am interested in joining your small group. Can I get more information? Thank you!",
          show: [
            {
              field: "gMCTAAction2nd",
              value: "contactLeader",
              defaultValue: "groupPage",
            },
            {
              field: "gMShowCta2nd",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "textarea",
            options: {},
          },
        },
      ],
    },
  ],
};
