/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/10/2018.
 */

const initialState = {
  collection: [],
  pagination: {
    count: 0,
    page: 1,
    perPage: 50,
  },

  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  delete: false,
  deleteSuccess: false,
  deleteError: null,

  update: false,
  updateSuccess: false,
  updateError: null,

  activate: false,
  activateSuccess: false,
  activateError: null,

  terminateSubscriptionUserUUID: null,
  terminateSubscription: false,
  terminateSubscriptionSuccess: false,
  terminateSubscriptionError: null,

  filters: {
    email: "",
    name: "",
  },
};

export default function reducer(
  state = { ...initialState, collection: [] },
  action
) {
  switch (action.type) {
    case "ORGANIZATIONS_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: false,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          perPage: action.payload.ipp,
        },
        filters: {
          name: action.payload.name,
          email: action.payload.email,
        },
      };

    case "ORGANIZATIONS_FETCH_FULFILLED":
      return {
        ...state,
        collection: action.payload.organizations,
        pagination: action.payload.pagination,

        fetch: false,
        fetchSuccess: true,
      };

    case "ORGANIZATIONS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.payload,
      };
    /*

    case "USER_DELETE":
      return {
        ...state,
        delete: true,
        deleteSuccess: false,
        deleteError: null,
      };

    case "USER_DELETE_FULFILLED":
      return {
        ...state,
        delete: false,
        deleteSuccess: true,
        collection: state.collection.filter(
          (i) => i.uuid != action.payload.uuid
        ),
      };

    case "USER_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteError: action.payload,
      };

    case "USER_EDIT":
      return {
        ...state,
        update: true,
        updateSuccess: false,
        updateError: null,
      };

    case "USER_EDIT_FULFILLED":
      const userIndex = state.collection.findIndex(
        (i) => i.uuid == action.payload.uuid
      );

      if (userIndex < 0) {
        return state;
      }

      return {
        ...state,
        updateSuccess: true,
        update: false,
        collection: state.collection.map((i) =>
          i.uuid == action.payload.uuid ? action.payload : i
        ),
      };

    case "USER_EDIT_REJECTED":
      return {
        ...state,
        update: false,
        updateError: action.payload,
      };

    case "USER_ACTIVATE":
      return {
        ...state,
        activate: true,
        activateSuccess: false,
        activateError: null,
      };

    case "USER_ACTIVATE_FULFILLED": {
      const userIndex = state.collection.findIndex(
        (i) => i.uuid == action.payload.uuid
      );

      if (userIndex < 0) {
        return state;
      }

      return {
        ...state,
        activate: false,
        activateSuccess: true,
        collection: state.collection.map((i) =>
          i.uuid == action.payload.uuid ? action.payload : i
        ),
      };
    }

    case "USER_ACTIVATE_REJECTED":
      return {
        ...state,
        activate: false,
        activateError: action.payload,
      };

    case "ADMIN_SUBSCRIPTION_TERMINATE":
      return {
        ...state,
        terminateSubscriptionUserUUID: action.uuid,
        terminateSubscription: true,
        terminateSubscriptionSuccess: false,
        terminateSubscriptionError: null,
      };

    case "ADMIN_SUBSCRIPTION_TERMINATE_FULFILLED":
      return {
        ...state,
        terminateSubscriptionUserUUID: null,
        terminateSubscription: false,
        terminateSubscriptionSuccess: true,
      };

    case "ADMIN_SUBSCRIPTION_TERMINATE_REJECTED":
      return {
        ...state,
        terminateSubscription: false,
        terminateSubscriptionError: action.payload,
      };
*/

    case "USER_LOGOUT":
      return { ...initialState, collection: [] };

    default:
      return state;
  }

  return state;
}
