/**
 * Created by piotr.pozniak@thebeaverhead.com on 10/06/2021.
 */

import { CT } from "../../../consts";

export const definitions = {
  title: "Days and dates",
  icon: "event",
  groups: [
    {
      label: "Behavior",
      options: [
        {
          title: "First day of week",
          templates: [CT.monthly],
          name: "firstDayOfWeek",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/4334528-days-and-dates-first-day-of-week",
            message: "Full calendar only: choose which day begins your week",
          },
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "Sunday", value: 0 },
                { label: "Monday", value: 1 },
                { label: "Tuesday", value: 2 },
                { label: "Wednesday", value: 3 },
                { label: "Thursday", value: 4 },
                { label: "Friday", value: 5 },
                { label: "Saturday", value: 6 },
              ],
            },
          },
        },
        {
          title: "Limit number of calendar pills displayed",
          templates: [CT.monthly],
          name: "isEventsLimited",
          defaultValue: 1,
          tooltip: {
            message: "Turn on to limit events displayed on calendar day",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Number of pills to be displayed",
          templates: [CT.monthly],
          name: "limitedNumberOfEvents",
          defaultValue: 3,
          tooltip: {
            message: "Number of events to be displayed on calendar day",
          },
          control: {
            kind: "number",
            options: {},
          },
          show: [
            {
              field: "isEventsLimited",
              value: 1,
              defaultValue: 1,
            },
          ],
        },
      ],
    },
    {
      label: "Look & Feel",
      options: [
        {
          title: "Day name color",
          templates: [CT.monthly],
          name: "mcWeekDayColor",
          defaultValue: "#9e9e9e",
          style: {
            selector: ".full-calendar table th",
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4334566-days-and-dates-day-name-color",
            message: "Full calendar only: days of the week color",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Day name hover color",
          templates: [CT.monthly],
          name: "mcWeekDayHoverColor",
          defaultValue: "#d4d4d4",
          style: {
            selector: ".full-calendar table th:hover",
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4334627-days-and-dates-day-name-hover-color",
            message:
              "Full calendar only: days of the week hover (mouseover) color",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Date color",
          templates: [CT.monthly],
          name: "mcDayColor",
          defaultValue: "#d8d8d8",
          style: {
            selector: [".full-calendar table td"],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4373087-days-and-dates-date-color",
            message: "Full calendar only: day dates in calendar color",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Date hover color",
          templates: [CT.monthly],
          name: "mcHoverDay",
          defaultValue: "#9e9e9e",
          style: {
            selector: ".full-calendar table td:hover .day-label",
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4373122-days-and-dates-day-s-date-color",
            message:
              "Full calendar only: specific day date hover (mouseover) color",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Date background hover color",
          templates: [CT.monthly],
          name: "mcHoverDayBg",
          defaultValue: "#f5f5f5",
          style: {
            selector: [
              ".full-calendar table td:hover",
              ".full-calendar table td.hover",
            ],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4418558-design-days-and-dates-date-background-hover-color",
            message:
              "Full calendar only: specific day date background hover (mouseover) color",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Current day accent",
          templates: [CT.monthly],
          name: "mcCurrentDayAccentColor",
          defaultValue: "#ef9a93",
          style: {
            selector: ".full-calendar .current-day::after",
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4421739-design-days-and-dates-current-and-selected-day-accents",
            message:
              "Full calendar only: color of the accent bar on today's date",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Selected day accent",
          templates: [CT.monthly],
          name: "mcSelectedDayAccentColor",
          defaultValue: "#9e9e9e",
          style: {
            selector: ".full-calendar table td.selected::before",
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4421739-design-days-and-dates-current-and-selected-day-accents",
            message:
              "Full calendar only: color of the accent bar on a date of interest to you",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Selected day color",
          templates: [CT.monthly],
          name: "mcSelectedDayColor",
          defaultValue: "#d4d4d4",
          style: {
            selector: ".full-calendar table td.selected .day-label",
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4422257-design-days-and-dates-selected-day-color",
            message:
              "Full calendar only: date color (the number) on a date of interest to you",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Selected day highlight",
          templates: [CT.monthly],
          name: "mcSelectedDayBgColor",
          defaultValue: "#fff3e7",
          style: {
            selector: ".full-calendar table td.selected",
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/4422788-design-days-and-dates-selected-day-highlight",
            message:
              "Full calendar only: color of specific cell-current date or selected date",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
