/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/06/2021.
 */

import { CT, IT } from "../../../consts";

export const definitions = {
  title: "AI Search",
  icon: "auto_awesome",
  groups: [
    {
      label: "Behavior",
      options: [
        {
          title: "Enable AI Search",
          templates: [],
          integrationSettings: {
            create_embeddings: true,
          },
          name: "aiEnabled",
          defaultValue: 0,
          show: [],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show events initially",
          templates: [
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.weekly,
            CT.monthly,
            CT.eventList,
            CT.detailedList,
          ],
          name: "aiPrefetchEvents",
          defaultValue: 1,
          show: [
            {
              field: "aiEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        /*{
          title: "Layout",
          templates: [
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.weekly,
            CT.monthly,
            CT.eventList,
          ],
          name: "aiLayout",
          defaultValue: "input",
          show: [
            {
              field: "aiEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "Input above events", value: "input" },
              ],
            },
          },
        },*/
        {
          title: "Placeholder",
          templates: [
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.weekly,
            CT.monthly,
            CT.eventList,
            CT.detailedList,
          ],
          name: "aiPlaceholder",
          defaultValue: "Search for events...",
          show: [
            {
              field: "aiEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "text",
            options: {},
          },
        },
      ],
    },
    {
      label: "Suggestions",
      options: [
        {
          title: "Show suggestions",
          templates: [
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.weekly,
            CT.monthly,
            CT.eventList,
            CT.detailedList,
          ],
          name: "aiShowSuggestions",
          defaultValue: 1,
          show: [
            {
              field: "aiEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Suggestions (semi-colon separated)",
          templates: [
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.weekly,
            CT.monthly,
            CT.eventList,
            CT.detailedList,
          ],
          name: "aiSuggestions",
          defaultValue:
            "events this week; events for kids; the last week of this month",
          show: [
            {
              field: "aiEnabled",
              value: 1,
              defaultValue: 0,
            },
            {
              field: "aiShowSuggestions",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "textarea",
            options: {},
          },
        },
      ],
    },
    {
      label: "Results",
      options: [
        {
          title: "Best matches label",
          templates: [CT.card, CT.cardList],
          name: "aiBestMatchesLabel",
          defaultValue: "Best matches",
          control: {
            kind: "text",
            options: {},
          },
          show: [
            {
              field: "aiEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
        },
        {
          title: "Remaining search results label",
          templates: [CT.card, CT.cardList],
          name: "aiRemainingResultsLabel",
          defaultValue: "Events you might be interested in",
          control: {
            kind: "text",
            options: {},
          },
          show: [
            {
              field: "aiEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
        },
      ],
    },
    /*{
      label: "UI",
      options: [
        {
          title: "Input size",
          templates: [
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.weekly,
            CT.monthly,
            CT.eventList,
          ],
          name: "aiInputSize",
          defaultValue: "1.2em",
          show: [
            {
              field: "aiEnabled",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "Extra small", value: "0.9em" },
                { label: "Small", value: "1.2em" },
                { label: "Medium", value: "1.5em" },
                { label: "Large", value: "2em" },
                { label: "Extra Large", value: "2.5em" },
              ],
            },
          },
        },
      ],
    },*/
  ],
};
