/**
 * Created by piotr.pozniak@thebeaverhead.com on 29/06/2022.
 */

import { GT, IT } from "../../../consts";

export const definitions = {
  title: "Headers and links",
  icon: "title",
  groups: [
    /*    {
      label: "Header",
      options: [
        {
          title: "Show filter names",
          templates: [GT.twoColumn, GT.card, GT.outlined, GT.mosaic],
          name: "filterNamesHeader",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/5988390-design-headers-and-links-show-filter-names-filters",
            message: "Display selected filters names for your group widget",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },*/
    {
      label: "Links",
      options: [
        {
          title: "Open links in new tab",
          templates: [GT.twoColumn, GT.card, GT.outlined, GT.mosaic],
          name: "openLinksNewTab",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/4472088-design-headers-and-links-open-links-in-new-tab",
            message: "Open group description list link(s) in a new tab.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Filters",
      options: [
        {
          title: "Group type selector",
          templates: [GT.twoColumn, GT.card, GT.outlined, GT.mosaic],
          integrations: [IT.ccb, IT.pco],
          name: "showgroup_typeDropdown",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide filter.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },

        {
          title: "Campuses selector",
          templates: [GT.twoColumn, GT.card, GT.outlined, GT.mosaic],
          integrations: [IT.ccb],
          name: "showcampusDropdown",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide filter.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },

        {
          title: "Departments selector",
          templates: [GT.twoColumn, GT.card, GT.outlined, GT.mosaic],
          integrations: [IT.ccb],
          name: "showdepartmentDropdown",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide filter.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Area selector",
          templates: [GT.twoColumn, GT.card, GT.outlined, GT.mosaic],
          integrations: [IT.ccb],
          name: "showareaDropdown",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide filter.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Demographic selector",
          templates: [GT.twoColumn, GT.card, GT.outlined, GT.mosaic],
          integrations: [IT.ccb],
          name: "showudfDropdown",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide filter.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Meeting day selector",
          templates: [GT.twoColumn, GT.card, GT.outlined, GT.mosaic],
          integrations: [IT.ccb],
          name: "showmeeting_dayDropdown",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Meeting time selector",
          templates: [GT.twoColumn, GT.card, GT.outlined, GT.mosaic],
          integrations: [IT.ccb],
          name: "showmeeting_timeDropdown",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Childcare selector",
          templates: [GT.twoColumn, GT.card, GT.outlined, GT.mosaic],
          integrations: [IT.ccb],
          name: "showchildcareDropdown",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Registration status",
          templates: [GT.twoColumn, GT.card, GT.outlined, GT.mosaic],
          integrations: [IT.ccb],
          name: "showregistration_statusDropdown",
          defaultValue: 0,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Group status",
          templates: [GT.twoColumn, GT.card, GT.outlined, GT.mosaic],
          integrations: [IT.pco],
          name: "showgroup_statusDropdown",
          defaultValue: 0,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Location selector",
          templates: [GT.twoColumn, GT.card, GT.outlined, GT.mosaic],
          integrations: [IT.pco],
          name: "showlocationDropdown",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide filter.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
  ],
};
