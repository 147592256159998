const initialState = {
  collection: [],
  coupon: null,

  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  update: false,
  updateSuccess: false,
  updateError: null,

  delete: false,
  deleteSuccess: false,
  deleteError: null,
};

export default function reducer(
  state = { ...initialState, collection: [] },
  action
) {
  switch (action.type) {
    case "SUBSCRIPTION_PLAN_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "SUBSCRIPTION_PLAN_FETCH_FULFILLED":
      return {
        ...state,
        collection: action.payload.subscription_plans,
        coupon: action.payload.coupon || null,
        fetch: false,
        fetchSuccess: true,
      };

    case "SUBSCRIPTION_PLAN_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.payload,
      };

    case "SUBSCRIPTION_PLAN_UPDATE":
      return {
        ...state,
        update: true,
        updateSuccess: false,
        updateError: false,
      };

    case "SUBSCRIPTION_PLAN_UPDATE_FULFILLED":
      const couponIndex = state.collection.findIndex(
        (i) => i.uuid == action.payload.uuid
      );

      if (couponIndex >= 0) {
        state.collection.splice(couponIndex, 1, action.payload);
      } else {
        state.collection.push(action.payload);
      }

      return {
        ...state,
        collection: [...state.collection],
        total: state.total + 1,
        update: false,
        updateSuccess: true,
      };

    case "SUBSCRIPTION_PLAN_UPDATE_REJECTED":
      return {
        ...state,
        update: false,
        updateError: action.payload,
      };

    case "SUBSCRIPTION_PLAN_DELETE":
      return {
        ...state,
        delete: true,
        deleteSuccess: false,
        deleteError: null,
      };

    case "SUBSCRIPTION_PLAN_DELETE_FULFILLED":
      return {
        ...state,
        collection: state.collection.filter(
          (i) => i.uuid != action.payload.uuid
        ),
        delete: false,
        deleteSuccess: true,
      };

    case "SUBSCRIPTION_PLAN_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteSuccess: false,
        deleteError: action.payload,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        collection: state.collection,
      };

    default:
      return state;
  }

  return state;
}
