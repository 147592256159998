/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/04/2022.
 */

import { GT } from "../../../consts";

export const definitions = {
  title: "Frame",
  icon: "crop_square",
  groups: [
    {
      label: "Frame",
      options: [
        {
          title: "Frame color",
          templates: [GT.card, GT.twoColumn, GT.outlined, GT.mosaic],
          name: "backgroundColor",
          style: {
            selector: (uuid) => ".groups-background.g" + uuid,
            property: "background-color",
          },
          defaultValue: "#fff",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Set the background color around your group view.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: true,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Frame width",
          templates: [GT.card, GT.twoColumn, GT.outlined, GT.mosaic],
          name: "widgetPadding",
          defaultValue: "20px",
          style: {
            selector: [
              (uuid) => `.dce_groups__${uuid}.groups .dce--${GT.twoColumn}`,
              (uuid) => `.dce_groups__${uuid}.groups .dce--${GT.card}`,
              (uuid) => `.dce_groups__${uuid}.groups .dce--${GT.outlined}`,
              (uuid) => `.dce_groups__${uuid}.groups .dce--${GT.mosaic}`,
            ],
            property: "padding",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Set the amount of space around your group view.",
          },
          control: {
            kind: "cssBox",
            options: {
              placeholder: "CSS value for padding",
            },
          },
        },
        {
          title: "Dynamic responsiveness",
          templates: [GT.twoColumn, GT.card, GT.outlined, GT.mosaic],
          name: "WAggressiveResp",
          defaultValue: 1,
          tooltip: {
            message:
              "Automatically adapts widget view to changing device window sizes.",
            url: "https://help.display.church/en/articles/6290474-design-frame-and-cells-aggressive-responsiveness",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Cards",
      options: [
        {
          title: "Cards per row",
          templates: [GT.card, GT.outlined],
          name: "cwColsCount",
          defaultValue: "1fr 1fr 1fr",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Number of cards per row.",
          },
          style: [
            {
              selector: [".dce--groups-cards", ".dce--outlined"],
              property: "grid-template-columns",
            },
          ],
          control: {
            kind: "number",
            options: {
              min: 1,
              placeholder: "Number of cards per row",
              updateValue: (val) => {
                val[1].value = [...Array(val[1].value * 1).keys()]
                  .map((i) => "1fr")
                  .join(" ");
                return val;
              },
              renderValue: (val) => val.split(" ").length,
            },
          },
        },
        {
          title: "Card margin",
          templates: [GT.card],
          name: "cwPadding",
          defaultValue: "2%",
          style: [
            {
              selector: ".dce--groups-cards .dce--groups-cards-card",
              property: "margin",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "L/R margins of the cards themselves.",
          },

          control: {
            kind: "cssBox",
            options: {
              placeholder: "CSS value for margin",
            },
          },
        },
        {
          title: "Card corner rounding",
          templates: [GT.card],
          name: "cwBorderRadius",
          defaultValue: "10px",
          style: [
            {
              selector: ".dce--groups-cards .dce--groups-cards-card",
              property: "border-radius",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "How much the corners are rounded.",
          },
          control: {
            kind: "cssDimension",
            options: {
              placeholder: "CSS value for corner rounding",
            },
          },
        },
        {
          title: "Card hover effect",
          templates: [GT.card],
          name: "cwHoverEffect",
          defaultValue: "zoom",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "What happens when you hover on a card.",
          },
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "None", value: "none" },
                { label: "Zoom in", value: "zoom" },
                { label: "Simple Accent", value: "accent-simple" },
                { label: "Expandable Accent", value: "accent-expand" },
              ],
            },
          },
        },
      ],
    },
  ],
};
