import { GT } from "../../../consts";

const showConditionsEvent = [
  {
    field: "gPopupShareShow",
    value: 1,
    defaultValue: 1,
  },
];

export const definitions = {
  title: "Sharing",
  icon: "share",
  groups: [
    {
      label: "Share Group",
      options: [
        {
          title: "Show share options in group details",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "gPopupShareShow",
          defaultValue: 1,
          style: null,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Share link",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "sharingItemColor",
          defaultValue: "#808080",
          show: showConditionsEvent,
          style: [
            {
              selector: [".dce-groups-modal-row--details-row .share-event.share-event--link"],
              property: "color",
            },
          ],
          tooltip: {
            url: "http://help.display.church/en/articles/9203703-display-church-styling-your-individual-event-sharing-button",
            message: "Color of share link in description",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Share link hover",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "sharingItemHoveredColor",
          defaultValue: "#fff",
          show: showConditionsEvent,
          style: [
            {
              selector: [
                ".dce-groups-modal-row--details-row .share-event.share-event--link:hover",
              ],
              property: "color",
            },
          ],
          tooltip: {
            url: "http://help.display.church/en/articles/9203703-display-church-styling-your-individual-event-sharing-button",
            message: "Color of share link on mouse over",
          },
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Share link background hover",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "sharingItemHoveredBgColor",
          defaultValue: "#9e9e9e",
          show: showConditionsEvent,
          style: [
            {
              selector: [
                ".dce-groups-modal-row--details-row .share-event.share-event--link:hover",
              ],
              property: "background-color",
            },
          ],
          tooltip: {
            url: "http://help.display.church/en/articles/9203703-display-church-styling-your-individual-event-sharing-button",
            message: "Color of circle around share link on mouse over",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Popup design",
      options: [
        {
          title: "Background color",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "sharePopupBgColor",
          defaultValue: "#fff",
          show: showConditionsEvent,
          style: {
            selector: ".dc-share-popup--card",
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Card title color",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "sharePopupTitleColor",
          defaultValue: "#000",
          show: showConditionsEvent,
          style: {
            selector: [".dc-share-popup--card-section--header"],
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Source color",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "sharePopupSourceColor",
          defaultValue: "#000",
          show: showConditionsEvent,
          style: {
            selector: ".dc-share-popup--card-section--subheader",
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Separator color",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "sharePopupSeparatorColor",
          defaultValue: "#e0e0e0",
          show: showConditionsEvent,
          style: {
            selector: ".dc-share-popup--card-section",
            property: "border-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Close button color",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "sharePopupCloseButtonColor",
          defaultValue: "#333",
          show: showConditionsEvent,
          style: {
            selector: [
              ".dc-share-popup--card--close::before",
              ".dc-share-popup--card--close::after",
            ],
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Close button color on hover",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "sharePopupCloseButtonColorOnHover",
          defaultValue: "#333",
          show: showConditionsEvent,
          style: {
            selector: [
              ".dc-share-popup--card--close:hover::before",
              ".dc-share-popup--card--close:hover::after",
            ],
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "QR Code",
      options: [
        {
          title: "Show QR code",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "popupQrCode",
          defaultValue: 1,
          show: showConditionsEvent,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "QR code background color",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "qrCodeBgColor",
          defaultValue: "#fff",
          show: [
            ...showConditionsEvent,
            {
              field: "popupQrCode",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "QR code foreground color",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "qrCodeFgColor",
          defaultValue: "#000",
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: [
            ...showConditionsEvent,
            {
              field: "popupQrCode",
              value: 1,
              defaultValue: 1,
            },
          ],
        },
        {
          title: "QR code border color",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "qrCodeBorderColor",
          defaultValue: "#e0e0e0",
          style: {
            selector: ".dc-share-popup--card-section--qr svg",
            property: "border-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: [
            ...showConditionsEvent,
            {
              field: "popupQrCode",
              value: 1,
              defaultValue: 1,
            },
          ],
        },
      ],
    },
    {
      label: "Social buttons",
      options: [
        {
          title: "Show share on facebook",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "shareFacebookShow",
          defaultValue: 1,
          show: showConditionsEvent,
          control: {
            kind: "checkbox",
            options: {},
          },
        },

        {
          title: "Show share on X",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "shareXButtonShow",
          defaultValue: 1,
          show: showConditionsEvent,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Shareable URL",
      options: [
        {
          title: "Show share link",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "popupShareLink",
          defaultValue: 1,
          show: showConditionsEvent,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Link background color",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "popupLinkBgColor",
          defaultValue: "#fff",
          style: {
            selector: ".dc-share-popup--link-input",
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: [
            ...showConditionsEvent,
            {
              field: "popupShareLink",
              value: 1,
              defaultValue: 1,
            },
          ],
        },
        {
          title: "Link color",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "popupLinkColor",
          defaultValue: "#000",
          style: {
            selector: ".dc-share-popup--link-input_value",
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: [
            ...showConditionsEvent,
            {
              field: "popupShareLink",
              value: 1,
              defaultValue: 1,
            },
          ],
        },
        {
          title: "Link border color",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "popupLinkBorderColor",
          defaultValue: "#e0e0e0",
          style: {
            selector: ".dc-share-popup--link-input",
            property: "border-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: [
            ...showConditionsEvent,
            {
              field: "popupShareLink",
              value: 1,
              defaultValue: 1,
            },
          ],
        },
        {
          title: "Copy button color",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "popupCopyButtonColor",
          defaultValue: "#2646CB",
          style: {
            selector: ".dc-share-popup--link-input_button",
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: [
            ...showConditionsEvent,
            {
              field: "popupShareLink",
              value: 1,
              defaultValue: 1,
            },
          ],
        },
        {
          title: "Copy button color on hover",
          templates: [GT.mosaic, GT.twoColumn, GT.card, GT.outlined],
          name: "popupCopyButtonColorOnHover",
          defaultValue: "#1a2f8c",
          style: {
            selector: ".dc-share-popup--link-input_button:hover",
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: [
            ...showConditionsEvent,
            {
              field: "popupShareLink",
              value: 1,
              defaultValue: 1,
            },
          ],
        },
      ],
    },
  ],
};
