/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/10/2023.
 */

import { ST, IT, GT } from "../../../consts";

export const definitions = {
  title: "Badges",
  icon: "star_border",
  groups: [
    {
      label: "Featured badge",
      options: [
        {
          title: "Show featured badge",
          templates: [ST.cardGrid],
          name: "sBCFeaturedShow",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/5844206-design-badges",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Badge label",
          templates: [ST.cardGrid],
          name: "sBCFeaturedLabel",
          defaultValue: "Featured",
          tooltip: {
            url: "https://help.display.church/en/articles/5844206-design-badges",
          },
          show: [
            {
              field: "sBCFeaturedShow",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Label background",
          templates: [ST.cardGrid],
          name: "sBCFeaturedBg",
          defaultValue: "#5bdaf7",
          style: [
            {
              selector: [".dce--signups-cards-card_featured-badge"],
              property: "background-color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/5844206-design-badges",
          },
          show: [
            {
              field: "sBCFeaturedShow",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Label color",
          templates: [ST.cardGrid],
          name: "sBCFeaturedColor",
          defaultValue: "#f8f8f8",
          style: [
            {
              selector: [".dce--signups-cards-card_featured-badge"],
              property: "color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/5844206-design-badges",
          },
          show: [
            {
              field: "sBCFeaturedShow",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
