const initialState = {
  collection: {},

  fetch: false,
  fetchError: null,
  fetchSuccess: null,

  updateAlertUUID: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "APP_TAGS_FETCH":
      return {
        ...state,
        fetch: true,
      };

    case "APP_TAGS_FETCH_FULFILLED":
      return {
        ...state,
        collection: action.payload,
        fetch: false,
        fetchSuccess: true,
        fetchError: false,
      };

    case "APP_TAGS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: true,
      };

    case "APP_TAGS_UPDATE":
      return {
        ...state,
        update: true,
        updateSuccess: false,
        updateError: false,
      };

    case "APP_TAGS_UPDATE_FULFILLED":
      const { tagType, tag, isDelete } = action;
      const updatedAppTags = { ...state.collection };

      if (tagType && updatedAppTags[tagType]) {
        if (isDelete) {
          updatedAppTags[tagType] = updatedAppTags[tagType].filter(
            (t) => t !== tag
          );
        } else {
          if (!updatedAppTags[tagType].includes(tag)) {
            updatedAppTags[tagType] = [...updatedAppTags[tagType], tag];
          }
        }
      }

      return {
        ...state,
        collection: updatedAppTags,
        update: false,
        updateSuccess: true,
        updateError: false,
      };

    case "APP_TAGS_UPDATE_REJECTED":
      return {
        ...state,
        collection: state?.collection,
        update: false,
        updateSuccess: false,
        updateError: action.error,
      };

    case "USER_LOGOUT":
      return { ...initialState, collection: {} };
    default:
      return state;
  }

  return state;
}
