/**
 * Get the public name of the calendar
 * @param calendarModel
 * @returns {{length}|*|string}
 */
export const calendarPublicName = (calendarModel) => {
  const seoName = calendarModel?.widget_settings?.seo?.title || "";

  if (seoName.length) {
    return seoName;
  }

  return calendarModel?.name || "";
};

export const isIOS = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

export const isAppleDevice = () => {
  return isIOS() || navigator.userAgent.includes("Macintosh");
};
