/**
 * Created by piotr.pozniak@thebeaverhead.com on 03/08/2021.
 */

import { Link } from "react-router-dom";
import React from "react";

const PrivateEventsSyncWarning = () => {
/*  return (
    <div className="alert alert-danger">
      Your integration configuration does not allow to pull private events.
      <br />
      <Link to={"/integrations"}>Go to integration settings</Link>, edit your
      integration configuration by allowing private events sync.
    </div>
  );*/

  return null;
};

export default PrivateEventsSyncWarning;
