/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/04/2022.
 */
import GroupsStrategy from "./GroupsStrategy";
import { ucFirst } from "../../helpers/string";
import { getLinkFromAnchor } from "../../helpers/widgetItems";

const sanitizeValue = (value, defaultValue) => {
  return value && !Array.isArray(value) ? value : defaultValue;
};

class PCOGroupsStrategy extends GroupsStrategy {
  processData(data) {
    return data.map((i) => {
      const attributes = i.data;

      const description = sanitizeValue(attributes.description, "");
      const headerImageJSON = sanitizeValue(attributes.header_image, "");

      const headerImage = headerImageJSON ? JSON.parse(headerImageJSON) : {};

      const contact = attributes.contact_email
        ? attributes.contact_email.split(",")[0].trim()
        : null;

      const leader = contact
        ? {
            name: contact,
            email: contact,
          }
        : null;

      const meeting = attributes.schedule
        ? {
            meeting_day: sanitizeValue(attributes.schedule, null),
            meeting_time: null,
          }
        : null;

      const membership_type = ucFirst(
        attributes.enrollment_strategy.replaceAll("_", " ")
      );

      const group_type = i.group_type.data.attributes;

      const locationData = i.location ? i.location.data.attributes : null;

      const publicChurchUrl = attributes.public_church_center_web_url;

      const join_group_url = publicChurchUrl;
      // code below doesn't work, PCO has changed addresses to join the group.
      /*? attributes.public_church_center_web_url.match(
            /https:\/\/[-_a-zA-Z0-9\.]*!/
          )[0] + `/groups/groups/${i.platform_metadata_id}/signups/new`
        : null;*/

      const descriptionAnchors = description.match(
        /(<a[\s]+([^>]+)>((?:.(?!\<\/a\>))*.)<\/a>)/g
      );
      const links =
        descriptionAnchors && descriptionAnchors.length
          ? [descriptionAnchors[0]]
          : [];

      if (descriptionAnchors && descriptionAnchors.length > 1) {
        links.push(descriptionAnchors.splice(-1)[0]);
      }

      const primitives = {
        GroupType: group_type.name,
        MembershipType: membership_type,
        // Leader: leader ? leader.name : null,
        ContactEmail: leader ? leader.name : null,
        MembersCount: attributes.memberships_count * 1,
        EnrollmentOpen: attributes.enrollment_open ? "Yes" : "No",
      };

      const ctas = {
        groupPage: {
          url: join_group_url,
          label: "Join group",
        },
        linkFirst: links[0] ? getLinkFromAnchor(links[0]) : null,
        linkLast: links[1] ? getLinkFromAnchor(links[1]) : null,
        contactLeader:
          leader && leader.email
            ? {
                url: `mailto:${leader.email}`,
                label: "Contact leader",
              }
            : null,
      };

      const address = locationData
        ? {
            name: sanitizeValue(locationData.name, null),
            latitude: locationData.latitude,
            longitude: locationData.longitude,
            formatted_address: locationData.full_formatted_address,
            city: null,
            street_address: null,
            state: null,
            zip: null,
          }
        : null;

      const output = {
        name: attributes.name,
        description,
        id: i.id,
        integration_metadata_id: i.platform_metadata_id,
        slug: i.id,
        display_location: attributes.location,
        cover_image: headerImage.medium,
        contact,
        leader,
        group_type,
        membership_type,
        members_count: attributes.memberships_count,
        meeting,
        enrollment_open:
          sanitizeValue(attributes.enrollment_open, "false") === "true",
        address,
        join_group_url,
        ctas,
        primitives,
      };
      return output;
    });
  }
}

export default PCOGroupsStrategy;
