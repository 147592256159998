import { CT } from "../../../consts";

export const definitions = {
  title: "Tile colors",
  icon: "brush",
  groups: [
    {
      label: "Tile",
      options: [
        {
          title: "Tile background",
          templates: [CT.bubble],
          name: "bcBubbleBg",
          defaultValue: "#336AD3",
          style: {
            selector: [".bubble-view--bubble-image"],
            property: "background-color",
          },
          tooltip: {
            message: "Change the color of the tile itself.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Border color",
          templates: [CT.bubble],
          name: "bcBubbleImgBorderColor",
          defaultValue: "#C7C6C6",
          style: {
            selector: [".bubble-view--bubble-image"],
            property: "border-color",
          },
          show: [
            {
              field: "bcBubbleImgBorder",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: {
            message:
              "Change the color of the borders around the tiles themselves.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Information",
      options: [
        {
          title: "Event name color",
          templates: [CT.bubble],
          name: "bcBubbleEventNameColor",
          defaultValue: "#535353",
          style: {
            selector: [".bubble-view--bubble_event-name"],
            property: "color",
          },
          show: [
            {
              field: "bcShowEventName",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Event date color",
          templates: [CT.bubble],
          name: "bcBubbleEventDateColor",
          defaultValue: "#a09e9e",
          style: {
            selector: [".bubble-view--bubble_event-date"],
            property: "color",
          },
          show: [
            {
              field: "bcShowEventDate",
              value: 1,
              defaultValue: 1,
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Scroll arrows",
      options: [
        {
          title: "Background",
          templates: [CT.bubble],
          name: "bcBubbleArrowBg",
          defaultValue: "#ffffff",
          style: {
            selector: [".calendar-bubble-view_scroll_button"],
            property: "background-color",
          },
          show: [
            {
              field: "bcWrap",
              value: 0,
              defaultValue: 0,
            },
          ],
          tooltip: {
            message: "Color of background.",
          },
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Color",
          templates: [CT.bubble],
          name: "bcBubbleArrowColor",
          defaultValue: "#A09E9E",
          style: {
            selector: [
              ".calendar-bubble-view_scroll_button",
              ".calendar-bubble-view_scroll_button .material-icons",
            ],
            property: ["border-color", "color"],
          },
          show: [
            {
              field: "bcWrap",
              value: 0,
              defaultValue: 0,
            },
          ],
          tooltip: {
            message: "Color of ring and arrow.",
          },
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Background on hover",
          templates: [CT.bubble],
          name: "bcBubbleArrowHoverBg",
          defaultValue: "#ffffff",
          style: {
            selector: [".calendar-bubble-view_scroll_button:hover"],
            property: "background-color",
          },
          show: [
            {
              field: "bcWrap",
              value: 0,
              defaultValue: 0,
            },
          ],
          tooltip: {
            message: "Color or background on hover.",
          },
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Color on hover",
          templates: [CT.bubble],
          name: "bcBubbleArrowHoverColor",
          defaultValue: "#A09E9E",
          style: {
            selector: [
              ".calendar-bubble-view_scroll_button:hover",
              ".calendar-bubble-view_scroll_button:hover .material-icons",
            ],
            property: ["border-color", "color"],
          },
          show: [
            {
              field: "bcWrap",
              value: 0,
              defaultValue: 0,
            },
          ],
          tooltip: {
            message: "Color of ring and arrow on hover.",
          },
          control: {
            kind: "color",
            options: {
              showCustomValue: true,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Right edge gradient",
          templates: [CT.bubble],
          name: "bcBubbleArrowRightEdge",
          defaultValue:
            "linear-gradient(90deg, rgba(255, 255, 255, 0) 10%, #ffffff 90%)",
          style: {
            selector: [".calendar-bubble-view_scroll-right"],
            property: "background",
          },
          show: [
            {
              field: "bcWrap",
              value: 0,
              defaultValue: 0,
            },
          ],
          tooltip: {
            message:
              "The color of the gradient at the right edge of your Tiles display.",
            url: "https://help.display.church/en/articles/8813392-design-tile-colors-scroll-arrows-right-and-left-gradient-colors",
          },
          control: {
            kind: "text",
            options: {
              placeholder: "CSS Gradient or color",
            },
          },
        },
        {
          title: "Left edge gradient",
          templates: [CT.bubble],
          name: "bcBubbleArrowLeftEdge",
          defaultValue:
            "linear-gradient(90deg, #ffffff 10%, rgba(255, 255, 255, 0) 100%)",
          style: {
            selector: [".calendar-bubble-view_scroll-left"],
            property: "background",
          },
          show: [
            {
              field: "bcWrap",
              value: 0,
              defaultValue: 0,
            },
          ],
          tooltip: {
            message:
              "The color of the gradient at the left edge of your Tiles display.",
            url: "https://help.display.church/en/articles/8813392-design-tile-colors-scroll-arrows-right-and-left-gradient-colors",
          },
          control: {
            kind: "text",
            options: {
              placeholder: "CSS Gradient or color",
            },
          },
        },
      ],
    },
  ],
};
