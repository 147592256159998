/**
 * Created by abdulah.hamzic@thebeaverhead.com on 31/10/2018.
 */
import { WT } from "./apps/admin/consts/widget";
import { isAppleDevice } from "./apps/calendar/helpers";

export const CCBIntegrationAddress = {
  scheme: "https://",
  host: ".ccbchurch.com/api.php",
};

export const BreezeIntegrationAddress = {
  scheme: "https://",
  host: ".breezechms.com",
};
export const PCOIntegrationAddress = {
  scheme: "https://",
  host: ".churchcenter.com",
};

/**
 *
 * @type {*[]}
 */
export const PAYOUTS_EXPENSES_KINDS = [
  {
    label: "One time",
    value: "",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Yearly",
    value: "yearly",
  },
];

/**
 *
 * @type {*[]}
 */
export const PAYOUTS_VENDORS = [
  {
    label: "Choose Vendor",
    value: "",
  },
  {
    label: "Fluid Ministry, LLC",
    value: "FLI",
  },
  {
    label: "The Beaverhead LTD",
    value: "TBH",
  },
];

/**
 *
 * @type {{cardList: string, detailedList: string, eventList: string, monthly: string, weekly: string, card: string, cardSlider: string}}
 */
export const CT = {
  eventList: "events_list",
  monthly: "monthly_view",
  weekly: "weekly_view",
  card: "card_view",
  cardList: "card_list",
  cardSlider: "card_slider",
  detailedList: "detailed_list",
  bubble: "bubble",
};

export const CT_NAMES = {
  [CT.eventList]: "Events List",
  [CT.monthly]: "Monthly View",
  [CT.weekly]: "Weekly View",
  [CT.card]: "Cards (grid)",
  [CT.cardList]: "Cards (list)",
  [CT.cardSlider]: "Cards (slider)",
  [CT.detailedList]: "Detailed List",
  [CT.bubble]: "Tiles",
};

/**
 * Group Widget Type GT
 * @type {{card: string}}
 */
export const GT = {
  card: "card_view",
  twoColumn: "two_col",
  outlined: "outlined",
  mosaic: "mosaic",
};
export const AT = {
  banner: "banner",
  modal: "modal",
  alert: "alert",
  takeover: "takeover",
  slider: "slider",
};
export const ST = {
  cardGrid: "cards",
  cardList: "cards_list",
};

/**
 * Preview Types PT
 * @type {{auto: string, desktop: string, mobile: string}}
 */
export const PT = {
  auto: "auto",
  desktop: "desktop",
  mobile: "mobile",
};

/**
 * Integration Types
 * @type {{ccb: string, pco: string}}
 */
export const IT = {
  ccb: "ccb",
  pco: "pco",
  google: "google",
  breeze: "breeze",
};

export const INTEGRATION_NAMES = {
  [IT.ccb]: "CCB",
  [IT.pco]: "PCO",
  [IT.google]: "Google Calendar",
  [IT.breeze]: "Breeze",
};

export const INTEGRATION_AUTH_NAMES = {
  [IT.ccb]: "PushPay",
  [IT.pco]: "Planning Center",
  [IT.google]: "Google",
  [IT.breeze]: "Breeze",
};
/**
 * Plan Types
 * @type {{starter: string, highest: string, medium: string}}
 */
export const PLANS = {
  free: "FREE",
  starter: "BASIC",
  medium: "STANDARD",
  highest: "PREMIUM",
};

export const PLAN_NAMES = {
  [PLANS.free]: "Free",
  [PLANS.starter]: "Starter",
  [PLANS.medium]: "Medium",
  [PLANS.highest]: "Pro",
};

export const PLANS_CALENDAR_TEMPLATES = {
  FREE: [CT.eventList, CT.monthly, CT.weekly, CT.card, CT.bubble],
  BASIC: [CT.eventList, CT.monthly, CT.weekly, CT.card, CT.bubble],
  STANDARD: [
    CT.eventList,
    CT.monthly,
    CT.weekly,
    CT.card,
    CT.cardSlider,
    CT.cardList,
    CT.bubble,
  ],
  PREMIUM: [
    CT.eventList,
    CT.monthly,
    CT.weekly,
    CT.card,
    CT.cardList,
    CT.cardSlider,
    CT.detailedList,
    CT.bubble,
  ],
};

export const CALENDAR_TEMPLATES_PLANS = {
  [CT.eventList]: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
  [CT.monthly]: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
  [CT.weekly]: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
  [CT.card]: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
  [CT.cardList]: [PLANS.medium, PLANS.highest],
  [CT.cardSlider]: [PLANS.medium, PLANS.highest],
  [CT.detailedList]: [PLANS.highest],
  [CT.bubble]: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
};

export const SIGNUP_TEMPLATES_PLANS = {
  [ST.cardGrid]: [PLANS.starter, PLANS.medium, PLANS.highest],
  [ST.cardList]: [PLANS.starter, PLANS.medium, PLANS.highest],
};

export const WIDGET_TEMPLATES_PLANS = {
  [WT.calendar]: CALENDAR_TEMPLATES_PLANS,
  [WT.signups]: SIGNUP_TEMPLATES_PLANS,
};

export const PRIVATE_EVENTS_PLANS = {
  FREE: false,
  BASIC: false,
  STANDARD: true,
  PREMIUM: true,
};

export const FEATURED_EVENTS_PLANS = {
  FREE: false,
  BASIC: false,
  STANDARD: true,
  PREMIUM: true,
};

export const FILTERS_EVENTS_PLANS = {
  ccb: {
    FREE: ["group", "department", "location", "campus", "event_organizer"],
    BASIC: ["group", "department", "location", "campus", "event_organizer"],
    STANDARD: ["group", "department", "location", "campus", "event_organizer"],
    PREMIUM: ["group", "department", "location", "campus", "event_organizer"],
  },
  pco: {
    FREE: ["approval_status", "tag", "tag_group", "featured"],
    BASIC: ["approval_status", "tag", "tag_group", "featured"],
    STANDARD: [
      "approval_status",
      "group",
      "tag",
      "tag_group",
      "groups_connection",
      "group_only",
      "group_type",
      "featured",
    ],
    PREMIUM: [
      "approval_status",
      "group",
      "tag",
      "tag_group",
      "groups_connection",
      "group_only",
      "group_type",
      "featured",
    ],
  },
  google: {
    FREE: ["calendar", "tag"],
    BASIC: ["calendar", "place", "tag"],
    STANDARD: ["calendar", "place", "tag"],
    PREMIUM: ["calendar", "place", "tag"],
  },
  breeze: {
    FREE: ["location", "tag", "calendar"],
    BASIC: ["location", "tag", "calendar"],
    STANDARD: ["location", "tag", "calendar"],
    PREMIUM: ["location", "tag", "calendar"],
  },
};

export const FILTER_SETTINGS_PLANS = {
  pco: {
    approval_status: {
      allowedValue: "A",
      plans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    },
  },
  ccb: {},
  google: {},
  breeze: {},
};

export const UNBRANDED_YEARLY_PRICE = 50.0;
export const UNBRANDED_MONTHLY_PRICE = 5.0;

export const ShareIcon = isAppleDevice() ? "ios_share" : "share";

// mailchimp templates
export const MCT = {
  list: "list",
  compact: "compact",
};
