/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022.
 */

const initialState = {
  collection: [],

  fetch: false,
  fetchSuccess: false,
  fetchError: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "USER_LOGOUT":
      return initialState;

    case "INTEGRATION_METADATA_FETCH":
      return {
        ...state,
        fetch: true,
        fetchError: null,
        fetchSuccess: false,
      };

    case "INTEGRATION_METADATA_FETCH_FULFILLED":
      return {
        ...state,
        collection: action.payload,
        fetch: false,
        fetchSuccess: true,
        fetchError: null,
      };

    case "INTEGRATION_METADATA_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.payload,
      };

    default:
      return state;
  }

  return state;
}
