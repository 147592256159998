/**
 * Created by piotr.pozniak@thebeaverhead.com on 03/08/2021.
 */

import { Link } from "react-router-dom";
import React from "react";
import ExpandableAlert from "../../../admin/component/ExpandableAlert";

const PrivateEventsSyncWarning = (integrationUUID) => {
  return (
    <ExpandableAlert
      icon={"warning"}
      errorLevel={"danger"}
      header={"Set up is required"}
      body={
        <span>
          Your integration configuration does not allow to pull private events.
          <br />
          <Link to={`/integration/${integrationUUID}`}>
            Go to integration settings
          </Link>
          , edit your integration configuration by allowing private events sync.
        </span>
      }
    />
  );
};

export default PrivateEventsSyncWarning;
