/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/04/2022.
 */

// console.log("Hello Jason!");

import { dceDebug } from "./helpers/debug";
import {
  onDocumentLoad,
  attachScript,
  getEmbeddableSource,
  getEmbeddableScriptDom,
  postMessageToWixIframes,
  onLocationChange,
} from "./helpers/launcher";
import { WT } from "./apps/admin/consts/widget";
import { runWidgetInitialization } from "./helpers/embeddable";

const KIND = "loader";
const backendAddress = getEmbeddableSource();
const scriptTag = getEmbeddableScriptDom();

const loadWidget = (widgetName) => {
  const widgetFileName = `${widgetName}${window.__DCE__DEV__ ? "" : ".min"}.js`;
  const widgetUri = `${backendAddress}/widgets/${widgetFileName}`;

  dceDebug("loading widget: ", widgetName, widgetUri);

  attachScript(widgetUri);
};

/**
 *
 * @param kind
 * @param widgetId
 */
const scrollToWidget = (kind, widgetId) => {
  // scroll to this div if query param contains its uuid
  if (window.location.search.includes(`${kind}=${widgetId}`)) {
    const element = document.getElementById(widgetId);
    if (element) {
      element.scrollIntoView();
    }
  }
};

const initializeWidgets = () => {
  dceDebug("initializeWidgets");

  // .ccb-events is for backward compatibility, used by all calendars embedded before v3.
  const embeddableDoms = document.querySelectorAll(
    ".dce-calendar, .dce-groups, .ccb-events, .dce-signup"
  );

  if (!embeddableDoms.length && !scriptTag && !scriptTag?.getAttribute("wix")) {
    setTimeout(initializeWidgets, 500);
    return;
  }

  const loadedWidgets = [];
  if (!scriptTag || scriptTag.getAttribute("wix") == undefined) {
    for (const dom of embeddableDoms) {
      dom.classList.forEach((value, key) => {
        // check for backward compatibility of ccb-events classname
        if (value.toLowerCase() === "ccb-events") {
          if (!loadedWidgets.includes(WT.calendar)) {
            loadedWidgets.push(WT.calendar);
            loadWidget(WT.calendar);
            scrollToWidget(WT.calendar, dom.id);
          }
          return;
        }

        const widget = value.replace("dce-", "");
        dceDebug("widget", widget);
        if (!loadedWidgets.includes(widget)) {
          loadedWidgets.push(widget);
          loadWidget(widget);
          scrollToWidget(widget, dom.id);
        }
      });
    }
  } else {
    window.addEventListener("message", (event) => {
      if (event.data.type && !loadedWidgets.includes(event.data.type)) {
        loadedWidgets.push(event.data.type);
        loadWidget(event.data.type);
      }
    });

    const sendAdvertisementToWix = () => {
      postMessageToWixIframes((iframe, i) => {
        console.log(iframe);
        iframe.contentWindow.postMessage({ advertise: true }, "*");
      });
    };

    const interval = setInterval(() => {
      if (loadedWidgets.length) {
        clearInterval(interval);
        return;
      }
      sendAdvertisementToWix();
    }, 300);
  }
};

const reinitializeWidgets = (type, uuid) => {
  dceDebug("reinitializeWidgets", type, uuid);
  runWidgetInitialization(type, `div[id='${uuid}']`);
};

(() => {
  window.__DCE = window.__DCE || {};

  const ID = new Date() * 1;

  dceDebug("script ID", ID, window.DCELOADERID);

  if (window.DCELOADERID) {
    return;
  } else {
    window.DCELOADERID = ID;
    window.__DCE[KIND] = {
      isWidgetLoaded: false,
    };
  }

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    window.__DCE__DEV__ = true;
  } else {
    window.__DCE__DEV__ = false;
  }

  onDocumentLoad("loader", initializeWidgets);
  onLocationChange(initializeWidgets);

  window.addEventListener(
    "message",
    (event) => {
      dceDebug(
        "dce-embeddable",
        event.data,
        event.data.type === "dce-embeddable"
      );
      if (event.data.type === "dce-embeddable") {
        reinitializeWidgets(event.data.widgetType, event.data.widgetUUID);
      }
    },
    false
  );
})();
