/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/10/2023.
 */
import ItemsStrategy from "../ItemsStrategy";
import { getImagesFromDescription } from "../../helpers/widgetItems";

const sanitizeValue = (value, defaultValue) => {
  return value && !Array.isArray(value) ? value : defaultValue;
};

class PCOSignupsStrategy extends ItemsStrategy {
  processData(data) {
    let items = data.map((i) => {
      const data = i.data;
      let description = sanitizeValue(data.description, "");

      const descriptionImages = getImagesFromDescription(description);

      if (descriptionImages.length) {
        // replace first image tag with src with value of descriptionImages[0].url with an empty string
        description = description.replace(
          /<img\s+[^>]*src="([^"]*)"[^>]*>/g,
          ""
        );
      }
      /*
      const headerImageJSON = sanitizeValue(attributes.header_image, "");

      const headerImage = headerImageJSON ? JSON.parse(headerImageJSON) : {};

      const contact = attributes.contact_email
        ? attributes.contact_email.split(",")[0].trim()
        : null;*/
      /*

      const leader = contact
        ? {
            name: contact,
            email: contact,
          }
        : null;

      const meeting = attributes.schedule
        ? {
            meeting_day: sanitizeValue(attributes.schedule, null),
            meeting_time: null,
          }
        : null;

      const group_type = i.group_type.data.attributes;

      const locationData = i.location ? i.location.data.attributes : null;

      const publicChurchUrl = attributes.public_church_center_web_url;
*/

      /*      const descriptionAnchors = description.match(
        /(<a[\s]+([^>]+)>((?:.(?!\<\/a\>))*.)<\/a>)/g
      );
      const links =
        descriptionAnchors && descriptionAnchors.length
          ? [descriptionAnchors[0]]
          : [];

      if (descriptionAnchors && descriptionAnchors.length > 1) {
        links.push(descriptionAnchors.splice(-1)[0]);
      }*/
      const starts_at =
        data.start && !isNaN(new Date(data.start).getTime())
          ? new Date(data.start)
          : null;

      const ends_at =
        data.end && !isNaN(new Date(data.end).getTime())
          ? new Date(data.end)
          : null;

      const output = {
        name: data.title,
        description: description || "",
        id: i.id,
        cover_image: descriptionImages.length ? descriptionImages[0].url : null,
        formUrl: data.url,
        isFeatured: null, //attributes.featured,
        frontendUrl: null, //attributes.public_url,
        starts_at,
        ends_at,
        /*        integration_metadata_id: i.platform_metadata_id,
        slug: i.id,
        display_location: attributes.location,
        contact,
        leader,
        group_type,
        members_count: attributes.memberships_count,
        meeting,
        enrollment_open:
          sanitizeValue(attributes.enrollment_open, "false") === "true",
        address,
        join_group_url,
        ctas,
        primitives,*/
      };
      return output;
    });

    const sortItemsByFeaturedFlag =
      this.model?.widget_settings?.sorting?.is_featured_first;

    if (sortItemsByFeaturedFlag) {
      items = items.sort((a, b) => {
        if (a.isFeatured && !b.isFeatured) {
          return -1;
        }
        return 0;
      });
    }

    return items;
  }
}

export default PCOSignupsStrategy;
