/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/02/2019.
 */

const initialState = {
  collection: [],
  pagination: {},
  page: 1,
  ipp: 5,

  fetch: false,
  fetchSuccess: false,
  fetchError: null,
};

export default function reducer(
  state = { ...initialState, collection: [] },
  action
) {
  switch (action.type) {
    case "IMPORTS_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: false,
        page: action.payload.page,
        ipp: action.payload.ipp,
      };

    case "IMPORTS_FETCH_FULFILLED":
      return {
        ...state,
        collection: action.payload.imports,
        pagination: action.payload.pagination,

        fetch: false,
        fetchSuccess: true,
      };

    case "IMPORTS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.payload,
      };

    case "USER_LOGOUT":
      return { ...initialState, collection: [] };

    default:
      return state;
  }

  return state;
}
