/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/02/2023.
 */

import { AT, CT } from "../../../consts";

export const definitions = {
  title: "Configuration",
  icon: "settings",
  groups: [
    {
      label: "Location",
      options: [
        {
          title: "Position",
          templates: [AT.banner],
          name: "dcaPosition",
          defaultValue: "dc-banner_top",
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "Where your Event Alert will display on the page.",
          },
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "Top of the page", value: "dc-banner_top" },
                { label: "Bottom of page", value: "dc-banner_bottom" },
              ],
            },
          },
        },
        {
          title: "Placement",
          templates: [AT.banner],
          name: "dcaPlacement",
          defaultValue: "dc-banner_stick",
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message:
              "Whether your Event Alert will be visible when scrolling or not.",
          },
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "Stick", value: "dc-banner_stick" },
                { label: "Scroll with the page", value: "dc-banner_scroll" },
              ],
            },
          },
        },
      ],
    },
    {
      label: "Behavior",
      options: [
        {
          title: "Show after",
          templates: [AT.banner],
          name: "dcaShowAfter",
          defaultValue: "0",
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "The first time the viewer will see your Event Alert.",
          },
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "On page load", value: "0" },
                { label: "5 second delay", value: "5" },
                { label: "10 second delay", value: "10" },
                { label: "15 second delay", value: "15" },
                { label: "20 second delay", value: "20" },
                { label: "25 second delay", value: "25" },
                { label: "30 second delay", value: "30" },
                { label: "45 second delay", value: "45" },
                { label: "60 second delay", value: "60" },
                { label: "2 minute delay", value: "120" },
                { label: "After scrolling a little", value: "little" },
                { label: "After scrolling to middle", value: "middle" },
                { label: "After scrolling to bottom", value: "end" },
              ],
            },
          },
        },
        {
          title: "Allow user to hide the banner",
          templates: [AT.banner],
          name: "dcaCanHide",
          defaultValue: "1",
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },

        {
          title: "Show banner again after",
          templates: [AT.banner],
          name: "dcaShowAgainAfter",
          defaultValue: "reload",
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "When next your viewer will see your Event Alert.",
          },
          show: [
            {
              field: "dcaCanHide",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "Every page reload", value: "reload" },
                { label: "After 1 day", value: "1" },
                { label: "After 2 days", value: "2" },
                { label: "After 3 days", value: "3" },
                { label: "After 4 days", value: "4" },
                { label: "After 5 days", value: "5" },
                { label: "After 6 days", value: "6" },
                { label: "After 7 days", value: "7" },
                { label: "After 2 weeks", value: "14" },
                { label: "After 3 weeks", value: "21" },
                { label: "After 4 weeks", value: "28" },
                { label: "After 1 month", value: "30" },
                { label: "After 2 months", value: "61" },
                { label: "After 3 months", value: "92" },
                { label: "On new event in the series", value: "event" },
                { label: "Never display again", value: "never" },
              ],
            },
          },
        },
      ],
    },
    {
      label: "Messaging",
      options: [
        {
          title: "Opening",
          templates: [AT.banner],
          name: "dcaOpeningText",
          defaultValue: "Join us for the next",
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "Type in the text line to change the default. ",
          },
          control: {
            kind: "text",
            options: {
              placeholder: "Opening message",
            },
          },
        },
        {
          title: "Closing",
          templates: [AT.banner],
          name: "dcaClosingText",
          defaultValue: "See you there!",
          tooltip: {
            url: "https://help.display.church/en/articles/7191669-event-alerts-banner",
            message: "Type in the text line to change the default.",
          },
          control: {
            kind: "text",
            options: {
              placeholder: "Closing message",
            },
          },
        },
      ],
    },
  ],
};
