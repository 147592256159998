import { AT } from "../../../consts";
/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/02/2023.
 */

/**
 * Contains hidden fields. These fields are not visible in the builder but used in the install tab.
 */
export const definitions = {
  title: "Alerts",
  icon: "remove",
  groups: [
    {
      label: "----",
      options: [
        {
          title: "Page to display",
          templates: [AT.banner],
          name: "dcAPageToDisplay",
          defaultValue: "",
          tooltip: null,
          show: [
            {
              field: "dcAPageToDisplay",
              value: false,
              defaultValue: "",
            },
          ],
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Show on",
          templates: [AT.banner],
          name: "dcAPageToShowOnOption",
          defaultValue: "*",
          tooltip: null,
          show: [
            {
              field: "dcAPageToShowOnOption",
              value: false,
              defaultValue: "",
            },
          ],
          control: {
            kind: "text",
            options: {},
          },
        },
        {
          title: "Show on",
          templates: [AT.banner],
          name: "dcAPageToShowOn",
          defaultValue: "",
          tooltip: null,
          show: [
            {
              field: "dcAPageToShowOn",
              value: false,
              defaultValue: "",
            },
          ],
          control: {
            kind: "text",
            options: {},
          },
        },
      ],
    },
  ],
};
