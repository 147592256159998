/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/04/2019.
 */

const initialState = {
  collection: [],

  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  update: false,
  updateSuccess: false,
  updateError: null,

  updatePayment: false,
  updatePaymentSuccess: false,
  updatePaymentError: null,

  delete: false,
  deleteSuccess: false,
  deleteError: null,
};

export default function reducer(
  state = { ...initialState, collection: [] },
  action
) {
  switch (action.type) {
    case "SUBSCRIPTION_INITIATE":
      return {
        ...state,
        update: true,
        updateSuccess: false,
        updateError: null,
      };

    case "SUBSCRIPTION_INITIATE_FULFILLED":
      return {
        ...state,
        update: false,
        updateSuccess: true,
      };

    case "SUBSCRIPTION_INITIATE_REJECTED":
      return {
        ...state,
        update: false,
        updateError: action.payload,
      };

    case "SUBSCRIPTION_TERMINATE":
      return {
        ...state,
        delete: true,
        deleteSuccess: false,
        deleteError: null,
      };

    case "SUBSCRIPTION_TERMINATE_FULFILLED":
      return {
        ...state,
        delete: false,
        deleteSuccess: true,
      };

    case "SUBSCRIPTION_TERMINATE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteError: action.payload,
      };

    case "BILLING_DETAILS_UPDATE":
      return {
        ...state,
        updatePayment: true,
        updatePaymentSuccess: false,
        updatePaymentError: null,
      };

    case "BILLING_DETAILS_UPDATE_FULFILLED":
      return {
        ...state,
        updatePayment: false,
        updatePaymentSuccess: true,
      };

    case "BILLING_DETAILS_UPDATE_REJECTED":
      return {
        ...state,
        updatePayment: false,
        updatePaymentError: action.payload,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        collection: {
          ...state.collection,
        },
      };

    default:
      return state;
  }

  return state;
}
