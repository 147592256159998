/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/10/2023.
 */

import { definitions as Frame } from "./frame";
import { definitions as Fonts } from "./fonts";
import { definitions as Cards } from "./cards";
import { definitions as Badges } from "./badges";

const definitions = [Frame, Cards, Badges, Fonts];

export default definitions;
