import React from "react";

const FilterLabelCTA = () => {
  return (
    <span>
      You may filter events on this calendar using one of the available filters.{" "}
      <a
        href={
          "https://help.display.church/en/articles/5553777-setup-events-filtering-calendars-event-alerts-pco"
        }
        target={"_blank_pco_filters"}
      >
        Follow this link to learn more.
      </a>
    </span>
  );
};

export default FilterLabelCTA;
