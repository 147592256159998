/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/06/2021.
 */
import CCBEventsStrategy from "./EventsStrategy/CCBEventsStrategy";
import PCOEventsStrategy from "./EventsStrategy/PCOEventsStrategy";
import { IT } from "../consts";
import GoogleEventsStrategy from "./EventsStrategy/GoogleEventsStrategy";
import BreezeEventsStrategy from "./EventsStrategy/BreezeEventsStrategy";

const selectStrategy = (kind) => {
  switch (kind) {
    case IT.ccb:
      return new CCBEventsStrategy();
    case IT.pco:
      return new PCOEventsStrategy();
    case IT.google:
      return new GoogleEventsStrategy();
    case IT.breeze:
      return new BreezeEventsStrategy();
  }
};

const EventsMiddleware = (storeAPI) => (next) => (action) => {
  const alert = storeAPI.getState().alert;

  let eventStrategy = null;

  switch (action.type) {
    case "EVENTS_FETCH_FULFILLED":
    case "FEATURED_EVENTS_FETCH_FULFILLED":
      const calendar = storeAPI.getState().calendar;

      if (!calendar.model || !calendar.model.integration) {
        return next(action);
      }
      eventStrategy = selectStrategy(calendar.model.integration.type);

      action.payload.events = eventStrategy.processData(
        action.payload.event ? [action.payload.event] : action.payload.events,
        calendar.model.integration
      );

      if (action.payload.topMatches) {
        action.payload.topMatches = eventStrategy.processData(
          action.payload.topMatches,
          calendar.model.integration
        );
      }
      break;

    case "ALERT_EVENTS_FETCH_FULFILLED":
      if (!alert.model || !alert.model.integration) {
        return next(action);
      }
      eventStrategy = selectStrategy(alert.model.integration.type);

      action.payload.events = eventStrategy.processData(
        action.payload.event ? [action.payload.event] : action.payload.events,
        alert.model.integration
      );
      break;
  }

  next(action);
};

export default EventsMiddleware;
