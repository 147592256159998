/**
 *
 * @param {Array}
 * @param {Array}
 * @returns {boolean}
 */
export function isEqualArrays(arr1, arr2) {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return arr1 === arr2;
  }

  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  return sortedArr1.every((item, index) => {
    if (Array.isArray(item) && Array.isArray(sortedArr2[index])) {
      return isEqualArrays(item, sortedArr2[index]);
    } else if (
      typeof item === "object" &&
      typeof sortedArr2[index] === "object"
    ) {
      return isEqualObjects(item, sortedArr2[index]);
    } else {
      return item === sortedArr2[index];
    }
  });
}

/**
 *
 * @param {Object}
 * @param {Object}
 * @returns {boolean}
 */
export function isEqualObjects(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!obj2.hasOwnProperty(key) || !isEqualArrays(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export function capitalizeString(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 *
 * @returns {string}
 */
export function getWebBrowserCountryCode() {
  let countryCode = window.navigator.language;

  if (countryCode) {
    const codes = countryCode.split("-");

    if (codes.length === 2) {
      countryCode = codes[1];
    }
  }

  return countryCode;
}
/**
 *
 * @returns {string}
 */
export function getWebBrowserLanguageCode() {
  let countryCode = window.navigator.language;

  if (countryCode) {
    const codes = countryCode.split("-");

    if (codes.length === 2) {
      countryCode = codes[0];
    }
  }

  return countryCode;
}
