/**
 * Created by piotr.pozniak@thebeaverhead.com on 01/07/2022.
 */

import { GT, IT } from "../../../consts";

const BADGES_FIELDS_CCB = [
  {
    value: "RegistrationStatus",
    label: "Registration status",
  },
  {
    value: "InteractionType",
    label: "Interaction type",
  },
  {
    value: "Childcare",
    label: "Childcare provided",
  },
  {
    value: "GroupType",
    label: "Group type",
  },
  {
    value: "MembershipType",
    label: "Membership type",
  },
  {
    value: "Leader",
    label: "Main leader",
  },
  {
    value: "Department",
    label: "Department",
  },
  {
    value: "Capacity",
    label: "Group capacity",
  },
  {
    value: "MembersCount",
    label: "Members count",
  },
];

const BADGES_FIELDS_PCO = [
  {
    value: "GroupType",
    label: "Group type",
  },
  {
    value: "MembershipType",
    label: "Enrollment Strategy",
  },
  // {
  //   value: "Leader",
  //   label: "Main leader",
  // },
  {
    value: "ContactEmail",
    label: "Contact email",
  },
  {
    value: "MembersCount",
    label: "Members count",
  },
  {
    value: "EnrollmentOpen",
    label: "Enrollment availability",
  },
];

const showListBadgesColorOptionsDepsCCB = [
  {
    field: "gBCShowRegistrationStatus",
    value: 1,
    defaultValue: 1,
    or: true,
  },
  {
    field: "gBCShowInteractionType",
    value: 1,
    defaultValue: 1,
    or: true,
  },
  {
    field: "gBCShowChildcare",
    value: 1,
    defaultValue: 1,
    or: true,
  },
  {
    field: "gBCShowGroupType",
    value: 1,
    defaultValue: 1,
    or: true,
  },
  {
    field: "gBCShowMembershipType",
    value: 1,
    defaultValue: 1,
    or: true,
  },
  {
    field: "gBCShowLeader",
    value: 1,
    defaultValue: 1,
    or: true,
  },
  {
    field: "gBCShowDepartment",
    value: 1,
    defaultValue: 1,
    or: true,
  },
  {
    field: "gBCShowCapacity",
    value: 1,
    defaultValue: 1,
    or: true,
  },
  {
    field: "gBCShowMembersCount",
    value: 1,
    defaultValue: 1,
    or: true,
  },
];
const showListBadgesColorOptionsDepsPCO = [
  {
    field: "gBCShowGroupType",
    value: 1,
    defaultValue: 1,
    or: true,
  },
  {
    field: "gBCShowMembershipType",
    value: 1,
    defaultValue: 1,
    or: true,
  },
  // {
  //   field: "gBCShowLeader",
  //   value: 1,
  //   defaultValue: 1,
  //   or: true,
  // },
  {
    field: "gBCShowContactEmail",
    value: 1,
    defaultValue: 1,
    or: true,
  },
  {
    field: "gBCShowMembersCount",
    value: 1,
    defaultValue: 1,
    or: true,
  },
  {
    field: "gBCShowEnrollmentOpen",
    value: 1,
    defaultValue: 1,
    or: true,
  },
];

export const definitions = {
  title: "Badges",
  icon: "star_border",
  groups: [
    {
      label: "Cards primary badge",
      options: [
        {
          title: "Show primary basic information badge",
          templates: [GT.card],
          name: "gBCShowPrimary",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide the first of two badges.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Select information to show as label",
          templates: [GT.card],
          integrations: [IT.ccb],
          name: "gBCShowPrimaryField",
          defaultValue: "GroupType",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Label of first badge.",
          },
          show: [
            {
              field: "gBCShowPrimary",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: BADGES_FIELDS_CCB,
            },
          },
        },
        {
          title: "Select information to show as label",
          templates: [GT.card],
          integrations: [IT.pco],
          name: "gBCShowPrimaryField",
          defaultValue: "GroupType",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Label of first badge.",
          },
          show: [
            {
              field: "gBCShowPrimary",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: BADGES_FIELDS_PCO,
            },
          },
        },
        {
          title: "Label background",
          templates: [GT.card],
          name: "gBCPrimaryBg",
          defaultValue: "#DBE4EB",
          style: [
            {
              selector: [".dce--groups-cards-card_badge-primary"],
              property: "background-color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Background color of first badge.",
          },
          show: [
            {
              field: "gBCShowPrimary",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Label color",
          templates: [GT.card],

          name: "gBCPrimaryColor",
          defaultValue: "#3B444D",
          style: [
            {
              selector: [".dce--groups-cards-card_badge-primary"],
              property: "color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Text color of first badge.",
          },
          show: [
            {
              field: "gBCShowPrimary",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Cards secondary badge",
      options: [
        {
          title: "Show secondary basic information badge",
          templates: [GT.card],
          name: "gBCShowSecondary",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide second badge.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Select information to show as label",
          templates: [GT.card],
          integrations: [IT.ccb],
          name: "gBCShowSecondaryField",
          defaultValue: "MembershipType",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Label of second badge.",
          },
          show: [
            {
              field: "gBCShowSecondary",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: BADGES_FIELDS_CCB,
            },
          },
        },
        {
          title: "Select information to show as label",
          templates: [GT.card],
          integrations: [IT.pco],
          name: "gBCShowSecondaryField",
          defaultValue: "MembershipType",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Label of second badge.",
          },
          show: [
            {
              field: "gBCShowSecondary",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: BADGES_FIELDS_PCO,
            },
          },
        },
        {
          title: "Label background",
          templates: [GT.card],
          name: "gBCSecondaryBg",
          defaultValue: "#DBE4EB",
          style: [
            {
              selector: [".dce--groups-cards-card_badge-secondary"],
              property: "background-color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Background color of second badge.",
          },
          show: [
            {
              field: "gBCShowSecondary",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Label color",
          templates: [GT.card],

          name: "gBCPrimarySecondary",
          defaultValue: "#3B444D",
          style: [
            {
              selector: [".dce--groups-cards-card_badge-secondary"],
              property: "color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Text color of second badge.",
          },
          show: [
            {
              field: "gBCShowSecondary",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "List badges",
      options: [
        {
          title: "Show registration status",
          templates: [GT.twoColumn],
          integrations: [IT.ccb],
          name: "gBCShowRegistrationStatus",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide badge.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show interaction type",
          templates: [GT.twoColumn],
          integrations: [IT.ccb],
          name: "gBCShowInteractionType",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide badge.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show childcare information",
          templates: [GT.twoColumn],
          integrations: [IT.ccb],
          name: "gBCShowChildcare",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide badge.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show group type",
          templates: [GT.twoColumn],
          name: "gBCShowGroupType",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide badge.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show membership type",
          templates: [GT.twoColumn],
          name: "gBCShowMembershipType",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide badge.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show leader",
          templates: [GT.twoColumn],
          name: "gBCShowLeader",
          integrations: [IT.ccb],
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide badge.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show contact email",
          templates: [GT.twoColumn],
          name: "gBCShowContactEmail",
          integrations: [IT.pco],
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide badge.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show department",
          templates: [GT.twoColumn],
          integrations: [IT.ccb],
          name: "gBCShowDepartment",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide badge.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show group capacity",
          templates: [GT.twoColumn],
          integrations: [IT.ccb],
          name: "gBCShowCapacity",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide badge.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show members count",
          templates: [GT.twoColumn],
          name: "gBCShowMembersCount",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide badge.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show enrollment availability",
          templates: [GT.twoColumn],
          integrations: [IT.pco],
          name: "gBCShowEnrollmentOpen",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Show/hide badge.",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Label background",
          templates: [GT.twoColumn],
          integrations: [IT.ccb],
          name: "gBCListBg",
          defaultValue: "#DBE4EB",
          style: [
            {
              selector: [".dce--groups-cards-card_badge-list"],
              property: "background-color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Background color of all badges in this section.",
          },
          show: showListBadgesColorOptionsDepsCCB,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Label color",
          templates: [GT.twoColumn],
          integrations: [IT.ccb],
          name: "gBCListColor",
          defaultValue: "#3B444D",
          style: [
            {
              selector: [".dce--groups-cards-card_badge-list"],
              property: "color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Text color of all badges in this section.",
          },
          show: showListBadgesColorOptionsDepsCCB,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Label background",
          templates: [GT.twoColumn],
          integrations: [IT.pco],
          name: "gBCListBg",
          defaultValue: "#DBE4EB",
          style: [
            {
              selector: [".dce--groups-primitives-badges-badge-list"],
              property: "background-color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Background color of all badges in this section.",
          },
          show: showListBadgesColorOptionsDepsPCO,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Label color",
          templates: [GT.twoColumn],
          integrations: [IT.pco],
          name: "gBCListColor",
          defaultValue: "#3B444D",
          style: [
            {
              selector: [".dce--groups-primitives-badges-badge-list"],
              property: "color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Text color of all badges in this section.",
          },
          show: showListBadgesColorOptionsDepsPCO,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
