/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/10/2023.
 */

import { ST } from "../../../consts";

const FontSizesOptions = [
  {
    label: "Extra small",
    value: "12px",
  },
  {
    label: "Small",
    value: "14px",
  },
  {
    label: "Medium",
    value: "16px",
  },
  {
    label: "Large",
    value: "18px",
  },
  {
    label: "Extra large",
    value: "20px",
  },
];

export const definitions = {
  title: "Fonts",
  icon: "format_size",
  groups: [
    {
      label: "Font Family",
      options: [
        {
          title: "General",
          templates: [ST.cardGrid, ST.cardList],
          name: "fontFamily",
          defaultValue: "Roboto",
          style: {
            selector: (uuid) => ".dce-signup",
            property: "font-family",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6528941-design-fonts",
          },
          control: {
            kind: "fontFamily",
            options: {
              placeholder: "Search Google Fonts",
              inherit: true,
            },
          },
        },
      ],
    },
    {
      label: "Size",
      options: [
        {
          title: "Responsive",
          templates: [ST.cardGrid, ST.cardList],
          name: "scFontSizeDesktop",
          defaultValue: "16px",
          style: {
            selector: [
              (uuid) => ".dce-signup",
              (uuid) => ".dce-signup-modal--wrapper",
            ],
            property: ["font-size"],
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6528941-design-fonts",
          },
          control: {
            kind: "range",
            options: FontSizesOptions,
          },
        },
      ],
    },
  ],
};
