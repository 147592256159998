/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/07/2022.
 */

import { CT, IT } from "../../../consts";
import {
  CTA_OPTIONS_CCB,
  CTA_OPTIONS_PCO,
  CTA_POSITIONS,
  CTA_OPTIONS_BREEZE_GOOGLE,
} from "./cta_options";

export const definitions = {
  title: "Calls to action",
  icon: "call_to_action",
  groups: [
    {
      label: "Card call-to-action",
      options: [
        {
          title: "Show CTA button",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          name: "cCardShowCta1st",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/8442825-calendar-design-calls-to-action",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Trigger action on card click",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          name: "cCardActionOnClick",
          defaultValue: 0,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: (
            <span>
              Make button full width{" "}
              <span className={"badge badge-success"}>NEW</span>
            </span>
          ),
          templates: [CT.card],
          name: "cCardCtaButtonFullWidth",
          defaultValue: 1,
          tooltip: {
            url: "https://help.display.church/en/articles/8442825-calendar-design-calls-to-action",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
          show: [
            {
              field: "cCardShowCta1st",
              value: 1,
              defaultValue: 0,
            },
          ],
        },
        {
          title: (
            <span>
              Position <span className={"badge badge-success"}>NEW</span>
            </span>
          ),
          templates: [CT.card],
          defaultValue: "Center",
          name: "cCardCtaPosition",
          tooltip: {
            url: "https://help.display.church/en/articles/8442825-calendar-design-calls-to-action",
          },
          show: [
            {
              field: "cCardCtaButtonFullWidth",
              value: 0,
              defaultValue: 1,
            },
            {
              field: "cCardShowCta1st",
              value: 1,
              defaultValue: 0,
            },
          ],
          style: {
            selector: [".dc_calendar_card-cta-action-position"],
            property: "justify-content",
          },
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_POSITIONS,
            },
          },
        },
        {
          title: "Position",
          templates: [CT.cardList, CT.cardSlider],
          name: "cCardListCtaPosition",
          defaultValue: "Center",
          tooltip: {
            url: "https://help.display.church/en/articles/8442825-calendar-design-calls-to-action",
          },
          show: [
            {
              field: "cCardShowCta1st",
              value: 1,
              defaultValue: 0,
            },
          ],
          style: {
            selector: [".dc_calendar_card-cta-action-position"],
            property: "justify-content",
          },
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_POSITIONS,
            },
          },
        },
        {
          title: "CTA action",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.ccb],
          name: "cCardCTAAction1st",
          defaultValue: "eventPage",
          tooltip: {
            url: "https://help.display.church/en/articles/8442825-calendar-design-calls-to-action",
          },
          show: [
            {
              field: "cCardShowCta1st",
              value: 1,
              defaultValue: 0,
            },
            {
              field: "cCardActionOnClick",
              value: 1,
              defaultValue: 0,
              or: true,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_OPTIONS_CCB,
            },
          },
        },
        {
          title: "CTA action",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.pco],
          name: "cCardCTAAction1st",
          defaultValue: "eventPage",
          tooltip: {
            url: "https://help.display.church/en/articles/8442825-calendar-design-calls-to-action",
          },
          show: [
            {
              field: "cCardShowCta1st",
              value: 1,
              defaultValue: 0,
            },
            {
              field: "cCardActionOnClick",
              value: 1,
              defaultValue: 0,
              or: true,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_OPTIONS_PCO,
            },
          },
        },
        {
          title: "CTA action",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          integrations: [IT.google, IT.breeze],
          name: "cCardCTAAction1st",
          defaultValue: "linkFirst",
          tooltip: {
            url: "https://help.display.church/en/articles/8442825-calendar-design-calls-to-action",
          },
          show: [
            {
              field: "cCardShowCta1st",
              value: 1,
              defaultValue: 0,
            },
            {
              field: "cCardActionOnClick",
              value: 1,
              defaultValue: 0,
              or: true,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_OPTIONS_BREEZE_GOOGLE,
            },
          },
        },
        {
          title: "Use default CTA button label",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          name: "cCard1stDefaultCTALabel",
          defaultValue: 1,
          show: [
            {
              field: "cCardShowCta1st",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/8442825-calendar-design-calls-to-action",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA button label",
          templates: [CT.card, CT.cardList, CT.cardSlider],
          name: "cCard1stCTALabel",
          defaultValue: "Learn more",
          tooltip: {
            url: "https://help.display.church/en/articles/8442825-calendar-design-calls-to-action",
          },
          show: [
            {
              field: "cCardShowCta1st",
              value: 1,
              defaultValue: 1,
            },
            {
              field: "cCard1stDefaultCTALabel",
              value: 0,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "text",
            options: {
              placeholder: "CTA button label",
            },
          },
        },
      ],
    },
    {
      label: "Cards details call-to-action",
      options: [
        {
          title: "Show CTA button",
          templates: [CT.detailedList],
          name: "cDetailedShowCta1st",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/8442825-calendar-design-calls-to-action",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA action",
          templates: [CT.detailedList],
          integrations: [IT.ccb],
          name: "cDetailedCTAAction1st",
          defaultValue: "eventPage",
          tooltip: {
            url: "https://help.display.church/en/articles/8442825-calendar-design-calls-to-action",
          },
          show: [
            {
              field: "cDetailedShowCta1st",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_OPTIONS_CCB,
            },
          },
        },
        {
          title: "CTA action",
          templates: [CT.detailedList],
          integrations: [IT.pco],
          name: "cDetailedCTAAction1st",
          defaultValue: "eventPage",
          tooltip: {
            url: "https://help.display.church/en/articles/8442825-calendar-design-calls-to-action",
          },
          show: [
            {
              field: "cDetailedShowCta1st",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_OPTIONS_PCO,
            },
          },
        },
        {
          title: "CTA action",
          templates: [CT.detailedList],
          integrations: [IT.breeze, IT.google],
          name: "cDetailedCTAAction1st",
          defaultValue: "linkFirst",
          tooltip: {
            url: "https://help.display.church/en/articles/8442825-calendar-design-calls-to-action",
          },
          show: [
            {
              field: "cDetailedShowCta1st",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_OPTIONS_BREEZE_GOOGLE,
            },
          },
        },
        {
          title: "Use default CTA button label",
          templates: [CT.detailedList],
          name: "cDetailed1stDefaultCTALabel",
          defaultValue: 1,
          show: [
            {
              field: "cDetailedShowCta1st",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/8442825-calendar-design-calls-to-action",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA button label",
          templates: [CT.detailedList],
          name: "cDetailed1stCTALabel",
          defaultValue: "Learn more",
          tooltip: {
            url: "https://help.display.church/en/articles/8442825-calendar-design-calls-to-action",
          },
          show: [
            {
              field: "cDetailedShowCta1st",
              value: 1,
              defaultValue: 1,
            },
            {
              field: "cDetailed1stDefaultCTALabel",
              value: 0,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "text",
            options: {
              placeholder: "CTA button label",
            },
          },
        },
      ],
    },
    {
      label: "Event Details call-to-action",
      options: [
        {
          title: "Show CTA button",
          templates: [
            CT.eventList,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.monthly,
            CT.weekly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "cPopupShowCta1st",
          defaultValue: 0,
          tooltip: {
            url: "https://help.display.church/en/articles/8442825-calendar-design-calls-to-action",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA action",
          templates: [
            CT.eventList,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.monthly,
            CT.weekly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "cPopupCTAAction1st",
          integrations: [IT.ccb],
          defaultValue: "eventPage",
          tooltip: {
            url: "https://help.display.church/en/articles/8442825-calendar-design-calls-to-action",
          },
          show: [
            {
              field: "cPopupShowCta1st",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_OPTIONS_CCB,
            },
          },
        },
        {
          title: "CTA action",
          templates: [
            CT.eventList,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.monthly,
            CT.weekly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "cPopupCTAAction1st",
          integrations: [IT.pco],
          defaultValue: "eventPage",
          tooltip: {
            url: "https://help.display.church/en/articles/8442825-calendar-design-calls-to-action",
          },
          show: [
            {
              field: "cPopupShowCta1st",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_OPTIONS_PCO,
            },
          },
        },
        {
          title: "CTA action",
          templates: [
            CT.eventList,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.monthly,
            CT.weekly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "cPopupCTAAction1st",
          integrations: [IT.google, IT.breeze],
          defaultValue: "linkFirst",
          tooltip: {
            url: "https://help.display.church/en/articles/8442825-calendar-design-calls-to-action",
          },
          show: [
            {
              field: "cPopupShowCta1st",
              value: 1,
              defaultValue: 0,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: CTA_OPTIONS_BREEZE_GOOGLE,
            },
          },
        },
        {
          title: "Use default CTA button label",
          templates: [
            CT.eventList,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.monthly,
            CT.weekly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "cPopup1stDefaultCTALabel",
          defaultValue: 1,
          show: [
            {
              field: "cPopupShowCta1st",
              value: 1,
              defaultValue: 0,
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/8442825-calendar-design-calls-to-action",
          },
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "CTA button label",
          templates: [
            CT.eventList,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.monthly,
            CT.weekly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "cPopup1stCTALabel",
          defaultValue: "Learn more",
          tooltip: {
            url: "https://help.display.church/en/articles/8442825-calendar-design-calls-to-action",
          },
          show: [
            {
              field: "cPopupShowCta1st",
              value: 1,
              defaultValue: 1,
            },
            {
              field: "cPopup1stDefaultCTALabel",
              value: 0,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "text",
            options: {
              placeholder: "CTA button label",
            },
          },
        },
      ],
    },
  ],
};
