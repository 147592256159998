import { filterAvailableValuesForWidgetType, initBuilder } from "../builder";
import definitions from "../builder/calendar/definitions";

const WidgetBuilderMiddleware =
  (definitions) => (storeAPI) => (next) => (action) => {
    switch (action.type) {
      case "WIDGET_UPDATE_FULFILLED":
      case "WIDGET_FETCH_FULFILLED":
      case "WIDGET_SET":
        if (
          !action.payload ||
          !action.payload.uuid ||
          !action.payload.integration
        ) {
          break;
        }

        action.payload.builder = {
          design: initBuilder(action.payload, definitions),
        };

        action.payload.widget_settings.design =
          filterAvailableValuesForWidgetType(
            action.payload.widget_settings.design || {},
            action.payload.builder.design.values
          );

        break;
    }

    next(action);
  };

export default WidgetBuilderMiddleware;
