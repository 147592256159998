// /**
//  * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
//  */

// import React from "react";
//import { createBrowserHistory } from "history";
import { createBrowserHistory } from "history";

//import { applyMiddleware, createStore } from "redux";
import { applyMiddleware, compose, createStore } from "redux";

import { routerMiddleware } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import logger from "redux-logger";
//import whyDidYouRender from "@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { default as signupDefinitions } from "./../../builder/signup/definitions";
import createRootReducer from "./reducers";
import EventsMiddleware from "../../middleware/events";
import CalendarBuilderMiddleware from "../../middleware/CalendarBuilderMiddleware";
import CalendarsMiddleware from "../../middleware/calendars";
import GroupsItemsMiddleware from "../../middleware/groupsItems";
import GroupsBuilderMiddleware from "../../middleware/GroupsBuilderMiddleware";
import GroupsMiddleware from "../../middleware/groups";
import AlertsMiddleware from "../../middleware/alerts";
import AlertsBuilderMiddleware from "../../middleware/AlertsBuilderMiddleware";
import SignupItemsMiddleware from "../../middleware/signupsItems";
import WidgetsMiddleware from "../../middleware/widgets";
import WidgetBuilderMiddleware from "../../middleware/WidgetBuilderMiddleware";
import WidgetItemsCacheMiddleware from "../../middleware/widgetItemsCache";
import IntegrationFiltersMiddleware from "../../middleware/integrationFilters";
// import {filtersReducerTransform} from "./reducers/filtersReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "user",
    "autologin",
    "currentOrganization",
    "dismissedAlerts",
    "appTags",
  ],
  // transforms: [filtersReducerTransform]
};

export const history = createBrowserHistory();

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);
const middlewareComponents = [
  thunk,
  routerMiddleware(history),
  EventsMiddleware,
  CalendarBuilderMiddleware,
  CalendarsMiddleware,
  GroupsItemsMiddleware,
  GroupsBuilderMiddleware,
  GroupsMiddleware,
  AlertsMiddleware,
  AlertsBuilderMiddleware,
  WidgetsMiddleware,
  WidgetBuilderMiddleware(signupDefinitions),
  SignupItemsMiddleware,
  WidgetItemsCacheMiddleware,
  IntegrationFiltersMiddleware,
];

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  middlewareComponents.push(logger);
  //whyDidYouRender(React);
}
const middleware = applyMiddleware(...middlewareComponents);

function configureStore(preloadedState) {
  const store = createStore(
    persistedReducer,
    preloadedState,
    compose(middleware)
  );

  return store;
}

export const store = configureStore();
export const persistor = persistStore(store);
