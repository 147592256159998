/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/10/2023.
 */

import { CT, ST } from "../../../consts";
const boxShadowOptions = [
  { label: "None", value: "none" },
  {
    label: "Light",
    value: "0px 4px 8px 0px rgba(0, 0, 0, 0.17)",
  },
  {
    label: "Strong",
    value: "0px 7px 11px 0px rgba(0, 0, 0, 0.27)",
  },
];
export const definitions = {
  title: "Cards grid",
  icon: "grid_view",
  groups: [
    {
      label: "Layout",
      options: [
        {
          title: "Card style",
          templates: [ST.cardGrid],
          name: "cwStyle",
          defaultValue: "none",
          tooltip: null,
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "Classic", value: "none" },
                { label: "Tile", value: "tile" },
                { label: "Tile with details below", value: "tile-details" },
              ],
            },
          },
        },
        {
          title: "Cards per row",
          templates: [ST.cardGrid],
          name: "cwColsCount",
          defaultValue: "1fr 1fr 1fr",
          tooltip: {
            url: "https://help.display.church/en/articles/4895900-design-frame-and-cells-cards-per-row",
          },
          style: [
            {
              selector: [".dce--signups-cards"],
              property: "grid-template-columns",
            },
          ],
          control: {
            kind: "number",
            options: {
              min: 1,
              placeholder: "Number of cards per row",
              updateValue: (val) => {
                val[1].value = [...Array(val[1].value * 1).keys()]
                  .map((i) => "1fr")
                  .join(" ");
                return val;
              },
              renderValue: (val) => val.split(" ").length,
            },
          },
        },
        {
          title: "Gap between cards",
          templates: [ST.cardGrid],
          name: "cwPadding",
          defaultValue: "1em",
          style: [
            {
              selector: ".dce--signups-cards",
              property: ["gap"],
            },
          ],
          tooltip: null,
          control: {
            kind: "cssDimension",
            options: {
              placeholder: "CSS value for gap between cards",
            },
          },
        },
        {
          title: "Card corner rounding",
          templates: [ST.cardGrid, ST.cardList],
          name: "cwBorderRadius",
          defaultValue: "20px",
          style: [
            {
              selector: ".dce--signups-cards .dce--signups-cards-card",
              property: "border-radius",
            },
          ],
          tooltip: {
            url: "http://help.display.church/en/articles/4895960-design-frame-and-cells-card-corner-rounding",
          },
          control: {
            kind: "cssDimension",
            options: {
              placeholder: "CSS value for corner rounding",
            },
          },
        },
        {
          title: "Tiled image corner rounding",
          templates: [ST.cardGrid, ST.cardList],
          name: "cwTiledBorderRadius",
          defaultValue: "20px",
          style: [
            {
              selector: [
                ".dce--signups-cards-card--style--tile-details .dce--signups-cards-card_cover img",
                ".dce--signups-cards-card--style--tile-details .dce--signups-cards-card_cover .dce--signups-cards-card_header-image__placeholder",
              ],
              property: "border-radius",
            },
          ],
          show: [
            {
              field: "cwStyle",
              value: "tile-details",
              defaultValue: "none",
            },
          ],
          tooltip: null,
          control: {
            kind: "cssDimension",
            options: {
              placeholder: "CSS value for corner rounding",
            },
          },
        },
        {
          title: "Drop shadow",
          templates: [ST.cardGrid],
          name: "sCBoxShadow",
          defaultValue: "0px 4px 8px 0px rgba(0, 0, 0, 0.17)",
          style: {
            selector: [".dce--signups-cards-card"],
            property: "box-shadow",
          },
          tooltip: null,
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: boxShadowOptions,
            },
          },
        },
        /*{
          title: "Card hover effect",
          templates: [GT.card],
          name: "cwHoverEffect",
          defaultValue: "zoom",
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "What happens when you hover on a card.",
          },
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "None", value: "none" },
                { label: "Zoom in", value: "zoom" },
                { label: "Simple Accent", value: "accent-simple" },
                { label: "Expandable Accent", value: "accent-expand" },
              ],
            },
          },
        },*/
      ],
    },
    {
      label: "Main information",
      options: [
        {
          title: "Show title",
          templates: [ST.cardGrid],
          name: "sBCTitleShow",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show event date",
          templates: [ST.cardGrid],
          name: "sBCEventDateShow",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Date location",
          templates: [ST.cardGrid],
          name: "sBEventDate",
          defaultValue: 0,
          tooltip: null,
          show: [
            {
              field: "sBCEventDateShow",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "Show date above the title", value: 0 },
                { label: "Show date below the title", value: 1 },
              ],
            },
          },
        },
        {
          title: "Show summary",
          templates: [ST.cardGrid],
          name: "sBCSummaryShow",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Limit summary lines",
          templates: [ST.cardGrid],
          name: "sCLimitSummary",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
          show: [
            {
              field: "sBCSummaryShow",
              value: 1,
              defaultValue: 1,
            },
          ],
        },
        {
          title: "Number of lines to display",
          templates: [ST.cardGrid],
          name: "sCLimitSummaryLines",
          defaultValue: "2",
          tooltip: null,
          control: {
            kind: "number",
            options: {},
          },
          style: [
            {
              selector: [".dce--signup-cards-card_content-summary"],
              property: "-webkit-line-clamp",
            },
          ],
          show: [
            {
              field: "sCLimitSummary",
              value: 1,
              defaultValue: 1,
            },
          ],
        },
        {
          title: "Action on click",
          templates: [ST.cardGrid],
          name: "sBCAction",
          defaultValue: "formUrl",
          tooltip: null,
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                {
                  label: "Open form",
                  value: "formUrl",
                },
                {
                  label: "Open Signup page",
                  value: "frontendUrl",
                },
              ],
            },
          },
        },
      ],
    },
    {
      label: "Classic style adjustments",
      options: [
        {
          title: "Background color",
          templates: [ST.cardGrid],
          name: "sBCBg",
          defaultValue: "#fff",
          style: [
            {
              selector: [".dce--signups-cards-card--style--none"],
              property: ["background"],
            },
          ],
          tooltip: null,
          show: [
            {
              field: "cwStyle",
              value: "none",
              defaultValue: "none",
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: true,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Image placeholder background color",
          templates: [ST.cardGrid],
          name: "sBCImgPlaceholderBg",
          defaultValue: "linear-gradient(to bottom, #e33fb7, #B540D1)",
          style: [
            {
              selector: [
                ".dce--signups-cards-card_cover .dce--signups-cards-card_header-image",
              ],
              property: ["background"],
            },
          ],
          tooltip: null,
          show: [
            {
              field: "cwStyle",
              value: "none",
              defaultValue: "none",
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Title color",
          templates: [ST.cardGrid],
          name: "sBCTitleColor",
          defaultValue: "#535353",
          style: [
            {
              selector: [".dce--signups-cards-card_content-body h2"],
              property: ["color"],
            },
          ],
          tooltip: null,
          show: [
            {
              field: "cwStyle",
              value: "none",
              defaultValue: "none",
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Summary color",
          templates: [ST.cardGrid],
          name: "sBCSiummaryColor",
          defaultValue: "#535353",
          style: [
            {
              selector: [".dce--signups-cards-card_content-body p"],
              property: ["color"],
            },
          ],
          tooltip: null,
          show: [
            {
              field: "cwStyle",
              value: "none",
              defaultValue: "none",
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Tile style adjustments",
      options: [
        {
          title: "Background color",
          templates: [ST.cardGrid],
          name: "sBCTileBg",
          defaultValue: "linear-gradient(to bottom, #e33fb7, #B540D1)",
          style: [
            {
              selector: [".dce--signups-cards-card--style--tile"],
              property: "background",
            },
          ],
          tooltip: null,
          show: [
            {
              field: "cwStyle",
              value: "tile",
              defaultValue: "none",
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: true,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Image placeholder background color",
          templates: [ST.cardGrid],
          name: "sBCTileImgPlaceholderBg",
          defaultValue: "transparent",
          style: [
            {
              selector: [
                ".dce--signups-cards-card_cover .dce--signups-cards-card_header-image",
              ],
              property: ["background"],
            },
          ],
          tooltip: null,
          show: [
            {
              field: "cwStyle",
              value: "tile",
              defaultValue: "none",
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Title color",
          templates: [ST.cardGrid],
          name: "sBCTileTitleColor",
          defaultValue: "#f3f3f3",
          style: [
            {
              selector: [".dce--signups-cards-card_content-body h2"],
              property: ["color"],
            },
          ],
          tooltip: null,
          show: [
            {
              field: "cwStyle",
              value: "tile",
              defaultValue: "none",
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Summary color",
          templates: [ST.cardGrid],
          name: "sBCTileSummaryColor",
          defaultValue: "#f3f3f3",
          style: [
            {
              selector: [".dce--signups-cards-card_content-body p"],
              property: ["color"],
            },
          ],
          tooltip: null,
          show: [
            {
              field: "cwStyle",
              value: "tile",
              defaultValue: "none",
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Tile with details style adjustments",
      options: [
        {
          title: "Background color",
          templates: [ST.cardGrid],
          name: "sBCTileDetailsBg",
          defaultValue: "linear-gradient(to bottom, #e33fb7, #B540D1)",
          style: [
            {
              selector: [".dce--signups-cards-card--style--tile-details"],
              property: "background",
            },
          ],
          tooltip: null,
          show: [
            {
              field: "cwStyle",
              value: "tile-details",
              defaultValue: "none",
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: true,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Image placeholder background color",
          templates: [ST.cardGrid],
          name: "sBCTileDetailsImgPlaceholderBg",
          defaultValue: "transparent",
          style: [
            {
              selector: [
                ".dce--signups-cards-card--style--tile-details .dce--signups-cards-card_cover .dce--signups-cards-card_header-image__placeholder",
              ],
              property: ["background"],
            },
          ],
          tooltip: null,
          show: [
            {
              field: "cwStyle",
              value: "tile-details",
              defaultValue: "none",
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Title color",
          templates: [ST.cardGrid],
          name: "sBCTileDescriptionTitleColor",
          defaultValue: "#f3f3f3",
          style: [
            {
              selector: [".dce--signups-cards-card_content-body h2"],
              property: ["color"],
            },
          ],
          tooltip: null,
          show: [
            {
              field: "cwStyle",
              value: "tile-details",
              defaultValue: "none",
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Summary color",
          templates: [ST.cardGrid],
          name: "sBCTileDescriptionSummaryColor",
          defaultValue: "#f3f3f3",
          style: [
            {
              selector: [".dce--signups-cards-card_content-body p"],
              property: ["color"],
            },
          ],
          tooltip: null,
          show: [
            {
              field: "cwStyle",
              value: "tile-details",
              defaultValue: "none",
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: true,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
