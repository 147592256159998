import { GT, IT } from "../../../consts";

export const definitions = {
  title: "Navigation",
  icon: "brush",
  groups: [
    {
      label: "Filters",
      options: [
        {
          title: "Background",
          templates: [GT.card, GT.twoColumn, GT.outlined, GT.mosaic],
          name: "groupFiltersContainerBg",
          defaultValue: "#eaeaea",
          style: {
            selector: ".dce-groups--filters-panel",
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Background color of filter toolbar.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Selected option",
          templates: [GT.card, GT.twoColumn, GT.outlined, GT.mosaic],
          name: "groupFilterSelectedOptionBg",
          defaultValue: "#eaeaea",
          style: {
            selector: ".dce-groups--filters--options_selected",
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Background color of filter pill areas.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },

        {
          title: "Selected option active bg",
          templates: [GT.card, GT.twoColumn, GT.outlined, GT.mosaic],
          name: "groupFilterDropdownActiveBG",
          defaultValue: "#a5a5a5",
          style: {
            selector: [
              ".dce-groups--filters--options_selected.active",
              ".dce-groups--filters--options_selected.active:hover",
            ],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Active filter background color.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Selected option hover bg",
          templates: [GT.card, GT.twoColumn, GT.outlined, GT.mosaic],
          name: "groupFilterDropdownHoveredBG",
          defaultValue: "#d2d2d2",
          style: {
            selector: [
              ".dce-groups--filters--options .dce-groups--filters--options_selected:hover",
            ],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Active filter background color on hover.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Selected option color",
          templates: [GT.card, GT.twoColumn, GT.outlined, GT.mosaic],
          name: "groupFilterSelectedOptionColor",
          defaultValue: "#606060",
          style: {
            selector:
              ".dce-groups--filters--options .dce-groups--filters--options_selected",
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Text color of filters.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Selected option hover color",
          templates: [GT.card, GT.twoColumn, GT.outlined, GT.mosaic],
          name: "groupFilterDropdownHoveredColor",
          defaultValue: "#fff",
          style: {
            selector: [
              ".dce-groups--filters--options .dce-groups--filters--options_selected:hover",
            ],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Text color of filter when hovered on.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Selected option active color",
          templates: [GT.card, GT.twoColumn, GT.outlined, GT.mosaic],
          name: "groupFilterDropdownActiveColor",
          defaultValue: "#fff",
          style: {
            selector: [".dce-groups--filters--options_selected.active"],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Text color of active filter.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option background color",
          templates: [GT.card, GT.twoColumn, GT.outlined, GT.mosaic],
          name: "groupFilterOptionBgColor",
          defaultValue: "#fff",
          style: {
            selector: [".dce-groups--filters--options_dropdown p"],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option background color hover",
          templates: [GT.card, GT.twoColumn, GT.outlined, GT.mosaic],
          name: "groupFilterOptionHoverBgColor",
          defaultValue: "#f7f7f7",
          style: {
            selector: [".dce-groups--filters--options_dropdown p:hover"],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Background color of active item dropdown menu on hover.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option background active color",
          templates: [GT.card, GT.twoColumn, GT.outlined, GT.mosaic],
          name: "groupFilterOptionActiveBgColor",
          defaultValue: "#f7f7f7",
          style: {
            selector: [".dce-groups--filters--options_dropdown p.active"],
            property: "background-color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Background color of active item dropdown menu.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option label color",
          templates: [GT.card, GT.twoColumn, GT.outlined, GT.mosaic],
          name: "groupFilterOptionLabelColor",
          defaultValue: "#929292",
          style: {
            selector: [".dce-groups--filters--options_dropdown p"],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },

        {
          title: "Option label active color",
          templates: [GT.card, GT.twoColumn, GT.outlined, GT.mosaic],
          name: "groupFilterOptionLabelActiveColor",
          defaultValue: "#929292",
          style: {
            selector: [".dce-groups--filters--options_dropdown p.active"],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Text color of active item dropdown menu.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option label color hover",
          templates: [GT.card, GT.twoColumn, GT.outlined, GT.mosaic],
          name: "groupFilterOptionLabelHoverColor",
          defaultValue: "#606060",
          style: {
            selector: [".dce-groups--filters--options_dropdown p:hover"],
            property: "color",
          },
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Text color of active item dropdown menu on hover.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option border color",
          templates: [GT.card, GT.twoColumn, GT.outlined, GT.mosaic],
          name: "groupFilterOptionsBorderColor",
          defaultValue: "#d9d9d9",
          style: [
            {
              selector: [".dce-groups--filters--options_dropdown p"],
              property: "border-bottom-color",
            },
            {
              selector: [".dce-groups--filters--options_dropdown"],
              property: "border-color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Line color of active item dropdown menu.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option border color hover",
          templates: [GT.card, GT.twoColumn, GT.outlined, GT.mosaic],
          name: "groupFilterOptionsBorderHoverColor",
          defaultValue: "#C7C7C7",
          style: [
            {
              selector: [".dce-groups--filters--options_dropdown p:hover"],
              property: "border-bottom-color",
            },
            {
              selector: [".dce-groups--filters--options_dropdown:hover"],
              property: "border-color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Line color of active item dropdown menu on hover.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option accent color",
          templates: [GT.card, GT.twoColumn, GT.outlined, GT.mosaic],
          name: "groupFilterOptionAccentColor",
          defaultValue: "#C7C7C7",
          style: [
            {
              selector: [".dce-groups--filters--options_dropdown p:hover"],
              property: "border-left-color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Left border of active item dropdown menu.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option selected icon color bg",
          templates: [GT.card, GT.twoColumn, GT.outlined, GT.mosaic],
          name: "groupFilterOptionIconBGColor",
          defaultValue: "#9e9e9e",
          style: [
            {
              selector: [".dce-groups--filters--options_dropdown p i"],
              property: "background-color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Color of the circle with the check mark.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Option selected icon color",
          templates: [GT.card, GT.twoColumn, GT.outlined, GT.mosaic],
          name: "groupFilterOptionIconColor",
          defaultValue: "#fff",
          style: [
            {
              selector: [".dce-groups--filters--options_dropdown p i"],
              property: "color",
            },
          ],
          tooltip: {
            url: "https://help.display.church/en/articles/6238250-groups-widget#h_dc46e99ff4",
            message: "Color of the check mark.",
          },
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
