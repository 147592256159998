import React from "react";
import appConfig from "../../../../appConfig";

const FilterLabelCTA = () => {
  return (
    <span>
      You may filter events on this calendar using one of the available filters.{" "}
      {appConfig.branding === "dc" ? (
        <a
          href={
            "https://help.display.church/en/articles/5684553-setup-events-filtering-calendars-event-alerts-gcal"
          }
          target={"_blank_gcal_filters"}
        >
          Follow this link to learn more.
        </a>
      ) : null}
    </span>
  );
};

export default FilterLabelCTA;
