/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */

const initialState = {
  collection: [],
  lastSyncs: {},
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "APP_ALERT_DISMISS":
      state.collection.push(action.id);
      return {
        ...state,
        collection: [...state.collection],
      };

    case "INTEGRATION_SYNC":
      return {
        ...state,
        lastSyncs: {
          ...state.lastSyncs,
          [action.payload.uuid]: Math.round(new Date().getTime() / 1000),
        },
      };

    default:
      return state;
  }

  return state;
}
